import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ActionIcon from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import HoverActionIcon from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import { Typography, Box } from "@mui/material";
import { getCookie } from "../../../utils/utils";
//import EditToDoDialog from "./VisitToDoEdit";
import dayjs from "dayjs";
import { MStatus } from "./Components";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#279989",
    padding: "8px 16px",
    border: "none",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "2px solid #eefefa",
    fontSize: 14,
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function LogMedication({ selectedVisit = {} }) {
  const [medicationList, setMedicationList] = useState([]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const fetchMedication = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/medication-get-details?visit_details_id=${selectedVisit.visitdetailsid}`; //visit_details_id=2703
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      console.log("API Response Data:", data); // Log the entire response

      if (response.ok) {
        const info = data.data;
        setMedicationList(info);
      } else {
        console.log("API request failed with status:", response.status);
      }
    } catch (e) {
      console.log("Error fetching data:", e.message);
    }
  };

  useEffect(() => {
    if (selectedVisit?.visitdetailsid) {
      fetchMedication();
    }
  }, [selectedVisit]);

  // useEffect(() => {
  //   fetchMedication();
  // }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "370px", overflow: "auto" }}
    >
      <Table sx={{ minWidth: 775 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 110 }}>
              Medication Name
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 55 }}>
              #Dose
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 110 }}>
              Medication Type
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 110 }}>
              Route
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 110 }}>
              Support
            </StyledTableCell>

            <StyledTableCell align="center" sx={{ width: 110 }}>
              Start Date
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 110 }}>
              End Date
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 110 }}>
              Created by
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: 60 }}>
              Status
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medicationList.length > 0 ? (
            medicationList.map((medicationList) => (
              <StyledTableRow key={medicationList.medication_id}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {medicationList.nhs_medicine_name}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.quantity_each_dose}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.medication_type}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.medication_route_name}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.medication_support}
                </StyledTableCell>

                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.blister_pack_start_date
                    ? formatDate(medicationList.blister_pack_start_date)
                    : medicationList.by_exact_start_date
                    ? formatDate(medicationList.by_exact_start_date)
                    : medicationList.scheduled_start_date
                    ? formatDate(medicationList.scheduled_start_date)
                    : medicationList.prn_start_date
                    ? formatDate(medicationList.prn_start_date)
                    : ""}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.blister_pack_end_date
                    ? formatDate(medicationList.blister_pack_end_date)
                    : medicationList.by_exact_end_date
                    ? formatDate(medicationList.by_exact_end_date)
                    : medicationList.scheduled_end_date
                    ? formatDate(medicationList.scheduled_end_date)
                    : medicationList.prn_end_date
                    ? formatDate(medicationList.prn_end_date)
                    : ""}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {medicationList.blister_pack_created_by
                    ? medicationList.blister_pack_created_by
                    : medicationList.by_exact_created_by
                    ? medicationList.by_exact_created_by
                    : medicationList.scheduled_created_by
                    ? medicationList.scheduled_created_by
                    : medicationList.prn_created_by
                    ? medicationList.prn_created_by
                    : ""}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  <MStatus status={medicationList.status} />
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={8} align="center">
                No Medication's added for this visit
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
