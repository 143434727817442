import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
  } from "react";
  import { Box, FormLabel, Typography, TextField } from "@mui/material";
  
  const AccountNumber = forwardRef(
    (
      {
        label,
        preFilledvalue,
        placeholder,
        onValueChange,
        required = false,
        sortcode = false,
        disabled = false,
        name,
      },
      ref
    ) => {
      const [value, setValue] = useState(preFilledvalue || "");
      const [errorMessage, setErrorMessage] = useState("");
  
      useEffect(() => {
        setValue(preFilledvalue || "");
        setErrorMessage("");
      }, [preFilledvalue]);
  
      const handleChange = (event) => {
        if (disabled) return;
  
        const inputValue = event.target.value;
  
        // Remove all non-digit characters
        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
  
        // Allow up to 8 digits only
        if (sanitizedValue.length > 8) {
          setErrorMessage(`${label} must be exactly 8 digits.`);
          return;
        }
  
        setValue(sanitizedValue);
        setErrorMessage(""); // Clear error message if input is valid
        onValueChange(sanitizedValue, event.target.name); // Notify parent component
      };
  
      const handleValidation = () => {
        const strValue = String(value).trim();
  
        if (required && !strValue) {
          setErrorMessage(`${label} is required.`);
          return false;
        }
  
        if (strValue.length !== 8) {
          setErrorMessage(`${label} must be exactly 8 digits.`);
          return false;
        }
  
        if (!/^\d{8}$/.test(strValue)) {
          setErrorMessage(`Please enter a valid ${label} with 8 digits.`);
          return false;
        }
  
        setErrorMessage("");
        return true;
      };
  
      // Expose the validation function to the parent component
      useImperativeHandle(ref, () => ({
        handleValidation,
      }));
  
      return (
        <Box sx={{ textAlign: "left" }}>
          <FormLabel>
            <Typography variant="h6">
              {label}
              {required && <span className="star">*</span>}
            </Typography>
          </FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={handleChange}
            inputProps={{
              maxLength: 8, // Restrict max length to 8
              inputMode: "numeric",
              pattern: "\\d*", // Ensure only numbers are allowed
            }}
            type="text" // Use text type for better control over input
            disabled={disabled}
          />
          {errorMessage && (
            <Typography variant="errmsg">{errorMessage}</Typography>
          )}
        </Box>
      );
    }
  );
  
  export default AccountNumber;
  