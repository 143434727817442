import { useState, useEffect } from "react";
import { getCookie } from "../../../utils/utils";

const useMedicationData = () => {
    const [medicines, setMedicines] = useState([]);
    const [medicationRoute, setMedicationRoute] = useState([]);
  
    useEffect(() => {
      const fetchMedicines = async () => {
        try {
          const token = getCookie();
          const URL = "https://3.8.158.142:8443/api/medication-nhs-medicine-list";
          const response = await fetch(URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const transformedData = data.data.map((item) => ({
              id: item.id,
              text: item.nhs_medicine_name,
            }));
            setMedicines(transformedData);
          } else {
            setMedicines([]);
          }
        } catch {
          setMedicines([]);
        }
      };
  
      const fetchMedicationRoute = async () => {
        try {
          const token = getCookie();
          const URL = "https://3.8.158.142:8443/api/medication-route";
          const response = await fetch(URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const transformedData = data.data.map((item) => ({
              id: item.id,
              text: item.medication_route_name,
            }));
            setMedicationRoute(transformedData);
          } else {
            setMedicationRoute([]);
          }
        } catch {
          setMedicationRoute([]);
        }
      };
  
      const fetchAllData = async () => {
        await Promise.all([fetchMedicines(), fetchMedicationRoute()]);
      };
      fetchAllData();
    }, []);
  
    return { medicines, medicationRoute };
  };
  
  export default useMedicationData;
