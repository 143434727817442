import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import themeStyle from "../../../constants/themeStyle";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";

const content = [
  { id: 1, text: "Users", linkUrl: "/users" },
  { id: 2, text: "Clients", linkUrl: "/clients" },
  { id: 3, text: "Roster", linkUrl: "/roster" },
  { id: 4, text: "Reports", linkUrl: "/reports" },
  { id: 5, text: "Billing", linkUrl: "/billing" },
  { id: 6, text: "Log", linkUrl: "/log" },
];

export default function MiddleContent({
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) {
  const [active, setActive] = useState();
  const [activeurl, setUrl] = useState();
  const [pendingid, setPendingid] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get current URL

  const handleNavigation = (id, url) => {
    if (hasUnsavedChanges) {
      setPendingNavigation(true);
      setPendingid(id);
      setUrl(url);
      handleOpenDialog();
    } else {
      setActive(id);
      navigate(url);
    }
  };
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingNavigation(false);
  };
  const onClickYesDialogBtn = () => {
    if (pendingNavigation) {
      setActive(pendingid);
      navigate(activeurl); // Navigate after confirming
      setPendingNavigation(false);
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleCloseDialog();
  };
  useEffect(() => {
    const currentPath = location.pathname;
    const matchedContent = content.find(
      (item) => currentPath.includes(item.linkUrl) // Check if item.text is part of the current URL path
    );
    if (matchedContent) {
      setActive(matchedContent.id); // Set the active tab based on URL
    } else {
      setActive(content[0].id); // Default to the first tab if no match
    }
  }, [location.pathname]); // Re-run when the URL changes

  return (
    <Card
      sx={{
        backgroundColor: themeStyle.colors.primary.dark,
        display: "flex",
        borderRadius: `0px 0px 10px 10px`,
        padding: "5px 20px",
      }}
    >
      {content.map((each) => (
        <Typography
          key={each.id}
          variant="body2"
          component="p"
          sx={{
            fontSize: "15px",
            textDecoration: "underline",
            textUnderlineOffset: "6px",
            textDecorationThickness: "2px",
            textDecorationColor:
              each.id === active
                ? themeStyle.colors.primary.white
                : "transparent",
            color: themeStyle.colors.primary.white,
            padding: "4px 18px",
            textAlign: "center",
            fontFamily: "Arvo",
            "&:hover": {
              textDecorationColor: themeStyle.colors.primary.white,
              cursor: "pointer",
            },
          }}
          onClick={() => handleNavigation(each.id, each.linkUrl)}
        >
          {each.text}
        </Typography>
      ))}

      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleCloseDialog}
      />
    </Card>
  );
}
