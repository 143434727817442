import { useState, useEffect, useRef } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { TabButton } from "../clientReusableComponents/components";
import { getCookie } from "../../../utils/utils";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import CustomButton from "../../reusableComponents/CustomButton";
import TopContentContainer from "../../reusableComponents/TopContentContainer";
import AboutMe from "../aboutMe/AboutMe";
import CarePlan from "../carePlan/CarePlan";
import MyCareNetwork from "../myCareNetwork/MyCareNetwork";
import Visits from "../visits/Visits";
import Todos from "../toDo's/Todos";
import Medication from "../medication/Medication";
import ShareAccess from "../shareAccess/ShareAccess";
import ClientSetting from "../settings/ClientSetting";
import { useOutletContext } from "react-router-dom";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";
//import { ChevronLeftRoundedIcon, ChevronRight } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const tabsList = [
  { id: 1, text: "Client Details" },
  { id: 2, text: "Care Network" },
  { id: 3, text: "Care Plan" },
  { id: 4, text: "Visits" },
  { id: 5, text: "To-Do's" },
  { id: 6, text: "Medications" },
  { id: 7, text: "Share Access" },
  { id: 8, text: "Settings" },
];

export default function UserDashMain() {
  const { userID } = useParams();
  const navigate = useNavigate();
  const [tempUserID, setTempUserID] = useState(userID);
  const [searchParams, setSearchParams] = useSearchParams(); // Used for URL params
  const [activeUserName] = useState("");
  const initialTab = parseInt(searchParams.get("tab")) || tabsList[0].id;
  const [activeTab, setActiveTab] = useState(initialTab);
  const [clientData, setClientData] = useState({});
  const [userName, setUserName] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useOutletContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingTabId, setPendingTabId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const [reload, setReload] = useState(false);
  const [assessmentName, setAssessmentName] = useState("");
  const scrollRef = useRef(null);
  const [showArrows, setShowArrows] = useState(false); // Single state to manage both arrows
  const [consent, setConsent] = useState(false);

  const isEditQueryParams = searchParams.get("edit");
  const initialMode = searchParams.get("mode")
    ? searchParams.get("mode")
    : isEditQueryParams
    ? "Edit"
    : "View";

  const [isEditMode, setIsEditMode] = useState(initialMode);

  useEffect(() => {
    if (clientData) {
      setUserName(`${clientData?.clientInfo?.first_name || ""}`);
    }
  }, [clientData]);

  const reloadTheData = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setClientData({});
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/client/aboutMe/${userID}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setClientData(data.data);
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersAPI();
  }, [userID, reload]);

  useEffect(() => {
    updateUrlParams(activeTab, isEditMode);
  }, [tempUserID]); // Run this effect when tempUserID changes

  // Update the URL when tab or mode changes
  const updateUrlParams = (tabId, mode) => {
    setSearchParams({ tab: tabId, mode: mode });
  };

  const handleBackButtonClick = () => {
    if (hasUnsavedChanges === true) {
      setPendingNavigation(true);
      handleOpenDialog();
    } else {
      navigate("/clients");
    }
  };
  const handleOpenDialog = () => setOpenDialog(true);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingTabId(null);
    setPendingUserId(null);
    setPendingNavigation(false);
  };

  const onClickYesDialogBtn = () => {
    if (pendingTabId !== null) {
      setActiveTab(pendingTabId);
      updateUrlParams(pendingTabId, isEditMode);
      setPendingTabId(null);
      if (pendingTabId === 3) {
        setAssessmentName("");
      }
    }
    if (pendingUserId !== null) {
      setTempUserID(pendingUserId);
      //updateUrlParams(activeTab, isEditMode); // Ensure mode and tab are updated when user switches
      setPendingUserId(null);
    }
    if (pendingNavigation) {
      navigate("/clients"); // Navigate after confirming
      setPendingNavigation(false);
    }

    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleCloseDialog();
  };

  const activeTabText = () => {
    const tab = tabsList.filter((each) => each.id === activeTab)[0].text;
    if (tab === "About Me") {
      return "About Me";
    }
    return tab ? tab : "";
  };

  // Handle tab switching with unsaved changes confirmation

  const handleTabSwitch = (tabId) => {
    if (hasUnsavedChanges) {
      setPendingTabId(tabId);
      handleOpenDialog();
    } else {
      setActiveTab(tabId);
      updateUrlParams(tabId, isEditMode);
      if (tabId === 3) {
        setAssessmentName("");
        setConsent(false);
      }
    }
  };
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <AboutMe
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            reloadTheData={reloadTheData}
            toggleEditMode={toggleEditMode}
            isEditMode={isEditMode}
          />
        );
      case 2:
        return (
          <MyCareNetwork
            clientId={userID}
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
            toggleEditMode={toggleEditMode}
          />
        );
      case 3:
        return (
          <CarePlan
            clientId={userID}
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
            toggleEditMode={toggleEditMode}
            isEditMode={isEditMode}
            assessmentName={assessmentName}
            setAssessmentName={setAssessmentName}
            consent={consent}
            setConsent={setConsent}
          />
        );
      case 4:
        return <Visits clientId={userID} clientMainData={clientData} />;
      case 5:
        return <Todos clientId={userID} clientMainData={clientData} />;
      case 6:
        return (
          <Medication
            clientId={userID}
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            toggleEditMode={toggleEditMode}
          />
        );
      case 7:
        return (
          <ShareAccess
            clientId={userID}
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            toggleEditMode={toggleEditMode}
          />
        );
      case 8:
        return (
          <ClientSetting
            clientId={userID}
            clientMainData={clientData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            toggleEditMode={toggleEditMode}
          />
        );

      default:
        return null; // or a default component
    }
  };

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      // Scroll by -50px to the left
      scrollRef.current.scrollLeft -= 100; // Direct manipulation of scroll position
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      // Scroll by 50px to the right
      scrollRef.current.scrollLeft += 100; // Direct manipulation of scroll position
    }
  };

  // Function to check if scroll is possible
  const checkScrollButtons = () => {
    const scrollableWidth = scrollRef.current.scrollWidth;
    const containerWidth = scrollRef.current.offsetWidth;
    const scrollPosition = scrollRef.current.scrollLeft;

    // If the content is scrollable (left or right), show the arrows
    setShowArrows(
      scrollPosition > 0 || scrollPosition < scrollableWidth - containerWidth
    );
  };

  // Add event listener for scroll event
  useEffect(() => {
    const scrollElement = scrollRef.current;

    // Check if the arrows should be shown initially and after every scroll event
    const handleScroll = () => {
      checkScrollButtons();
    };

    scrollElement.addEventListener("scroll", handleScroll);

    // Check for resizing the window (for zoom effect)
    const handleResize = () => {
      checkScrollButtons();
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listeners on component unmount
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Check if the scroll position is at the start or end initially
  useEffect(() => {
    checkScrollButtons();
  }, [tabsList]);

  return (
    <Box>
      <TopContentContainer>
        <Typography
          component="p"
          sx={{ color: "#279989", fontWeight: 700, padding: "0px" }}
        >
          {`Clients > ${
            activeUserName ? activeUserName : userName
          } > ${activeTabText()}`}
        </Typography>
        {/* <Link to="/clients" style={{ textDecoration: "none" }}> */}
        <CustomButton text="Back" onClick={handleBackButtonClick} />
        {/* </Link> */}
      </TopContentContainer>
      <br />

      <Box
        component="div"
        sx={{
          background: "white",
          width: "100%",
          position: "relative",
          borderRadius: "15px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "end",
            position: "absolute",
            //position: "relative",
            top: 0,
            left: "50%",
            transform: "translate(-50%, -100%)",
            zIndex: 1,
            maxWidth: "100%",
          }}
        >
          {/* Left Arrow Button */}
          {showArrows && (
            <Box>
              <IconButton
                sx={{
                  zIndex: 2,
                  //backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={handleScrollLeft}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
            </Box>
          )}
          <Box
            ref={scrollRef}
            sx={{
              display: "flex",
              alignItems: "end",
              overflowX: "auto",
              borderRadius: "5px",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
            }}
          >
            {tabsList.map((each) => (
              <TabButton
                key={each.id}
                isActive={activeTab === each.id}
                onClick={() => {
                  handleTabSwitch(each.id);
                }}
                data={each}
              />
            ))}
          </Box>

          {/* Right Arrow Button */}
          {showArrows && (
            <Box>
              <IconButton
                sx={{
                  zIndex: 2,
                  //backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={handleScrollRight}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        {renderActiveTabContent()}
      </Box>
      <ToastContainer />

      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
