import React, { useState, useEffect, useMemo } from "react";
import { getCookie } from "../../../utils/utils";
import {
  Container,
  Grid,
  Card,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../Theme";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import EYE from "../../../assets/images/users/view.svg";
import { sections } from "./questionnaire";
import { nonequestions } from "./questionnaire";
import { emptyCompetency } from "./questionnaire";
import { Calendar } from "../../reusableComponents";
import dayjs from "dayjs";
import InformationDialog from "../../reusableComponents/informationDialog";

export default function UserCompetency({ userId }) {
  const initialCompetency = useMemo(() => emptyCompetency, []);
  const [assesments, setAssessments] = useState([]);
  const [activeAssessment, setActiveAssessment] = useState(0);
  const [competency, setCompetency] = useState(initialCompetency);
  const [openDialog, setOpenDialog] = useState(false);
  const [fullAssessment, setFullAssessment] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setActiveAssessment(0);
  };

  useEffect(() => {
    const fetchUsersAPI = async () => {
      if (!userId) return;
      const token = getCookie();
      const baseURL = process.env.REACT_APP_BASE_URL;
      const URL = `${baseURL}/competency?user_id=${userId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await fetch(URL, options);
        const data = await response.json();
        const finalData = data.data;
        if (response.ok && finalData.length > 0) {
          const newData = finalData[0] || {};
          setAssessments(finalData.filter((r) => r.sign_2 !== ""));
          if (newData.sign_1 !== "" && newData.sign_2 === "") {
            setActiveAssessment(finalData[0].id);
            // setCompetency((prevState) => ({
            //   ...prevState,
            //   ...newData,
            //   "c18-checkbox1": newData.c18_c1 === "true",
            //   "c18-checkbox2": newData.c18_c2 === "true",
            //   "c18-checkbox3": newData.c18_c3 === "true",
            //   "c18-checkbox4": newData.c18_c4 === "true",
            //   "c18-checkbox5": newData.c18_c5 === "true",
            //   "c18-checkbox6": newData.c18_c6 === "true",
            //   "c18-checkbox7": newData.c18_c7 === "true",
            //   "c18-checkbox8": newData.c18_c8 === "true",
            //   "c18-checkbox9": newData.c18_c9 === "true",
            //   "c18-checkbox10": newData.c18_c10 === "true",
            //   "c18-checkbox11": newData.c18_c11 === "true",
            //   "c46-checkbox": newData.consent === true,
            // }));
            const sanitizedData = Object.fromEntries(
              Object.entries(newData).map(([key, value]) => {
                if (value === "true") return [key, true];
                if (value === "false") return [key, false];
                return [key, value];
              })
            );
            setCompetency(sanitizedData);
          } else {
            setActiveAssessment(0);
            setCompetency((prevState) => ({
              ...prevState,
              ...emptyCompetency,
              sign_1: "",
              sign_2: "",
              date_1: "",
              date_2: "",
            }));
          }
        } else {
          setActiveAssessment(0);
          setCompetency(initialCompetency);
        }
      } catch (error) {
        console.error("Failed to fetch users API:", error);
      }
    };
    fetchUsersAPI();
  }, [userId, initialCompetency]);

  const formatDates = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const selectedAssesment = async (ind) => {
    setActiveAssessment(ind);
    const token = getCookie();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const URL = `${baseURL}/competency?user_id=${userId}&id=${ind}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(URL, options);
      const data = await response.json();
      const finalData = data.data;
      if (response.ok && finalData.length > 0) {
        const newData = finalData[0];
        const sanitizedData = Object.fromEntries(
          Object.entries(newData).map(([key, value]) => {
            if (value === "true") return [key, true];
            if (value === "false") return [key, false];
            return [key, value];
          })
        );
       
        setFullAssessment(sanitizedData);
        console.log("fullAssessment of history===================", sanitizedData);
        handleOpen();
      } else {
        setFullAssessment([]);
      }
    } catch (error) {
      console.error("Failed to fetch users API:", error);
    }
  };
  console.log("competency of history usedstyate data===================", competency);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        maxWidth={false}
      >
        <Box>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={8.5} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  marginBottom: 0,
                  backgroundColor: ["#ffffff", "!important"], // If you need !important
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    paddingRight: 2,
                    width: "100%",
                  }}
                >
                  <Grid container direction="row" alignItems="top">
                    <Grid item xs={12}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(3, 3) }}
                      >
                        {sections.map((section, index) => (
                          <Box key={index} sx={{ mb: 4 }}>
                            <Typography variant="h4">
                              {section.title}
                            </Typography>
                            {section.subtitle && (
                              <Typography variant="h5">
                                {section.subtitle}
                              </Typography>
                            )}
                            {section.subtitle === "Medicine form" ? (
                              <Box>
                                {section.questions.map((question) => (
                                  <FormControlLabel
                                    key={question.id}
                                    label={question.text}
                                    control={
                                      <Checkbox
                                        checked={!!competency[question.id]} // Ensure boolean value
                                        // onChange={handleCheckboxChange}
                                        name={question.id}
                                      />
                                    }
                                  />
                                ))}
                              </Box>
                            ) : (
                              section.questions.map((question) => (
                                <Box key={question.id} sx={{ mb: 3 }}>
                                  {question.id.includes("checkbox") ? null : (
                                    <>
                                      <FormLabel>
                                        <Typography variant="h6">
                                          {question.text}
                                        </Typography>
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        sx={{ padding: "0", marginTop: "5px" }}
                                        name={question.id}
                                        value={competency[question.id]}
                                        disabled
                                      >
                                        <FormControlLabel
                                          value="yes"
                                          control={<Radio disabled />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="no"
                                          control={<Radio disabled />}
                                          label="No"
                                        />
                                        {nonequestions.includes(
                                          question.id
                                        ) && (
                                          <FormControlLabel
                                            value="none"
                                            control={<Radio disabled />}
                                            label="None seen this time"
                                          />
                                        )}
                                      </RadioGroup>
                                    </>
                                  )}
                                </Box>
                              ))
                            )}
                          </Box>
                        ))}
                        <FormLabel>
                          <Typography variant="h4">
                            Any other information
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="info"
                          variant="outlined"
                          className="multiline-textfield"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.info}
                          InputProps={{ readOnly: true }}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Actions/exceptions identified{" "}
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="action"
                          variant="outlined"
                          className="multiline-textfield"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.action}
                          InputProps={{ readOnly: true }}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Signatures of All Involved Admins in the Assessment
                          </Typography>
                        </FormLabel>
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 8, mt: 2 }}
                        >
                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Signature</Typography>
                            </FormLabel>
                            <TextField
                              name="sign_1"
                              variant="outlined"
                              fullWidth
                              value={competency.sign_1}
                              //disabled
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Date</Typography>
                            </FormLabel>
                            <TextField
                              name="date_1"
                              variant="outlined"
                              fullWidth
                              value={
                                competency.date_1
                                  ? formatDates(competency.date_1)
                                  : ""
                              }
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                        </Grid>
                        {competency.sign_1 === "" ||
                        user.id === competency.user_id ||
                        competency.sign_2 === "" ? null : (
                          <Grid
                            container
                            direction="row"
                            alignItems="top"
                            sx={{ gap: 8 }}
                          >
                            <Grid item xs={10} lg={4}>
                              <FormLabel>
                                <Typography variant="h6">Signature</Typography>
                              </FormLabel>
                              <TextField
                                name="sign_2"
                                variant="outlined"
                                fullWidth
                                value={competency.sign_2}
                                disabled={
                                  competency.sign_2 !== "" ||
                                  competency.editable === 0
                                }
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={10} lg={4}>
                              <FormLabel>
                                <Typography variant="h6">Date</Typography>
                              </FormLabel>
                              <TextField
                                name="date_2"
                                variant="outlined"
                                fullWidth
                                value={
                                  competency.date_1
                                    ? formatDates(competency.date_2)
                                    : ""
                                }
                                InputProps={{ readOnly: true }}
                              ></TextField>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={3.5} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  marginBottom: 0,
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "17px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                  <br />
                  {assesments?.map((a, index) => (
                    <div
                      style={{ paddingBottom: "4px" }}
                      key={index}
                      onClick={() => selectedAssesment(a.id)}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          border: "2px solid #F0FCFA",
                          minheight: "60px",
                          padding: "5px 15px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          background: "#C0EBE4",
                          cursor: "pointer",
                          border:
                            a.id === activeAssessment
                              ? "2px solid #279989"
                              : "2px solid #F0FCFA",
                          "&:hover": {
                            border: "2px solid #279989", // Correct way to use :hover in MUI sx
                          },
                        }}
                      >
                        {/* <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        > */}
                        <Grid item xs={2} sx={{ margin: "0 2px" }}>
                          <Box>
                            <img
                              src={ASSESMENT_FILE}
                              alt="user logo"
                              height="35px"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ margin: "0 1px" }}>
                          <Box sx={{ paddingLeft: "10px" }}>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                                paddingBottom: 0,
                              }}
                              variant="h5"
                            >
                              {a.sign_2}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                              }}
                              variant="h6"
                            >
                              {formatDates(a.date_2)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sx={{ margin: "0 1px" }}>
                          {/* </Box> */}

                          <Box>
                            <img
                              src={EYE}
                              alt={index}
                              style={{ width: "22px", height: "22px" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {openDialog && (
          <InformationDialog
            competency={fullAssessment}
            userId={userId}
            openDialog={openDialog}
            competencyId={0}
            handleCloseDialog={handleClose}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}

