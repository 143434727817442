import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
  } from "react";
  import { Box, FormLabel, Typography, TextField } from "@mui/material";
  
  const City = forwardRef(
    (
      {
        nolabel = false,
        label,
        preFilledvalue,
        name,
        placeholder,
        onValueChange,
        required = false,
        AllCapital = false,
        maxLength,
        disabled = false,
        multiline = false,
        rows = 0,
        className,
      },
      ref
    ) => {
      const [value, setValue] = useState(preFilledvalue || "");
      const [errorMessage, setErrorMessage] = useState("");
  
      useEffect(() => {
        if (preFilledvalue) {
          setValue(preFilledvalue);
          setErrorMessage("");
        } else {
          setValue("");
          setErrorMessage("");
        }
      }, [preFilledvalue]);
  
      const capitalizeFirstLetter = (str) => {
        if (AllCapital) {
          return str.toUpperCase();
        }
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
      };
  
      const handleChange = (event) => {
        if (disabled) return;
        const inputValue = event.target.value;
  
        // Allow only alphabets
        const sanitizedValue = inputValue.replace(/[^a-zA-Z]/g, "");
  
        const capitalizedValue = capitalizeFirstLetter(sanitizedValue);
  
        setValue(capitalizedValue);
        setErrorMessage(""); // Clear error message as soon as value changes
        onValueChange(capitalizedValue, event.target.name); // Notify parent component about value change
      };
  
      const handleValidation = () => {
        const strValue = String(value).trim();
        if (required && !strValue) {
          setErrorMessage(`${label} is required.`);
          return false;
        }
  
        if (!/^[a-zA-Z]*$/.test(strValue)) {
          setErrorMessage(`Please enter a valid ${label} with alphabets only.`);
          return false;
        }
  
        if (maxLength && strValue.length > maxLength) {
          setErrorMessage(`${label} cannot exceed ${maxLength} characters.`);
          return false;
        }
  
        setErrorMessage("");
        return true;
      };
  
      useImperativeHandle(ref, () => ({
        handleValidation,
      }));
  
      return (
        <Box sx={{ textAlign: "left" }}>
          {!nolabel && (
            <FormLabel>
              <Typography variant="h6">
                {label}
                {required && <span className="star">*</span>}
              </Typography>
            </FormLabel>
          )}
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            value={value}
            name={name}
            inputProps={{ maxLength }} // Restrict max length in the input field itself
            onChange={handleChange}
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            className={className}
          />
          {errorMessage && (
            <Typography variant="errmsg">{errorMessage}</Typography>
          )}
        </Box>
      );
    }
  );
  
  export default City;
  