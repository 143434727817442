import React, { useEffect, useImperativeHandle,forwardRef, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UserTableEdit from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import UserTableEditHover from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import useStyles from "../styles/useStyles";
import EditMedicationForm from "./EditMedicationForm";
import StyledCheckBox from "../../reusableComponents/StyledCheckbox";
import { numberToText } from "../../../cms/dropDown";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import { IMG, TablePopup } from "../../users/userTable/Components";
import incrementIcon from "../../../assets/images/common/incrementIcon.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useMedicationData from "./useMedicationData";
const sessionsConstants = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const getDayNameList = (frequencyList,times) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    
      }}
    >
      {sessionsConstants?.map((day, index) => {
        const isSelected = frequencyList?.includes(day);
        return (
          <Box
            key={index}
            style={{
              borderRadius: "5px",
              border: "2px solid rgba(39, 153, 137, 0.30)",
              background: isSelected ? "#279989" : "#FFF",
              color: isSelected ? "#FFF" : "#1E3037",
              fontWeight: isSelected ? 700 : 400,
              height: 30,
              width: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0px 3px",
              fontSize: "12px",
            }}
          >
            {day.slice(0, 2)}
          </Box>
            
        );
      })}
    </Box>
    
  );
};
const shownumbertext = (number) => {
  const timesTextMap = {
    0: "Never",
    1: "Once a Day",
    2: "Twice a Day",
    3:"Thrice a Day"
  };
  return (
    <Box sx={{pt:1}}>
    <small style={{fontSize:"13px",color:"#000000",fontWeight:500}}>
      {timesTextMap[number] || `${numberToText(number)} Times a Day`}
    </small>
    </Box>
  )
}
const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "23px",
        height: "23px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};
const MangeMedicationTable = forwardRef(({ medicationData, setActiveTabId, refreshData, setHasUnsavedChanges,setSelectToDownload }, ref) => {
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [selectToEditMedication, setSelectToeditMedication] = useState(null);
  const [openEditMedicationForm, setOpenEditMedicationForm] = useState(false);
  const [medicationOpen, setMedicationOpen] = useState(false);
  const [selectedMedicationType, setSelectedMedicationType] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState([]);
  const [supportOpen, setSupportOpen] = useState(false);
  const classes = useStyles();
  const {medicines,medicationRoute} = useMedicationData();
  useEffect(() => {
    if (selectedMedications.length > 0) {
      setSelectToDownload(selectedMedications);
     
      
    }else{
      setSelectToDownload([]); 
    }
  }, [selectedMedications]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allIds = medicationData.map((med) => med.medication_id);
      setSelectedMedications(allIds);
      console.log("Selected Medications:", allIds); // Log the updated array directly
    } else {
      setSelectedMedications([]);
      console.log("Selected Medications: []"); // Log an empty array
    }
  };
  const toggleEditMedicationPopup = () => {
    setOpenEditMedicationForm(!openEditMedicationForm);
  };

  const handleCheckboxClick = (medicationId) => {
    setSelectedMedications((prevSelected) => {
      const updatedSelection = prevSelected.includes(medicationId)
        ? prevSelected.filter((id) => id !== medicationId)
        : [...prevSelected, medicationId];
      console.log("Selected Medications:", updatedSelection); // Log the updated array directly
      return updatedSelection;
    });
  };
  const adminFiltList = [
    {
      id: 1,
      text: "Select All",
      onChange: () => {
        setSelectedMedicationType(
          selectedMedicationType.length > 0
            ? []
            : ["Blister Pack", "Scheduled", "PRN"]
        );
        setMedicationOpen(false); // Close the popup after selection
      },
      checked:
        selectedMedicationType.length === 3 ||
        (selectedMedicationType.includes("Blister Pack") &&
          selectedMedicationType.includes("Scheduled") &&
          selectedMedicationType.includes("PRN")),
    },
    {
      id: 2,
      text: "Blister Pack",
      onChange: () => {
        if (selectedMedicationType.includes("Blister Pack")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "Blister Pack")
          );
        } else {
          setSelectedMedicationType([
            ...selectedMedicationType,
            "Blister Pack",
          ]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("Blister Pack"),
    },
    {
      id: 3,
      text: "Scheduled",
      onChange: () => {
        if (selectedMedicationType.includes("Scheduled")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "Scheduled")
          );
        } else {
          setSelectedMedicationType([...selectedMedicationType, "Scheduled"]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("Scheduled"),
    },
    {
      id: 4,
      text: "PRN",
      onChange: () => {
        if (selectedMedicationType.includes("PRN")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "PRN")
          );
        } else {
          setSelectedMedicationType([...selectedMedicationType, "PRN"]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("PRN"),
    },
  ];
  const medicationSupport = [
    {
      id: 1,
      text: "Select All",
      onChange: () => {
        setSelectedSupport(
          selectedSupport.length > 0
            ? []
            : ["Administer", "Assist", "Prompt"]
        );
        setSupportOpen(false); // Close the popup after selection
      },
      checked:
      selectedSupport.length === 3 ||
        (selectedSupport.includes("Administer") &&
        selectedSupport.includes("Assist") &&
        selectedSupport.includes("Prompt")),
    },
    {
      id: 2,
      text: "Administer",
      onChange: () => {
        if (selectedSupport.includes("Administer")) {
          setSelectedSupport(
            selectedSupport.filter((type) => type !== "Administer")
          );
        } else {
          setSelectedSupport([
            ...selectedSupport,
            "Administer",
          ]);
        }
        setSupportOpen(false); // Close the popup after selection
      },
      checked: selectedSupport.includes("Administer"),
    },
    {
      id: 3,
      text: "Assist",
      onChange: () => {
        if (selectedSupport.includes("Assist")) {
          setSelectedSupport(
            selectedSupport.filter((type) => type !== "Assist")
          );
        } else {
          setSelectedSupport([...selectedSupport, "Assist"]);
        }
        setSupportOpen(false); // Close the popup after selection
      },
      checked: selectedSupport.includes("Assist"),
    },
    {
      id: 4,
      text: "Prompt",
      onChange: () => {
        if (selectedSupport.includes("Prompt")) {
          setSelectedSupport(
            selectedSupport.filter((type) => type !== "Prompt")
          );
        } else {
          setSelectedSupport([...selectedSupport, "Prompt"]);
        }
        setSupportOpen(false); // Close the popup after selection
      },
      checked: selectedSupport.includes("Prompt"),
    },
  ];  
  const filteredData = medicationData?.filter((data) =>
    (selectedMedicationType?.length === 0 || selectedMedicationType?.includes(data?.medication_type)) &&
    (selectedSupport?.length === 0 || selectedSupport?.includes(data?.medication_support))
  );
  const exportClientsPdf = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 3; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo
  
    // Add background color
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F");
  
    // Add left and right logos aligned with the table
    doc.addImage(CareboxLogo, "JPEG", tableMargin, imageYPosition, logoWidthLeft, imageHeight); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    ); // Right logo
     // Add title
    doc.setFontSize(18);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text("Medication List", pageWidth / 2, imageYPosition + imageHeight / 2, { align: "center" });
    // Add date below the title
    doc.setFontSize(12);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().slice(0, 10);
    doc.text(`Date: ${today}`, tableMargin, imageYPosition + imageHeight + 5);
    // Data Preparation
    const finalMedications = filteredData?.filter((u) =>
      selectedMedications?.includes(u.medication_id)
    );
    if (!finalMedications.length) {
      console.error("No data available to display.");
      return;
    }
  
    const body = finalMedications?.map((med) => {
      return [
        med?.nhs_medicine_name,
        med.quantity_each_dose,
        med?.medication_type,
        med?.medication_route_name,
        med?.medication_support,
        "", // Placeholder for Frequency column
        med?.medication_type === "Blister Pack"
        ? med?.blister_pack_start_date
        : med?.medication_type === "PRN"
        ? med?.prn_start_date
        : med?.scheduled_start_date || med?.by_exact_start_date,
         med?.medication_type === "Blister Pack"
        ? med?.blister_pack_end_date
        : med?.medication_type === "PRN"
        ? med?.prn_end_date
        : med?.scheduled_end_date || med?.by_exact_end_date,
      ];
    });
  
    const clientColumns = [
      "Medication",
      "Quantity",
      "Type",
      "Route",
      "Support",
      "Frequency", // Add the frequency column
      "Start Date",
      "End Date",
    ];
    doc.autoTable({
      head: [clientColumns], // Table header (column titles)
      body: body, // Table body (data rows)
      startY: 34,
      tableWidth: "auto", // Adjusts table width to content
      margin: { top: 10, left: 3, right: 3 }, // Table margin
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" }, 
        1: { cellWidth: "auto", halign: "center" }, 
        2: { cellWidth: "auto", halign: "center" }, 
        3: { cellWidth: "auto", halign: "center" }, 
        4: { cellWidth: "auto", halign: "center" }, 
        5: { cellWidth: 54, halign: "center" }, 
        6: { cellWidth: "auto", halign: "center" }, 
        7: { cellWidth: "auto", halign: "center" }, 
      },
      headStyles: {
        halign: "center", // Center-align header cells
        valign: "middle", // Vertical alignment for header
        fillColor: "#279989",
        fontStyle: "Courier",
      },
      styles: {
        cellPadding: { top: 5.4, right: 1.5, bottom: 5.4, left: 1.5 },
      },
      didDrawCell: (data) => {
        if (data.section === "body" && data.column.index === 5) {
          const rowIndex = data.row.index;
          const med = finalMedications[rowIndex];
    
          // Determine days for frequency
          const days =
            med?.medication_type === "Blister Pack"
              ? med?.blister_pack_dayname || []
              : med?.medication_type === "PRN"
              ? ''
              : med?.scheduled_dayname || [];
    
          const x = data.cell.x; // Start drawing from the beginning of the cell
          const y = data.cell.y + 2; // Base Y position (slightly lower for better alignment)
          const boxSize = 6;
          const spacing = 1.5;
    
          // Draw boxes for each day
          if (med?.medication_type !== "PRN"){

            sessionsConstants?.forEach((day, index) => {
            const isDaySelected = days?.includes(day);
      
              // Conditional colors and styles
              const borderColor = isDaySelected ? [39, 153, 137] : [39, 153, 137]; // Green border for both cases
              const backgroundColor = isDaySelected ? [39, 153, 137] : [255, 255, 255]; // Green for selected, white for unselected
              const textColor = isDaySelected ? [255, 255, 255] : [39, 153, 137]; // White text for selected, green for unselected
      
              // Draw rounded rectangle (box)
              doc.setDrawColor(...borderColor); // Set border color
              doc.setFillColor(...backgroundColor); // Set background color
              doc.roundedRect(
                x + index * (boxSize + spacing), // X position with consistent starting alignment
                y, // Y position
                boxSize, // Width
                boxSize, // Height
                1, // Horizontal corner radius
                1, // Vertical corner radius
                "FD" // Fill and draw
              );
      
              // Set text properties
              doc.setTextColor(...textColor); // Set text color
              doc.setFontSize(8);
              doc.text(
                day.slice(0, 2), // First two letters of the day
                x + index * (boxSize + spacing) + boxSize / 2, // Center the text horizontally
                y + boxSize / 2 + 1, // Center the text vertically (adjust based on font size)
                { align: "center" } // Center alignment
              );
  
            });
          }
          const getAdditionalText = (med) => {
            const timesTextMap = {
              0: "Never",
              1: "Once a Day",
              2: "Twice a Day",
              3: "Thrice a Day",
            };
          
            if (med?.medication_type === "Blister Pack") {
              return timesTextMap[med?.blister_pack_times_per_day] || `${numberToText(med?.blister_pack_times_per_day)} Times a Day`;
            } else if (med?.medication_type === "PRN") {
              return ""; // No text for PRN
            } else {
              return timesTextMap[med?.scheduled_times_per_day] || `${numberToText(med?.scheduled_times_per_day)} Times a Day`;
            }
          };
          
          const additionalText = getAdditionalText(med);
          
          if (additionalText) {
            doc.setFontSize(8);
            doc.setTextColor(100, 100, 100);
            doc.text(
              additionalText, // Text content
              x + data.cell.width / 2, // Centered horizontally within the cell
              y + boxSize + 4, // Position below the last box (adjust as needed)
              { align: "center" } // Center alignment
            );
          }
        }
      },
    });   //   // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    doc.setFillColor("f5fcff");
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        pageWidth / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }
   // Save the PDF
    
    doc.save(`medication_list_${today}.pdf`);
  };
  useImperativeHandle(ref, () => ({
    exportClientsPdf,
    setSelectedMedications  
  }));
  return (
    <Box sx={{width: "100%"}}>
      <Box
        sx={{
          width: "100%",
          boxShadow: "0px 0px 10px 0px #2799894D",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        {openEditMedicationForm && (
          <EditMedicationForm
            medicationroute={medicationRoute}
            medicines={medicines}
            selectToEditMedication={selectToEditMedication}
            toggleEditMedicationPopup={toggleEditMedicationPopup}
            openEditMedicationForm={openEditMedicationForm}
            setActiveTabId={setActiveTabId}
            refreshData={refreshData}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        )}
        {medicationData.length > 0 ? (
          <>
            <TableContainer sx={{ borderRadius: "10px",width: "100%" ,overflowY: "auto",position: "relative", height: "calc(100vh - 300px)",top:0}}>
              <Table className={classes.medicationTable}>
                <TableHead sx={{ backgroundColor: "#279989", position: "sticky", top:0,zIndex:1000 }}>
                  <TableRow>
                    <TableCell padding="checkbox" className={classes.tableCell}>
                     <StyledCheckBox indeterminate={
                      selectedMedications.length > 0 &&
                      selectedMedications.length < medicationData.length
                      }
                         checked={
                        medicationData.length > 0 &&
                         selectedMedications.length === medicationData.length
                         }
                        onChange={handleSelectAllClick}
                        />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Medication
                    </TableCell>
                    <TableCell className={classes.tableCell}>Quantity</TableCell>
                    <TableCell className={classes.tableCell}>
                    <Box sx={{display: "flex", alignItems: "center",position: 'sticky',justifyContent:'center'}}>
                     Type
                    <IMG
                    imgUrl={incrementIcon}
                    onClick={() => setMedicationOpen(!medicationOpen)}
                       />
                  {medicationOpen && (
                    <TablePopup
                      items={adminFiltList.map((item) => ({
                        id: item.id,
                        text: item.text,
                        checked: item.checked,
                        onChange: item.onChange,
                      }))}
                      onClose={() => setMedicationOpen(false)}
                    />
                  )}
                    </Box>
                   
                    </TableCell>
                    <TableCell className={classes.tableCell}>Route</TableCell>
                    <TableCell className={classes.tableCell}>
                    <Box sx={{display: "flex", alignItems: "center",position: 'sticky',justifyContent:'center'}}>
                     Support
                    <IMG
                    imgUrl={incrementIcon}
                    onClick={() => setSupportOpen(!supportOpen)}
                       />
                  {supportOpen && (
                    <TablePopup
                      items={medicationSupport.map((item) => ({
                        id: item.id,
                        text: item.text,
                        checked: item.checked,
                        onChange: item.onChange,
                      }))}
                      onClose={() => setMedicationOpen(false)}
                    />
                  )}
                    </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Frequency
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Start Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      End Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Created By
                    </TableCell>
                    <TableCell className={classes.tableCell} sx={{ pr: 3 }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((med) => (
                    <TableRow key={med.medication_id}  sx={{ "&:nth-of-type(even)": {
                      backgroundColor: "#EEFEFA"}}} >
                      <TableCell
                        padding="checkbox"
                        className={classes.tablebodycell}
                      >
                       <StyledCheckBox
                          checked={selectedMedications.includes(med.medication_id)}
                          onChange={() => handleCheckboxClick(med.medication_id)}
                          />
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.nhs_medicine_name}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.quantity_each_dose}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_type}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_route_name}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_support}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_type === "Blister Pack"
                          ? getDayNameList(med.blister_pack_dayname,)
                          : med.medication_type === "PRN"
                          ? `${med.prn_dose_per} Doses per ${med.prn_doses} ${med.prn_time_frame}`
                          : getDayNameList(med.scheduled_dayname,
                          )}
                          {
                            med.medication_type === "Blister Pack" ? shownumbertext(med.blister_pack_times_per_day):
                            med.medication_type === "PRN" ? '':shownumbertext(med.scheduled_times_per_day)
                          }

                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_type === "Blister Pack"
                          ? med.blister_pack_start_date
                          : med.medication_type === "PRN"
                          ? med.prn_start_date
                          : med.scheduled_start_date || med.by_exact_start_date}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_type === "Blister Pack"
                          ? med.blister_pack_end_date ||'-'
                          : med.medication_type === "PRN"
                          ? med.prn_end_date || '-'
                          : med.scheduled_end_date || med.by_exact_end_date ||'-'}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        {med.medication_type === "Blister Pack"? med.blister_pack_created_by: med.medication_type === "PRN" ? med.prn_created_by : med.scheduled_created_by || med.blister_pack_created_by}
                      </TableCell>
                      <TableCell className={classes.tablebodycell}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: "0",
                            cursor: "pointer",
                          }}
                          // onClick={() => togoleEditTodoPopup()}
                          onClick={() => {
                            setSelectToeditMedication(med);
                            toggleEditMedicationPopup();
                          }}
                        >
                          <HoverImg
                            imgBeforeHover={UserTableEdit}
                            imgAfterHover={UserTableEditHover}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box>
            <h5 style={{ textAlign: "center", padding: "10px" }}>
              No Medication Found Please Add Medication
            </h5>
          </Box>
        )}
      </Box>
    </Box>
  );
});
export default MangeMedicationTable;