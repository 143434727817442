import { React, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import MiddleContent from "./MiddleContent";
import UserLogin from "./UserLogin";

import careSteemLogo from "../../../assets/images/common/careSteemLogo.svg";

import agencyLogo from "../../../assets/images/common/agencyLogo.png";
import themeStyle from "../../../constants/themeStyle";
import IconChangePopup from "./IconChangePopup";
import InvoicePopup from "./InvoicePopup";

function Header({ hasUnsavedChanges, setHasUnsavedChanges }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [openChangeIcon, setOpenChangeIcon] = useState(false);

  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);

  const [agencySelectedLogo, setAgencySelectedLogo] = useState(false);
  const [finalSelectedProfilePhoto, setFinalSelectedProfilePhoto] = useState();

  const [changeOrEdit, setChangeOrEdit] = useState();

  const handleDialog = () => {
    console.log("Closing dialog...");
    setOpenInvoiceDialog(false);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: themeStyle.colors.primary.white,
        boxShadow: themeStyle.shadows.header,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          position: "relative",
          width: "100%",
          margin: "auto",
        }}
      >
        <img
          style={{ width: "20%", maxWidth: "110px", marginRight: "5px" }}
          src={careSteemLogo}
          alt="careSteemLogo"
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translate(-50%, -30%)",
          }}
        >
          {!isTablet && (
            <MiddleContent
              hasUnsavedChanges={hasUnsavedChanges}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          )}
        </Box>

        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{
              height: "45px",
              width: "45px",
              marginLeft: "30px",
              marginRight: "40px",
            }}
            src={agencySelectedLogo ? agencySelectedLogo : agencyLogo}
            alt="agencyLogo"
          />

          <UserLogin
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setOpenChangeIcon={setOpenChangeIcon}
            setChangeOrEdit={setChangeOrEdit}
            finalSelectedProfilePhoto={finalSelectedProfilePhoto}
            setOpenInvoiceDialog={setOpenInvoiceDialog}
          />
        </div>
      </Toolbar>

      <IconChangePopup
        logo={agencySelectedLogo ? agencySelectedLogo : agencyLogo}
        openChangeIcon={openChangeIcon}
        onClose={() => setOpenChangeIcon(false)}
        setAgencySelectedLogo={setAgencySelectedLogo}
        setFinalSelectedProfilePhoto={setFinalSelectedProfilePhoto}
        changeOrEdit={changeOrEdit}
      />

      {openInvoiceDialog && (
        <InvoicePopup
          open={openInvoiceDialog}
          handleInvoiceDialog={handleDialog}
        />
      )}
    </AppBar>
  );
}

export default Header;
