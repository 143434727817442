import { Box, Button, Modal, Typography, IconButton, } from "@mui/material";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Calendar,
  Dropdown,
  RadioInput,
  Sessions,
  Days,
  Name,
  NumberInput,
  Time,
 
} from "../../reusableComponents";
import dayjs from "dayjs";
import { BlisterSesiions, medicationPrnTime } from "../../../cms/dropDown";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CustomButtom from "../../reusableComponents/CustomButton";

import { getCookie } from "../../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import EditDays from "./EditDays";
import Admin from "../../../assets/images/users/Admin.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg"
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import EditBodyMap from "./EditBodyMap";
import BodyMapView from "./BodyMapView";


function EditMedicationForm({
  toggleEditMedicationPopup,
  selectToEditMedication,
  openEditMedicationForm,
  setActiveTabId,
  setHasUnsavedChanges,
  medicines,
  medicationroute,
  refreshData
}) {
  console.log(selectToEditMedication);
  const [medicationType, setMedicationType] = useState("");
  const [medicationInfo, setMedicationInfo] = useState({});
  const [medicationInfoOriginal, setMedicationInfoOriginal] = useState({});
  const [bodyData, setBodyData] = useState([]);
  const [bodyData1, setBodyData1] = useState([]);
  const [bodyData2, setBodyData2] = useState([]);
  const [bodyModeEnable, setBodyModeEnable] = useState("");
  const [selectBysession, setSelectBySession] = useState("By Session");
  const [errors, setErrors] = useState({
    prn_dose: "",
    prn_dose_time: "",
    prn_dose_timeDropdown: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  
  const [edit, setEdit] = useState(false);
  const editBodyMapRef = useRef(null);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  useEffect(() => {
    console.log("Medication Info Updated:", medicationInfo);
  }, [medicationInfo]);
  const handleOpen = () =>{
    if(!areObjectsEqual(medicationInfo, medicationInfoOriginal) || bodyData.length > 0){
      setOpenDialog(true);
    }else{
     
      toggleEditMedicationPopup()
      setHasUnsavedChanges(false)
    }
  };
  const closeEditMedicationForm = () => {
    if(!areObjectsEqual(medicationInfo, medicationInfoOriginal) || medicationInfo?.body_images > 0){
      setOpenDialog(true);
    }else{
     
      toggleEditMedicationPopup()
      setHasUnsavedChanges(false)
    }
  }
  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialog(false);
   ;};
  const onClickYesDialogBtn = () => {
    toggleEditMedicationPopup()
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
  };
  const [startHour, startMinute] = selectToEditMedication.by_exact_time.split(":");
  let choosesessions = selectToEditMedication?.blister_pack_choose_sessions?.map(
    (eachsession, index) => ({
      id: parseInt(eachsession) || 0,
      text:
        selectToEditMedication.blister_pack_choose_sessions_session_type[index] ||
        "",
      value:
        selectToEditMedication.blister_pack_choose_sessions_session_type[index] ||
        "",
    })
  );
  let SheduledSessions = selectToEditMedication?.scheduled_choose_sessions?.map(
    (eachsession, index) => ({
      id: parseInt(eachsession) || 0,
      text:
        selectToEditMedication.scheduled_choose_sessions_session_type[index] ||
        "",
      value:
        selectToEditMedication.scheduled_choose_sessions_session_type[index] ||
        "",
    })
  );
   useEffect(() => {
    if (selectToEditMedication) {
      const parts = selectToEditMedication?.body_image[0].split(",");
      const base64Images = parts.filter(
        (_, index) => index % 2 !== 0 && parts[index].trim() !== ""
      );
  
      // Prepend "data:image/png;base64," and update state
      let formattedBase64Images = base64Images.map(
        (base64Data) => `data:image/png;base64,${base64Data}`
      );
      console.log("formattedBase64Images", formattedBase64Images);
      // setBodyData2(formattedBase64Images);
      // setBodyData(selectToEditMedication?.body_part_names);
      let initialTodoInfo = {
        // client_id: parseInt(clientId),
        visit_id: 218,
        NHS_Medicine_Database: selectToEditMedication?.nhs_medicine || "",
        medication_Route: selectToEditMedication?.medication_route,
        quantity_for_dose: String(selectToEditMedication?.quantity_each_dose || ""), // Convert to string
        adm_qty_for_dose: String(selectToEditMedication?.blister_pack_times_per_day || ""),
        select_days: selectToEditMedication?.blister_pack_select_days || [],
        blister_main_session: choosesessions || [],
        medication_support: selectToEditMedication?.medication_support,
        medication_type: selectToEditMedication?.medication_type,
        blister_sDate: selectToEditMedication?.blister_pack_start_date,
        bister_eDate: selectToEditMedication?.blister_pack_end_date,
        blister_start_session:selectToEditMedication?.blister_pack_start_choose_session,
        blister_end_session:selectToEditMedication?.blister_pack_end_choose_session,
        //sheduled
        sheduled_many_times: String(selectToEditMedication?.scheduled_times_per_day || ""),
        sheduled_select_days: selectToEditMedication?.scheduled_select_days || [],
        Select_Preferences: selectToEditMedication?.select_preference || 'By Session',
        sheduled_session: SheduledSessions || [],
        sheduled_by_session_startDate:selectToEditMedication?.scheduled_start_date,
        sheduled_by_session_endDate: selectToEditMedication?.scheduled_end_date,
        sheduled_by_session_start:selectToEditMedication?.scheduled_start_choose_session,
        sheduled_by_session_end:selectToEditMedication?.scheduled_end_choose_session,
        starthtime: startHour || "00",
        startmtime: startMinute || "00",
        By_Exact_start_time: selectToEditMedication?.by_exact_start_date,
        By_Exact_end_time: selectToEditMedication?.by_exact_end_date,
        prn_dose: String(selectToEditMedication?.prn_dose_per || ""), // Convert to string
        prn_dose_time: String(selectToEditMedication?.prn_doses || ""),
        prn_dose_timeDropdown: selectToEditMedication?.prn_time_frame,
        prn_dose_offered: selectToEditMedication?.prn_offered,
        prn_dose_given: selectToEditMedication?.prn_be_given,
        Prn_sDate: selectToEditMedication?.prn_start_date,
        Prn_eDate: selectToEditMedication?.prn_end_date,
        body_mapping: "",
        body_images:formattedBase64Images,
        body_part_names:selectToEditMedication?.body_part_names

      };
      setMedicationInfo(initialTodoInfo);
      setMedicationInfoOriginal(initialTodoInfo);
    }
  }, [selectToEditMedication]);
  const NhsMedicine = useRef();
  const quantityForDose = useRef();
  const admQtyForDose = useRef();
  const choose_sessionsRef = useRef();
  const blister_start_dateRef = useRef();
  const blister_end_dateRef = useRef();
  const blister_main_sessionRef = useRef();
  const blister_start_sessionRef = useRef();
  const blister_end_sessionRef = useRef();
  const select_daysRef = useRef();
  const medication_supportRef = useRef();
  const medication_typeRef = useRef();
  const prnNumberRef = useRef();
  const prnTimeRef = useRef();
  const prnTimeDropdownRef = useRef();
  const prnDoseOfferRef = useRef();
  const prnDoseGivenRef = useRef();
  const prnDateStartRef = useRef();
  const prnDateEndRef = useRef();
  const sheduled_many_timesRef = useRef();
  const sheduled_select_daysRef = useRef();
  const Select_PreferencesRef = useRef();
  const sheduled_sessionRef = useRef();
  const sheduled_by_session_startDateRef = useRef();
  const sheduled_by_session_endDateRef = useRef();
  const sheduled_by_session_startRef = useRef();
  const sheduled_by_session_endRef = useRef();
  const starttimeRef = useRef();
  const By_Exact_start_timeRef = useRef();
  const By_Exact_end_timeRef = useRef();
  const currentDate = dayjs().startOf("day");
  const formattedDate = dayjs().format("YYYY-MM-DD");
  // Utility function to find the label (text) by id

  const getLabelById = (id, list) => {
    const selectedItem = list.find((item) => item.id === id);
    return selectedItem ? selectedItem.text : ""; // Return the text or label corresponding to the id
  };
  const showToastMessage = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };
  const showToastFailureMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
    });
  };
  const handleDropDownChange = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value?.id,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const handleDropDownChange2 = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        prn_dose_timeDropdown: value?.text,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleValueChange = (newValue, name) => {
    // Compute the updated form object
    // const updatedValue = name === "quantity_for_dose" ? parseFloat(newValue) : newValue;
    const updatedForm = { ...medicationInfo, [name]: newValue };
  
    // Log to verify correctness
    console.log("New Value:", newValue);
    console.log("Updated Form Data (before state):", updatedForm);
  
    // Update state with the new form object
    setMedicationInfo(updatedForm);
  
    // Compare updated form with original form
    const hasChanges = !areObjectsEqual(updatedForm, medicationInfoOriginal);
    console.log("Has Unsaved Changes:", hasChanges);
  
    // Update unsaved changes state
    setHasUnsavedChanges(hasChanges);
  
    // Clear errors for the specific field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  
  const handleRadioChange = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value,
      };
      if (name === "medication_type") {
        setMedicationType(value);
      }
      if (name === "Select_Preferences") {
        // Update preference name to match your RadioInput name
        setSelectBySession(value);
      }
      if (name === "Body-map") {
        // Update preference name to match your RadioInput name
        setBodyModeEnable(value);
      }

      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const handleDateChange = (date, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
    console.log("start date", medicationInfo.blister_sDate);
  };
  const handleChange = (name, value) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: [...value] };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const handleChange1 = (name, value) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: value };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const changeAddAndViewMode = () => {
    // Function to compare two arrays for equality
    const areArraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((item, index) => item === arr2[index]);
    };
  
    // Check conditions
    if (edit && !areArraysEqual(medicationInfo.body_images, medicationInfoOriginal.body_images)) {
      setOpenDialog(true); // Open confirmation dialog
    } else {
      setEdit(!edit); // Toggle edit mode
      
    }
  };
  useEffect(() => {
    if (edit && editBodyMapRef.current) {
      editBodyMapRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [edit]);
  
  const handleSubmit = async () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (medicationInfo.medication_type === "PRN" && !medicationInfo.prn_dose) {
      isValid = false;
      newErrors.prn_dose = "enter a valid dose.";
      setErrors(newErrors);
    }
    // Custom validation for prn_dose_time
    if (
      medicationInfo.medication_type === "PRN" &&
      !medicationInfo.prn_dose_time
    ) {
      isValid = false;
      newErrors.prn_dose_time = "enter a valid dose time.";
      setErrors(newErrors);
      console.error("Error: prn_dose_time is required");
    }
    // Custom validation for prn_dose_timeDropdown
    if (
      medicationInfo.medication_type === "PRN" &&
      !medicationInfo.prn_dose_timeDropdown
    ) {
      isValid = false;
      newErrors.prn_dose_timeDropdown = "Please select a valid time option.";
      setErrors(newErrors);
    }
    if (NhsMedicine.current && !NhsMedicine.current.handleValidation()) {
      isValid = false;
    }
    if (
      quantityForDose.current &&
      !quantityForDose.current.handleValidation()
    ) {
      isValid = false;
    }
    if (admQtyForDose.current && !admQtyForDose.current.handleValidation()) {
      isValid = false;
    }
    if (
      choose_sessionsRef.current &&
      !choose_sessionsRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_start_dateRef.current &&
      !blister_start_dateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_end_dateRef.current &&
      !blister_end_dateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (select_daysRef.current && !select_daysRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      medication_supportRef.current &&
      !medication_supportRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      medication_typeRef.current &&
      !medication_typeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_main_sessionRef.current &&
      !blister_main_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_start_sessionRef.current &&
      !blister_start_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_end_sessionRef.current &&
      !blister_end_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    // sheduled form
    if (
      sheduled_many_timesRef.current &&
      !sheduled_many_timesRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_select_daysRef.current &&
      !sheduled_select_daysRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      Select_PreferencesRef.current &&
      !Select_PreferencesRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_sessionRef.current &&
      !sheduled_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_startDateRef.current &&
      !sheduled_by_session_startDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_endDateRef.current &&
      !sheduled_by_session_endDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_startRef.current &&
      !sheduled_by_session_startRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_endRef.current &&
      !sheduled_by_session_endRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (starttimeRef.current && !starttimeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      By_Exact_start_timeRef.current &&
      !By_Exact_start_timeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      By_Exact_end_timeRef.current &&
      !By_Exact_end_timeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (prnNumberRef.current && !prnNumberRef.current.handleValidation()) {
      isValid = false;
    }
    if (prnTimeRef.current && !prnTimeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      prnTimeDropdownRef.current &&
      !prnTimeDropdownRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseOfferRef.current &&
      !prnDoseOfferRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseGivenRef.current &&
      !prnDoseGivenRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseOfferRef.current &&
      !prnDoseOfferRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDateStartRef.current &&
      !prnDateStartRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (prnDateEndRef.current && !prnDateEndRef.current.handleValidation()) {
      isValid = false;
    }
    console.log("bodydata1", bodyData1);
    console.log("bodydata2", bodyData2);
    console.log("bodydata3", bodyData);
    if (isValid) {
      if(medicationInfo === medicationInfoOriginal){
        const msg = "No changes Made In The Form";
        showToastMessage(msg);
      }else{
        await new Promise((resolve) => setTimeout(resolve, 0));
        setHasUnsavedChanges(false);
         await postapi();
         toggleEditMedicationPopup();
       
      }
      // Force synchronization with the latest state
      
      // postapi();

      console.log("form data submitted", medicationInfo);
    } else {
      console.log("invalid");
      const msg = "Please fill in all the required fields.";
      showToastFailureMessage(msg);
    }
  };
  const postapi = async () => {
    console.log("post api called");
    try {
      const ClientId = selectToEditMedication.client_id,
      medicationId = selectToEditMedication.medication_id;
      const token = getCookie();
      const apiUrl = `https://3.8.158.142:8443/api/medication/${ClientId}/${medicationId}`;
      // const URL = `${BASE_URL}/add`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          medicationInfo: {
            nhs_medicine: medicationInfo.NHS_Medicine_Database,
            medication_support: medicationInfo.medication_support,
            medication_route: medicationInfo.medication_Route,
            quantity_each_dose: medicationInfo.quantity_for_dose,
            medication_type: medicationInfo.medication_type,
          },
          scheduledInfo: {
            user_id: parseInt(user.id),
            how_many_times_day: parseInt(medicationInfo?.sheduled_many_times),
            medication_select_days: medicationInfo?.sheduled_select_days,
            select_preference: medicationInfo?.Select_Preferences,
            medication_choose_sessions: medicationInfo?.sheduled_session?.map((each) => each.id),
            start_date: medicationInfo?.sheduled_by_session_startDate,
            start_choose_session:medicationInfo?.sheduled_by_session_start,
            end_date: medicationInfo?.sheduled_by_session_endDate,
            end_choose_session: medicationInfo?.sheduled_by_session_end ,
            by_exact_time: `${medicationInfo?.starthtime}:${medicationInfo.startmtime}`,
            by_exact_start_date: medicationInfo?.By_Exact_start_time,
            by_exact_end_date: medicationInfo?.By_Exact_end_time,
          },
          blisterPackInfo: {
            user_id: parseInt(user.id),
            how_many_times_day: parseInt(medicationInfo.adm_qty_for_dose),
            medication_select_days: medicationInfo?.select_days,
            medication_choose_sessions: medicationInfo.blister_main_session?.map((each) => each.id),
            start_date: medicationInfo.blister_sDate,
            start_choose_session: medicationInfo.blister_start_session,
            end_date: medicationInfo.bister_eDate,
            end_choose_session: medicationInfo.blister_end_session,
          },
          prnInfo: {
            user_id: parseInt(user.id),
            dose_per: parseInt(medicationInfo.prn_dose),
            doses: parseInt(medicationInfo.prn_dose_time),
            time_frame: medicationInfo.prn_dose_timeDropdown,
            prn_offered: medicationInfo.prn_dose_offered,
            prn_be_given: medicationInfo.prn_dose_given,
            start_date: medicationInfo.Prn_sDate,
            end_date: medicationInfo.Prn_eDate,
          },
          bodyMapInfo: {
            body_part_names:medicationInfo.body_part_names,
            file_name: bodyData1,
            body_image: medicationInfo.body_images,
          },
        }),
      };
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        refreshData()

      } else {
       
        const msg = "Error updating medication";
        showToastFailureMessage(msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Modal open={openEditMedicationForm} sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            left: "53%",
            transform: "translate(-50%, 15%)",
            width: { xs: "90%", sm: "60%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "15px",
            transition:'none',
            pb: 4,
          }}
        >
         <Box
          aria-label="close"
          onClick={closeEditMedicationForm}
          sx={{
            position: "absolute",
            right: 5,
            top: 6, 
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
            
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px",pt:'5px', overflowY: "scroll" ,height: "80vh",width: "100%",px:5}}>
            <Box sx={{
              pointerEvents: "none", 
              opacity: 0.5, 
              pt:2        
                    }}>
            <Dropdown
              label="NHS medicine database (dmd-browser) helps you in identifying the exact medication required "
              ref={NhsMedicine}
              name="NHS_Medicine_Database"
              onDropdownSelect={(value) =>
                handleDropDownChange(value, "NHS_Medicine_Database")
              }
              list={medicines}
              placeholder="Type to Search"
              // preFilledvalue={medicationInfo.NHS_Medicine_Database}
                 preFilledvalue={getLabelById(
                medicationInfo.NHS_Medicine_Database,
                medicines
              )}
              required={true} 
              disabled={true}      
            />
            </Box>
            <Box sx={{pl:'5px'}}>
            <RadioInput
              label="Medication Support?"
              name="medication_support"
              ref={medication_supportRef}
              options={[
                { value: "Administer", label: "Administer" },
                { value: "Assist", label: "Assist" },
                { value: "Prompt", label: "Prompt" },
              ]}
              preFilledvalue={medicationInfo.medication_support}
              onValueChange={(value) =>
                handleRadioChange(value, "medication_support")
              }
              required={true}
            />
            </Box>
            <Grid container justifyContent={"space-between"}>
              <Grid md={5.5}>
                <Dropdown
                  label="Medication Route"
                  ref={choose_sessionsRef}
                  name=" medication_Route"
                  onDropdownSelect={(value) =>
                    handleDropDownChange(value, "medication_Route")
                  }
                  list={medicationroute}
                  placeholder="medication Route"
                  // preFilledvalue={medicationInfo.medication_Route}
                  preFilledvalue={getLabelById(
                    medicationInfo.medication_Route,
                    medicationroute
                  )}
                  required={true}
                />
              </Grid>
              <Grid md={5.5}>
                <NumberInput
                  ref={quantityForDose}
                  label="Quantity for each dose"
                  placeholder="Quantity for each dose"
                  onValueChange={(value) =>
                    handleValueChange(value, "quantity_for_dose")
                  }
                  name="quantity_for_dose"
                  required={true}
                  preFilledvalue={medicationInfo.quantity_for_dose||""}
                  enableSpinner
                />
              </Grid>
            </Grid>
            <Box sx={{pointerEvents: "none", opacity: 0.5,pl:'3px'}}>
            <RadioInput
              label="Medication Type?"
              name="medication_type"
              ref={medication_typeRef}
              options={[
                { value: "Blister Pack", label: "Blister Pack" },
                { value: "Scheduled", label: "Scheduled" },
                { value: "PRN", label: "When required (PRN)" },
              ]}
              onValueChange={(value) =>
                handleRadioChange(value, "medication_type")
              }
              preFilledvalue={medicationInfo.medication_type}
              required={true}
            />
            </Box>
            {medicationInfo.medication_type === "Blister Pack" ? (
              <>
                {/* medication type Blister Pack  */}
                <Box
                  sx={{ backgroundColor: "#F0FCFA", px: "30px", py: "18px",borderRadius: "20px" }}
                >
                  <Grid item md={5.5}>
                    <NumberInput
                      ref={admQtyForDose}
                      label="How many times in a day"
                      placeholder="How many times in a day"
                      onValueChange={(value) =>
                        handleValueChange(value, "adm_qty_for_dose")
                      }
                      name="adm_qty_for_dose"
                      required={true}
                      preFilledvalue={medicationInfo.adm_qty_for_dose}
                      enableSpinner
                    />
                  </Grid>
                  <Grid item xs={12} md={6.5}>
                    <Days
                      label="Frequency Of The Medication"
                      ref={select_daysRef}
                      initialSelectedDays={medicationInfo.select_days || []}
                      onDaysChange={(updatedDays) => {
                        handleChange("select_days", updatedDays);
                      }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Sessions
                      ref={choose_sessionsRef} // Pass the ref to the Sessions component
                      preFilledvalue={medicationInfo.blister_main_session.map(
                        (session) => session.text
                      )}
                      onSessionSelect={(sessions) => {
                        handleChange1("blister_main_session", sessions || []);
                      }}
                      required={true}
                    />
                  </Grid>

                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={blister_start_dateRef}
                        label="Start Date"
                        onDateChange={handleDateChange}
                        required
                        name="blister_sDate"
                        preFilledvalue={medicationInfo.blister_sDate}
                        minDate={currentDate}
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Dropdown
                        label="Session"
                        ref={blister_start_sessionRef}
                        name="blister_start_session"
                        onDropdownSelect={(value) =>
                          handleDropDownChange(value, "blister_start_session")
                        }
                        list={BlisterSesiions}
                        placeholder="Select Session"
                        // preFilledvalue={medicationInfo.blister_start_session}
                        preFilledvalue={getLabelById(
                          medicationInfo.blister_start_session,
                          BlisterSesiions
                        )}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={blister_end_dateRef}
                        label="End Date"
                        onDateChange={handleDateChange}
                        // required
                        preFilledvalue={medicationInfo.bister_eDate}
                        name="bister_eDate"
                        minDate={
                          medicationInfo.blister_sDate
                            ? medicationInfo.blister_sDate
                            : currentDate
                        }
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Dropdown
                        label="Session"
                        ref={blister_end_sessionRef}
                        name="blister_end_session"
                        onDropdownSelect={(value) =>
                          handleDropDownChange(value, "blister_end_session")
                        }
                        list={BlisterSesiions}
                        placeholder="Select Session"
                        // preFilledvalue={medicationInfo.blister_end_session}
                        preFilledvalue={getLabelById(
                          medicationInfo.blister_end_session,
                          BlisterSesiions
                        )}
                        // required={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : medicationInfo.medication_type === "Scheduled" ? (
              <>
                {/* medication type Scheduled */}
                <Box
                  sx={{ backgroundColor: "#F0FCFA", px: "30px", py: "18px" ,borderRadius: "20px"}}
                >
                  <Grid item xs={12} md={5.5}>
                    <NumberInput
                      ref={sheduled_many_timesRef}
                      name="sheduled_many_times"
                      label="How many times in a day"
                      placeholder="How many times in a day"
                      maxLength={5}
                      onValueChange={(value) =>
                        handleValueChange(value, "sheduled_many_times")
                      }
                      preFilledvalue={medicationInfo.sheduled_many_times}
                      required
                      enableSpinner
                    />
                  </Grid>
                  <Grid item xs={12} md={6.5}>
                    <EditDays
                      label="Frequency Of The Medication"
                      ref={sheduled_select_daysRef} // Pass the ref to the Days component
                      initialSelectedDays={
                        medicationInfo.sheduled_select_days || []
                      }
                      onDaysChange={(updatedDays) => {
                        handleChange("sheduled_select_days", updatedDays);
                      }}
                      required={true}
                    />
                  </Grid>
                  <RadioInput
                    label="Select Preferences?"
                    name="Select_Preferences"
                    ref={Select_PreferencesRef}
                    options={[
                      { value: "By Session", label: "By Session" },
                      { value: "By Exact Time", label: "By Exact Time" },
                    ]}
                    preFilledvalue={medicationInfo?.Select_Preferences}
                    onValueChange={(value) =>
                      handleRadioChange(value, "Select_Preferences")
                    }
                    required={true}
                  />
                  {medicationInfo.Select_Preferences === "By Session" ? (
                    <>
                      <Grid item xs={12} md={5.5}>
                        <Sessions
                          ref={choose_sessionsRef} // Pass the ref to the Sessions component
                          preFilledvalue={medicationInfo.sheduled_session?.map(
                            (session) => session.text
                          )}
                          onSessionSelect={(sessions) => {
                            handleChange1("sheduled_session", sessions || []);
                          }}
                          required={true}
                        />
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item md={5.6}>
                          <Calendar
                            ref={sheduled_by_session_startDateRef}
                            label="Start Date"
                            name="sheduled_by_session_startDate"
                            onDateChange={handleDateChange}
                            required
                            preFilledvalue={
                              medicationInfo.sheduled_by_session_startDate
                            }
                            minDate={currentDate}
                          />
                        </Grid>
                        <Grid md={5.6}>
                          <Dropdown
                            label="Session"
                            ref={sheduled_by_session_startRef}
                            name="sheduled_by_session_start"
                            onDropdownSelect={(value) =>
                              handleDropDownChange(
                                value,
                                "sheduled_by_session_start"
                              )
                            }
                            list={BlisterSesiions}
                            placeholder="Select Session"
                            preFilledvalue={getLabelById(
                              medicationInfo.sheduled_by_session_start,
                              BlisterSesiions
                            )}
                            required={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item md={5.6}>
                          <Calendar
                            ref={sheduled_by_session_endDateRef}
                            label="End Date"
                            onDateChange={handleDateChange}
                            // required
                            preFilledvalue={
                              medicationInfo.sheduled_by_session_endDate
                            }
                            name="sheduled_by_session_endDate"
                            minDate={
                              medicationInfo.sheduled_by_session_startDate
                                ? medicationInfo.sheduled_by_session_startDate
                                : currentDate
                            }
                          />
                        </Grid>
                        <Grid md={5.6}>
                          <Dropdown
                            label="Session"
                            ref={sheduled_by_session_endRef}
                            name="sheduled_by_session_end"
                            onDropdownSelect={(value) =>
                              handleDropDownChange(
                                value,
                                "sheduled_by_session_end"
                              )
                            }
                            list={BlisterSesiions}
                            placeholder="Select Session"
                            preFilledvalue={getLabelById(
                              medicationInfo.sheduled_by_session_end,
                              BlisterSesiions
                            )}
                            // required={true}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={9}>
                        <Time
                          ref={starttimeRef}
                          label="What time should the medication be administrated"
                          required
                          initialHours={startHour}
                          initialMinutes={startMinute}
                          onHoursChange={(value) =>
                            setMedicationInfo({
                              ...medicationInfo,
                              starthtime: value,
                            })
                          }
                          onMinutesChange={(value) =>
                            setMedicationInfo({
                              ...medicationInfo,
                              startmtime: value,
                            })
                          }
                        />
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item md={5.6}>
                          <Calendar
                            ref={By_Exact_start_timeRef}
                            label="Start Date"
                            onDateChange={handleDateChange}
                            required
                            preFilledvalue={medicationInfo.By_Exact_start_time}
                            name="By_Exact_start_time"
                            minDate={currentDate}
                          />
                        </Grid>
                        <Grid md={5.6}>
                          <Calendar
                            ref={By_Exact_end_timeRef}
                            label="End Date"
                            onDateChange={handleDateChange}
                            // required
                            preFilledvalue={medicationInfo.By_Exact_end_time}
                            name="By_Exact_end_time"
                            minDate={
                              medicationInfo.By_Exact_start_time
                                ? medicationInfo.By_Exact_start_time
                                : currentDate
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <>
                {/* medication type PRN */}
                <Box
                  sx={{
                    backgroundColor: "#F0FCFA",
                    px: "30px",
                    paddingBottom: "18px",
                    borderRadius: "20px"
                  }}
                >
                  <Grid item md={8} sx={{ marginTop: "20px" }}>
                    <p
                      style={{ paddingLeft: -"-15rem", marginBottom: "-20px" }}
                    >
                      what is the time frame between doses *
                    </p>
                  </Grid>
                  <Grid container direction={"row"}>
                    <Grid item md={0.6}>
                      <NumberInput
                        label=""
                        ref={prnNumberRef}
                        placeholder="1"
                        name="prn_dose"
                        onValueChange={(value) =>
                          handleValueChange(value, "prn_dose")
                        }
                        preFilledvalue={medicationInfo.prn_dose}
                        // required
                      />
                      {errors.prn_dose && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "8px",
                            width: "80px",
                          }}
                        >
                          {errors.prn_dose}
                        </p>
                      )}
                    </Grid>
                    <Grid item md={1.2}>
                      <h6 style={{ paddingTop: "43px", fontSize: "12px" }}>
                        Dose Per
                      </h6>
                    </Grid>
                    <Grid item md={0.6}></Grid>
                    <Grid item md={0.6}>
                      <NumberInput
                        label=""
                        ref={prnTimeRef}
                        placeholder="1"
                        name="prn_dose_time"
                        onValueChange={(value) =>
                          handleValueChange(value, "prn_dose_time")
                        }
                        preFilledvalue={medicationInfo.prn_dose_time}
                        // required
                      />
                      {errors.prn_dose_time && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "8px",
                            width: "80px",
                            paddingRight: "34px",
                          }}
                        >
                          {errors.prn_dose_time}
                        </p>
                      )}
                    </Grid>
                    <Grid item md={0.25}></Grid>
                    <Grid item md={2}>
                      <Dropdown
                        ref={prnTimeDropdownRef}
                        label=""
                        name="prn_dose_timeDropdown"
                        onDropdownSelect={(value) =>
                          handleDropDownChange2(value, "prn_dose_timeDropdown")
                        }
                        list={medicationPrnTime}
                        preFilledvalue={medicationInfo.prn_dose_timeDropdown}
                        placeholder=""
                      />
                      {errors.prn_dose_timeDropdown && (
                        <h6 style={{ color: "red", fontSize: "8px" }}>
                          {errors.prn_dose_timeDropdown}
                        </h6>
                      )}
                    </Grid>
                  </Grid>
                  <Name
                    ref={prnDoseOfferRef}
                    label="Why are they each PRN offered?"
                    rows={4}
                    onValueChange={(value) =>
                      handleValueChange(value, "prn_dose_offered")
                    }
                    name="prn_dose_offered"
                    preFilledvalue={medicationInfo.prn_dose_offered}
                    required={true}
                    multiline
                    className="custom-textarea"
                  />
                  <Name
                    ref={prnDoseGivenRef}
                    label="When would this PRN be given?"
                    rows={3}
                    onValueChange={(value) =>
                      handleValueChange(value, "prn_dose_given")
                    }
                    name="prn_dose_given"
                    required={true}
                    preFilledvalue={medicationInfo.prn_dose_given}
                    multiline
                    className="custom-textarea1"
                  />
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={prnDateStartRef}
                        label="Start Date"
                        onDateChange={handleDateChange}
                        required
                        preFilledvalue={medicationInfo.Prn_sDate}
                        name="Prn_sDate"
                        minDate={currentDate}
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Calendar
                        ref={prnDateEndRef}
                        label="End Date"
                        onDateChange={handleDateChange}
                        // required
                        preFilledvalue={medicationInfo.Prn_eDate}
                        name="Prn_eDate"
                        minDate={
                          medicationInfo.Prn_sDate
                            ? medicationInfo.Prn_sDate
                            : currentDate
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          
          {medicationInfo?.body_images?.length > 0 && (
  <>
    <Box sx={{ backgroundColor: "#F0FCFA", borderRadius: "15px" }}>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          px: 4,
          py: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#279989",
            fontWeight: "bold",
          }}
        >
          Body Map Images
        </Typography>
        <CustomButtom
          text={!edit ? "Add" : "Close"}
          
          onClick={changeAddAndViewMode}
        />
      </Box>

      {/* View Body Map is always visible */}
      {/* {!edit && ( */}
      <BodyMapView
        bodyPartNames={medicationInfo?.body_part_names}
        bodyImages={medicationInfo?.body_images}
        setMedicationInfo={setMedicationInfo}
        medicationInfo={medicationInfo}
      />
      {/* )} */}

      {/* Edit Body Map is conditionally rendered */}
      {edit && (
      <div ref={editBodyMapRef}>
        <EditBodyMap
          medicationInfo={medicationInfo}
          setMedicationInfo={setMedicationInfo}
          setBodyData1={setBodyData1}
        />
        </div>
      )}
    </Box>

   
  </>
)}     
            <Box sx={{ mt: 1, alignSelf: "center" }}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Button
                    variant="customcancel"
                    color="primary"
                    onClick={handleOpen}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="customsave"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
      <ToastContainer />
    </Box>
  );
}

export default EditMedicationForm;
