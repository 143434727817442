import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Prefix,
  Name,
  Email,
  ContactNumber,
  Calendar,
  RadioInput,
  NumberInput,
  Country,
} from "../../reusableComponents";
import { Container, Grid, Button, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../Theme";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Address from "../../reusableComponents/Address";
import City from "../../reusableComponents/City";

export default function UserNextToKinEdit({
  userId,
  toggleEdit,
  toastMsg,
  setHasUnsavedChanges,
}) {
  const initialKinInfo = useMemo(
    () => ({
      prefix: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      contact_number: "",
      telephone_codes: "",
      country_code: "",
      email: "",
      dob: "",
      age: "0",
      gender: "",
      address: "",
      city: "",
      postcode: "",
      country: "United Kingdom",
      country_name: "United Kingdom",
      relation: "",
    }),
    []
  );
  const today = new Date();

  const currentDate = dayjs().subtract(16, "year");

  function calculateAge(dateString) {
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  const [kinInfo, setKinInfo] = useState(initialKinInfo);
  const [kinInfoOriginal, setKinInfoOriginal] = useState(initialKinInfo);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const onClickYesDialogBtn = () => {
    toggleEdit();
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
  };

  const showToastMessage = async (msg, response) => {
    if (response) {
      toast.success(msg || "Operation successful!", { position: "top-right" });
    } else {
      toast.error(msg || "Operation failed.", { position: "top-right" });
    }
    toggleEdit();
  };

  useEffect(() => {
    const fetchKin = async () => {
      if (!userId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/next-to-kin/${userId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setKinInfo(info);
          setKinInfoOriginal(info);
          setHasUnsavedChanges(false); // No unsaved changes on initial load
          //setAllowedMethod("PUT");
        } else {
          setKinInfo(initialKinInfo);
          setKinInfoOriginal(initialKinInfo);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchKin();
  }, [userId, initialKinInfo, setHasUnsavedChanges]);
  const prefixRef = useRef();
  const fnameRef = useRef();
  const mnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const contactRef = useRef();
  const dobRef = useRef();
  const ageRef = useRef(null);
  const genderRef = useRef(null);
  const addressRef = useRef();
  const cityRef = useRef();
  const postcodeRef = useRef();
  const countryRef = useRef();
  const relationRef = useRef();

  const handlePrefixSelect = (prefix) => {
    const finalPrefix = prefix ? prefix.text : "";
    // setSelectedPrefix(prefix);
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        prefix: finalPrefix,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleValueChange = (newValue, name) => {
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: newValue,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleNumberChange = (number) => {
    console.log("Auto Calculated Age:", number);
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        age: number,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleCountryCodeSelect = (countrycode) => {
    if (countrycode !== null) {
      // setSelectedCountry(countrycode.value);
      setKinInfo((prevFormData) => {
        const updatedForm = {
          ...prevFormData,
          telephone_codes: countrycode.id,
          country_code: countrycode.code,
        };
        setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
        return updatedForm;
      });
    }
  };
  const onChangeContactNumber = (contactVal) => {
    console.log("Updated Contact Number:", contactVal);
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        contact_number: contactVal,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleEmailChange = (newValue) => {
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        email: newValue,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleRadioChange = (value) => {
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        gender: value,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handlekDateChange = (date) => {
    // const dateStr = dayjs(date).toDate();
    // const dateObj = new Date(dateStr);
    // const formattedDate = dateObj.toISOString().slice(0, 10);

    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        dob: date,
        age: calculateAge(date),
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleCountrySelect = (country) => {
    // setSelectedCountry(country);
    console.log("first", country);
    setKinInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        country: country?.id,
        country_name: country?.country,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, kinInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleCancel = () => {
    if (!areObjectsEqual(kinInfo, kinInfoOriginal)) {
      handleOpen();
    } else {
      toggleEdit();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    if (prefixRef.current && !prefixRef.current.handleValidation()) {
      isValid = false;
    }
    if (fnameRef.current && !fnameRef.current.handleValidation()) {
      isValid = false;
    }
    if (mnameRef.current && !mnameRef.current.handleValidation()) {
      isValid = false;
    }
    if (lnameRef.current && !lnameRef.current.handleValidation()) {
      isValid = false;
    }
    if (emailRef.current && !emailRef.current.handleValidation()) {
      isValid = false;
    }
    if (contactRef.current && !contactRef.current.handleValidation()) {
      isValid = false;
    }
    if (dobRef.current && !dobRef.current.handleValidation()) {
      isValid = false;
    }
    if (ageRef.current && !ageRef.current.handleValidation()) {
      isValid = false;
    }
    if (genderRef.current && !genderRef.current.handleValidation()) {
      isValid = false;
    }
    if (addressRef.current && !addressRef.current.handleValidation()) {
      isValid = false;
    }
    if (cityRef.current && !cityRef.current.handleValidation()) {
      isValid = false;
    }
    if (postcodeRef.current && !postcodeRef.current.handleValidation()) {
      isValid = false;
    }
    if (countryRef.current && !countryRef.current.handleValidation()) {
      isValid = false;
    }
    if (relationRef.current && !relationRef.current.handleValidation()) {
      isValid = false;
    }
    if (isValid) {
      if (kinInfoOriginal === kinInfo) {
        const msg = "No new changes";
        showToastMessage(msg, false);
      } else {
        // Add your form submission logic here
        setHasUnsavedChanges(false); // Reset unsaved state
        PostNextToKin();
        //toggleEdit();
      }
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  const PostNextToKin = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/next-to-kin/${userId}`;
      const body = {
        ...kinInfo,
        age: kinInfo.age ? kinInfo.age : "0",
      };

      //payrollInfo   payroll
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        const msg = "Added Next to Kin Details";
        showToastMessage(msg, true);
        toggleEdit();
      } else {
        const msg2 = "Failed to upload user Next to Kin details.";
        showToastMessage(msg2, false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            margin: "15px 20px",
          }}
        >
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={12} md={1.5}>
              <Prefix
                preFilledvalue={kinInfo.prefix}
                ref={prefixRef}
                onPrefixSelect={handlePrefixSelect}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                ref={fnameRef}
                label="First Name"
                placeholder="First Name"
                onValueChange={handleValueChange}
                name="first_name"
                preFilledvalue={kinInfo.first_name}
                required
                maxLength={35}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                ref={mnameRef}
                label="Middle Name"
                placeholder={"Middle Name"}
                onValueChange={handleValueChange}
                preFilledvalue={kinInfo.middle_name}
                name="middle_name"
                maxLength={35}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                ref={lnameRef}
                label="Last Name"
                placeholder="Last Name"
                onValueChange={handleValueChange}
                name="last_name"
                preFilledvalue={kinInfo.last_name}
                required
                maxLength={35}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <ContactNumber
                preFilledvalue={kinInfo.contact_number}
                preFilledcode={kinInfo.country_code}
                onValueChange={onChangeContactNumber}
                onCountrySelect={handleCountryCodeSelect}
                defaultCountry={kinInfo.country_code} // Pass the default country value
                ref={contactRef}
                name="contact_number"
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={6.5} xs={12}>
              <Email
                ref={emailRef}
                preFilledvalue={kinInfo.email}
                onEmailValueChange={handleEmailChange}
                name="email"
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <Calendar
                ref={dobRef}
                label="Date of Birth"
                onDateChange={handlekDateChange}
                preFilledvalue={kinInfo.dob}
                maxDate={currentDate}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={2.5}>
              <NumberInput
                ref={ageRef}
                label="Age"
                placeholder="0"
                maxLength={5}
                onValueChange={handleNumberChange}
                preFilledvalue={kinInfo.age}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={5}>
              <RadioInput
                ref={genderRef}
                label="Gender"
                name="gender"
                required
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                  { value: "Other", label: "Other" },
                ]}
                onValueChange={handleRadioChange}
                preFilledvalue={kinInfo.gender}
              />
            </Grid>
            <Grid item xs={12}>
               <Address
                ref={addressRef}
                label="Address"
                placeholder="Address"
                onValueChange={handleValueChange}
                name="address"
                preFilledvalue={kinInfo.address}
              />
             
            </Grid>
            <Grid item xs={12} md={3.5}>
              <City
                ref={cityRef}
                label="City"
                placeholder="City"
                onValueChange={handleValueChange}
                name="city"
                preFilledvalue={kinInfo.city}
                maxLength={35}
                required
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3.5}>
              <Name
                ref={postcodeRef}
                label="Postcode"
                placeholder="Postcode"
                onValueChange={handleValueChange}
                name="postcode"
                preFilledvalue={kinInfo.postcode}
                AllCapital
                maxLength={10}
                required
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={4}>
              <Country
                ref={countryRef}
                onCountrySelect={handleCountrySelect}
                name="country"
                preFilledvalue={kinInfo.country_name}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Name
                ref={relationRef}
                label="Relation"
                placeholder="Relation"
                onValueChange={handleValueChange}
                name="relation"
                required
                preFilledvalue={kinInfo.relation}
                maxLength={35}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  variant="customcancel"
                  color="primary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="customsave"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
          <ToastContainer />
        </Box>
      </Container>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
    </ThemeProvider>
  );
}
