import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, Button, Box } from "@mui/material";
import CloseIcon from "../../../assets/icons/close.svg";
import { getCookie } from "../../../utils/utils";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import Loader from "../../../assets/images/common/logo_animation.gif";
import DownloadCarePlan from "./DownloadCarePlan"; // Assuming this is your PDF component

export default function PDFViewerDialog({
  open,
  onClose,
  clientMainData,
  name,
  clientId,
  selectedCard,
}) {
  const [finalAssessment, setFinalAssessment] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const [finalCareNetwork, setFinalCareNetwork] = useState([]);
  const [finalTodoData, setFinalTodoData] = useState([]);
  const [finalMedicationData, setFinalMedicationData] = useState([]);

  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [filteredRiskAssessments, setFilteredRiskAssessments] = useState([]);

  const fetchData = async () => {
    try {
      const token = getCookie();

      // Define URLs
      const questionsURL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment-questions-list`;
      const assessmentsURL = `https://3.8.158.142:8443/api/careplan-assessment/${clientId}`;
      const careNetworkData = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
      const todoUrl = `https://3.8.158.142:8443/api/todo-get-all?client_id=${clientId}`;
      const medicationUrl = `https://3.8.158.142:8443/api/medication?client_id=${clientId}`;

      // Create fetch options
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // Fetch both sets of data in parallel
      const [
        questionsResponse,
        assessmentsResponse,
        careNetworkResponse,
        todoUrlResponse,
        medicationResponse,
      ] = await Promise.all([
        fetch(questionsURL, options),
        fetch(assessmentsURL, options),
        fetch(careNetworkData, options),
        fetch(todoUrl, options),
        fetch(medicationUrl, options),
      ]);

      // Handle questions data
      const questionsData = await questionsResponse.json();
      const modifiedAssessmentList = questionsData.finalData.map(
        (assessment) => ({
          assessment_name: assessment.assessment_name,
          questionslist: assessment.questions.map((question) => ({
            questionid: question.id,
            questionname: question.question_name.replace("<name>", name), // Replace placeholder
          })),
        })
      );

      // Handle assessments data
      const assessmentsData = await assessmentsResponse.json();
      if (assessmentsResponse.ok) {
        const activeData = assessmentsData.data.filter(
          (item) => item.status === "Active"
        );

        // Now, fetch assessment details for each active assessment
        const assessmentDetailsPromises = activeData.map(async (assessment) => {
          const assessmentURL = `${process.env.REACT_APP_BASE_URL}/${assessment.api_assessment_name}?client_id=${assessment.client_id}`;
          const assessmentResponse = await fetch(assessmentURL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!assessmentResponse.ok) {
            throw new Error(
              `Failed to fetch details for ${assessment.assessment_name}`
            );
          }

          const assessmentData = await assessmentResponse.json();
          const activeAssessmentData = assessmentData.data.filter(
            (item) => item.status === "Active"
          );

          const finalAll = await fetchAssessmentsDetails(
            activeAssessmentData,
            assessment
          );
          return finalAll;
        });

        // Wait for all the data to be fetched and process the results
        const resolvedDetails = await Promise.all(assessmentDetailsPromises);
        const flattenedDetails = resolvedDetails.flat();

        const careNetData = await careNetworkResponse.json();

        const todoData = await todoUrlResponse.json();

        const medicationData = await medicationResponse.json();

        const finalAssessment = activeData.map((assessment) => {
          // Find the matching question list for the assessment
          const questionList =
            modifiedAssessmentList.find(
              (q) => q.assessment_name === assessment.assessment_name
            )?.questionslist || [];

          // Find the matching answers for the assessment
          const answerList = flattenedDetails.filter(
            (a) => a.assessment_name_id === assessment.assessment_id
          );

          return {
            status: assessment.status,
            assessment_id: assessment.assessment_id,
            assessment_name: assessment.assessment_name,
            sign_name: assessment.sign_name,
            sign_date: assessment.sign_date,
            questionList, // List of questions related to this assessment
            answerList, // List of answers related to this assessment
          };
        });

        setFinalMedicationData(medicationData.data);
        setFinalTodoData(todoData.data);
        setFinalCareNetwork(careNetData.data);
        setFinalAssessment(finalAssessment);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return []; // In case of error, return an empty array or handle as needed
    } finally {
      setIsLoading(false); // Data fetching is complete
    }
  };

  const fetchAssessmentsDetails = async (activeData, assess) => {
    try {
      const token = getCookie();

      // Transform active assessments into their detailed forms
      const transformedDetails = await Promise.all(
        activeData.map(async (assessment) => {
          const URL = `${process.env.REACT_APP_BASE_URL}/${assess.api_assessment_name}?client_id=${assess.client_id}`;
          const response = await fetch(URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch details for ${assess.assessment_name}`
            );
          }

          const data = await response.json();
          const activeAssessmentData = data.data.filter(
            (item) => item.status === "Active"
          );

          // Transform data based on `assessment_id`
          switch (assess.assessment_id) {
            case 21:
              return activeAssessmentData.map((item) => ({
                activities: item.activities.map((activity) => ({
                  action_to_be_taken: activity.action_to_be_taken,
                  activity: activity.activity,
                  equipment: activity.equipment,
                  range: activity.range,
                  risk_level: activity.risk_level,
                  task_support_required: activity.task_support_required,
                })),
                client_id: item.activities?.[0]?.client_id,
                consent: item.activities?.[0]?.consent,
                date_1: item.activities?.[0]?.date_1,
                date_2: item.activities?.[0]?.date_2,
                require_support: item.activities?.[0]?.require_support,
                sign_1: item.activities?.[0]?.sign_1,
                sign_2: item.activities?.[0]?.sign_2,
                assessment_name_id: item.activities?.[0]?.assessment_name_id,
              }));

            case 27:
              return activeAssessmentData.map((item) => ({
                medicationDetails: item.medicationDetails.map((medicine) => ({
                  medicine_name: medicine.medicine_name,
                  dose: medicine.dose,
                  route: medicine.route,
                  time_frequency: medicine.time_frequency,
                  self_administration: medicine.self_administration,
                })),
                client_id: item.medicationDetails?.[0]?.client_id,
                consent: item.medicationDetails?.[0]?.consent,
                date_1: item.medicationDetails?.[0]?.date_1,
                date_2: item.medicationDetails?.[0]?.date_2,
                sign_1: item.medicationDetails?.[0]?.sign_1,
                sign_2: item.medicationDetails?.[0]?.sign_2,
                assessment_name_id:
                  item.medicationDetails?.[0]?.assessment_name_id,
                self_administer_fully:
                  item.medicationDetails?.[0].self_administer_fully,
                medicines: item.medicationDetails?.[0].medicines,
                time_to_take_medicines:
                  item.medicationDetails?.[0].time_to_take_medicines,
                dosage: item.medicationDetails?.[0].dosage,
                take_medicines: item.medicationDetails?.[0].take_medicines,
                special_instructions:
                  item.medicationDetails?.[0].special_instructions,
                side_effects: item.medicationDetails?.[0].side_effects,
                missed_dose: item.medicationDetails?.[0].missed_dose,
                difficulty_reading_label:
                  item.medicationDetails?.[0].difficulty_reading_label,
                opens_medication: item.medicationDetails?.[0].opens_medication,
                safe_storage: item.medicationDetails?.[0].safe_storage,
                agrees_to_notify: item.medicationDetails?.[0].agrees_to_notify,
                responsible_for_reorder:
                  item.medicationDetails?.[0].responsible_for_reorder,
              }));

            case 24:
              return activeAssessmentData.map((item) => ({
                equipmentDetails: item.equipmentDetails.map((eqp) => ({
                  equipment: eqp.equipment,
                  equipment_description: eqp.equipment_description,
                  provided_by: eqp.provided_by,
                  purpose: eqp.purpose,
                  date_of_next_test: eqp.date_of_next_test,
                  test_completed_on: eqp.test_completed_on,
                })),
                client_id: item.equipmentDetails?.[0]?.client_id,
                consent: item.equipmentDetails?.[0]?.consent,
                date_1: item.equipmentDetails?.[0]?.date_1,
                date_2: item.equipmentDetails?.[0]?.date_2,
                sign_1: item.equipmentDetails?.[0]?.sign_1,
                sign_2: item.equipmentDetails?.[0]?.sign_2,
                assessment_name_id:
                  item.equipmentDetails?.[0]?.assessment_name_id,
              }));

            case 23:
              return activeAssessmentData.map((item) => ({
                substanceDetails: item.substanceDetails.map((subs) => ({
                  name_of_product: subs.name_of_product,
                  type_of_harm: subs.type_of_harm,
                  description_substance: subs.description_substance,
                  color: subs.color,
                  details_substance: subs.details_substance,
                  contact_substance: subs.contact_substance,
                  may_it_occur: subs.may_it_occur,
                  substance_use: subs.substance_use,
                  safe_alternative: subs.safe_alternative,
                  controls: subs.controls,
                  emergency_procedures: subs.emergency_procedures,
                  staff_aware: subs.staff_aware,
                  controls_reduced: subs.controls_reduced,
                  action_to_be_taken: subs.action_to_be_taken,
                })),
                client_id: item.substanceDetails?.[0]?.client_id,
                consent: item.substanceDetails?.[0]?.consent,
                date_1: item.substanceDetails?.[0]?.date_1,
                date_2: item.substanceDetails?.[0]?.date_2,
                sign_1: item.substanceDetails?.[0]?.sign_1,
                sign_2: item.substanceDetails?.[0]?.sign_2,
                assessment_name_id:
                  item.substanceDetails?.[0]?.assessment_name_id,
              }));

            default:
              return activeAssessmentData.map((item) => ({
                ...item,
                client_id: item.client_id,
                consent: 0,
                date_1: item.date_1,
                date_2: item.date_2,
                sign_1: item.sign_1,
                sign_2: item.sign_2,
                assessment_name_id: item.assessment_name_id,
              }));
          }
        })
      );

      return transformedDetails.flat(); // Flatten the array
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("££££££$$$$$$$$$$%%%%%%%%%%", finalMedicationData);
  }, [finalMedicationData]);

  useEffect(() => {
    if (finalAssessment && finalAssessment.length > 0) {
      const filteredAssessments = finalAssessment.filter(
        (assessment) => assessment.assessment_id < 21
      );
      const filteredRiskAssessments = finalAssessment.filter(
        (assessment) => assessment.assessment_id >= 21
      );

      setFilteredAssessments(filteredAssessments);
      setFilteredRiskAssessments(filteredRiskAssessments);
    }
  }, [finalAssessment]);

  const handleDownload = async () => {
    const blob = await pdf(
      <DownloadCarePlan
        clientMainData={clientMainData}
        name={name}
        finalAssessment={finalAssessment}
        finalMedicationData={finalMedicationData}
        finalTodoData={finalTodoData}
        finalCareNetwork={finalCareNetwork}
        filteredAssessments={filteredAssessments}
        filteredRiskAssessments={filteredRiskAssessments}
      />
    ).toBlob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `CarePlan-${name || "default"}.pdf`; // Customize the file name
    link.click();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          width: "70%",
          maxWidth: "100%",
          height: "80%",
          "@media (min-width: 1350px)": {
            width: "47%",
          },
        },
      }}
    >
      {/* Close button */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          width: "28px ! important",
          height: "28px ! important",
          backgroundImage: `url(${CloseIcon})`, // Default close.svg
          backgroundSize: "cover",
          "&:hover": {
            backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
          },
        }}
      />

      <DialogContent
        sx={{
          background: "#FFFFFF",
          paddingInline: "60px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "90%",
            display: "flex", // Enable flexbox
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            margin: "0 auto",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                width: "80%",
                height: "calc(100% - 50px)",
                backgroundColor: "white",
                border: "1px solid #dddddd",
                overflowY: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Loader} alt="Loading..." />
            </Box>
          ) : (
            <PDFViewer width="100%" height="100%" showToolbar={false}>
              <DownloadCarePlan
                clientMainData={clientMainData}
                name={name}
                finalAssessment={finalAssessment}
                finalMedicationData={finalMedicationData}
                finalTodoData={finalTodoData}
                finalCareNetwork={finalCareNetwork}
                filteredAssessments={filteredAssessments}
                filteredRiskAssessments={filteredRiskAssessments}
              />
            </PDFViewer>
          )}
        </Box>
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={handleDownload}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Download PDF"}
          </Button>
        </Box>
        {/* </Box> */}
      </DialogContent>
    </Dialog>
  );
}
