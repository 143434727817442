import React from 'react'
import Header from '../components/common/header/Header'
import { Box, Typography } from '@mui/material'
import ReportsMain from '../components/reports/ReportsMain'

const ReportPage = () => {
  return (
    <Box sx={{overflowY: "hidden",height: "100vh",width:'100%'}}>
      <Header/>
      <Box sx={{width: "100%",overflowY: "hidden",height: "100vh"}}>

      <ReportsMain/>
      </Box>


        
    </Box>
  )
}

export default ReportPage