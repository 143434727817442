import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  Avatar,
  Grid,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie, slicedText } from "../../../utils/utils";

import User from "../../../assets/images/users/User.svg";
import { ViewTheme } from "../../../themes/Theme";

import { useState, useRef, useEffect } from "react";

import ProfilePhotoEdit from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEdit.svg";
import ProfilePhotoAddPhoto from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhoto.svg";
import ProfilePhotoSave from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSave.svg";
import ProfilePhotoDelete from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDelete.svg";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";

import ProfilePhotoEditHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEditHover.svg";
import ProfilePhotoAddPhotoHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhotoHover.svg";
import ProfilePhotoSaveHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSaveHover.svg";
import ProfilePhotoDeleteHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDeleteHover.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";

export const TopSection = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "20px auto",
      }}
    >
      {props.children}
    </Box>
  );
};

export const TabButton = ({ data, isActive, onClick = () => {} }) => {
  return (
    <Button
      onClick={onClick}
      key={data.id}
      variant="customtab"
      size="small"
      sx={{
        backgroundColor: isActive ? "#279989" : "#DFF1F8",
        color: isActive ? "#fff" : "#1E303799",
        fontSize: "12px",
        // boxShadow: "none",
        // padding: "6px 10px",
        minWidth: "130px",
        // borderRadius: "8px 8px 0px 0px",
        position: "relative",
        // margin: "0px 7px",
        // "&:hover": {
        //   color: "#fff",
        // },
      }}
    >
      {data.text}
      <span
        style={{
          position: "absolute",
          left: "45%",
          bottom: "-18%",
          width: 0,
          height: 0,
          paddingTop: "px",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: isActive ? "6px solid #279989" : "6px solid transparent",
        }}
      ></span>
    </Button>
  );
};
export const TabButton1 = ({ data, isActive, onClick = () => {} }) => {
  return (
    <Button
      onClick={onClick}
      key={data.id}
      variant="customtab"
      size="small"
      sx={{
        backgroundColor: isActive ? "#279989" : "#DFF1F8",
        color: isActive ? "#fff" : "#1E303799",
        fontSize: "12px",
        minWidth: "170px",
        position: "relative",
       
      }}
    >
      {data.text}
      <span
        style={{
          position: "absolute",
          left: "45%",
          bottom: "-18%",
          width: 0,
          height: 0,
          paddingTop: "px",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: isActive ? "6px solid #279989" : "6px solid transparent",
        }}
      ></span>
    </Button>
  );
};

export const UserNameWithArrow = ({ eachUser, isActiveUser, onClick }) => {
  return (
    <Typography
      onClick={onClick}
      key={eachUser.id}
      variant="body0"
      sx={{
        fontSize: "12px",
        fontWeight: isActiveUser ? 700 : 400,
        color: isActiveUser ? "#279989" : "#1E3037",
        margin: "5px 0px",
        display: "flex",
        alignItems: "center",
        padding: "0px",
        "&:hover": {
          fontWeight: 700,
          color: "#279989",
        },
      }}
    >
      <ChevronRightIcon
        sx={{
          color: "#279989",

          width: "20px",
        }}
      />
      {/* {eachUser.name} */}
      {slicedText(`${eachUser.first_name} ${eachUser.last_name}`)}
    </Typography>
  );
};

export const SearchBox = ({ updateSetValue, searchValue }) => {
  return (
    <TextField
      value={searchValue}
      onChange={(e) => updateSetValue(e.target.value)}
      size="small"
      placeholder="Search by user name"
      type="search"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "#269988", width: "18px" }} />
          </InputAdornment>
        ),
      }}
      sx={{
        width: "100%",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
        margin: "0px",
        // margin:"auto",  // centering the search bar
        // marginRight: "auto", // for left align of search bar
      }}
    />
  );
};

export const ClientLeftPanel = () => {
  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
      }}
    ></Box>
  );
};

const ProfilePhotoButton = ({
  buttonText = "",
  onClickButton = () => {},
  imgUrlBeforeHover = "", // url before hove the img
  imgUrlAfterHover = "", // url after hover imge
  styles = {},
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      onClick={onClickButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        textTransform: "none",
        color: "#969999",
        display: "flex",
        flexDirection: "column", // Stack the icon and text vertically
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arvo",
        "&:hover": {
          color: "#279989", // apply the hover color of button
        },
        ...styles,
      }}
    >
      {/* change th margin, heights, width, as per requirements in styles of img */}
      <img
        alt="profile icon"
        src={isHover ? imgUrlAfterHover : imgUrlBeforeHover}
        style={{
          width: "20px",
          height: "20px",
          gap: 1,
        }}
      />
      {buttonText}
    </Button>
  );
};

const ProfilePhotoPopup = ({
  image,
  setImage = () => {},
  onClose = () => {},
  openDialogue = false,
  textShow,
}) => {
  const [selectedImage, setSelectedImage] = useState(image);
  const [onHoverAddPhoto, setOnHoverAddPhoto] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = null;
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: "#279989",
            fontFamily: "Arvo",
            marginRight: "auto",
          }}
        >
          Profile Photo
        </Typography>

        <ProfilePhotoButton
          buttonText=""
          onClickButton={handleClose}
          imgUrlBeforeHover={ProfilePhotoClose} // apply ProfilePhotoClose svg icon url
          imgUrlAfterHover={ProfilePhotoCloseHover} // apply ProfilePhotoCloseHover svg icon url
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: 150,
              height: 150,
              borderRadius: "50%",
              background: "#2799894D",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden", // To ensure the image is clipped to the circle
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <Typography
                variant="h2"
                sx={{
                  fontSize: "50px",
                  color: "#279989",
                }}
              >
                {textShow}
              </Typography>
            )}
          </Box>
        </Grid>
      </DialogContent>
      <Divider
        sx={{
          bgcolor: "#2799894D",
          height: "2px",
        }}
      />
      <DialogActions
        sx={{
          marginBottom: "4px",
          justifyContent: "space-between",
          padding: "0",
        }}
      >
        <Grid container justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ProfilePhotoButton
              buttonText="Edit"
              onClickButton={() => {}}
              imgUrlBeforeHover={ProfilePhotoEdit} // apply ProfilePhotoEdit svg icon url
              imgUrlAfterHover={ProfilePhotoEditHover} // apply ProfilePhotoEditHover svg icon url
            />
            <Button
              onMouseEnter={() => setOnHoverAddPhoto(true)}
              onMouseLeave={() => setOnHoverAddPhoto(false)}
              component="label" // This allows us to use the button to trigger the file input
              //   startIcon={<AddPhotoAlternateIcon />}
              sx={{
                textTransform: "none",
                color: "#969999",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Arvo",
                "&:hover": {
                  color: "#279989", // change the hover color of button
                },
              }}
            >
              <img
                alt="add  icon"
                src={
                  onHoverAddPhoto
                    ? ProfilePhotoAddPhotoHover
                    : ProfilePhotoAddPhoto
                }
                style={{
                  textTransform: "none",
                  color: "#969999",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Arvo",
                  "&:hover": {
                    color: "#279989", // change the hover color of button
                  },
                }}
                // style={{
                //   width: "20px",
                //   height: "20px",
                //   gap: 1,
                //   color:"#279989"
                // }}
              />
              Add Photo
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                hidden
              />
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ProfilePhotoButton
              buttonText="Save"
              onClickButton={() => {
                setImage(selectedImage);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoSave} // apply ProfilePhotoSave svg icon url
              imgUrlAfterHover={ProfilePhotoSaveHover} // apply ProfilePhotoSaveHover svg icon url
            />

            <ProfilePhotoButton
              buttonText="Delete"
              onClickButton={() => setSelectedImage(null)}
              imgUrlBeforeHover={ProfilePhotoDelete} // apply ProfilePhotoDelete svg icon url
              imgUrlAfterHover={ProfilePhotoDeleteHover} // apply ProfilePhotoDeleteHover svg icon url
            />

            {/* <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    textTransform: "none",
                    color: "#969999",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Arvo",
                  }}
                  onClick={() => setSelectedImage(null)} // Clear the image
                >
                  Delete
                </Button> */}
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePhotoPopup;

export const UserAvatarForm = ({ isEditMode, activeUserName }) => {
  const [image, setImage] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);

  // Function to handle file selection
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getTwoUpperCaseLetters = () => {
    if (activeUserName) {
      let strList = activeUserName.split(" ");

      if (strList.length > 1) {
        return `${strList[0][0]}${strList[1][0]}`.toUpperCase();
      }

      return strList[0][0].toUpperCase();
    }

    // return "U";
    return " ";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          height: "100px",
          width: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          background: "#269988",
        }}
      >
        {image ? (
          image && (
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "inherit",
                border: 0,
              }}
              alt="user avatar"
              src={image}
            />
          )
        ) : (
          <Typography
            sx={{
              fontSize: "44px",
              fontWeight: 700,
              color: "white",
              fontFamily: "Arvo",
            }}
          >
            {getTwoUpperCaseLetters()}
          </Typography>
        )}
      </Box>
      {isEditMode === "Edit" && (
        <Button
          variant="outlinedSecondary"
          size="small"
          sx={{
            marginTop: 2,
            position: "relative",
          }}
          onClick={() => setOpenDialogue(true)}
        >
          {/* <input
            type="file"
            id="upload-button"
            accept="image/*"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              background: "red",
              opacity: 0,
              cursor: "pointer",
            }}
            onChange={handleImageUpload}
          /> */}
          Upload Photo
        </Button>
      )}

      {openDialogue && (
        <ProfilePhotoPopup
          openDialogue={openDialogue}
          onClose={() => setOpenDialogue(false)}
          onClickAddPhoto={handleImageUpload}
          textShow={getTwoUpperCaseLetters()}
          image={image}
          setImage={setImage}
        />
      )}
    </div>
  );
};

export const UserAvatarSection = ({ isEditMode, activeUserName }) => {
  return (
    <UserAvatarForm activeUserName={activeUserName} isEditMode={isEditMode} />
  );
};

export const ClientHeader = ({
  head = "",
  mode = "",
  toggleEdit = () => {},
  isHideButton = true,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 0,
        margin: 0,
        Width: "100%",
      }}
    >
      <Box
        alignItems="Top"
        style={{
          marginLeft: "-20px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          textAlign="left"
          sx={{
            display: "flex",
            alignItems: "center",
            Width: "100%",
            padding: { xs: ViewTheme.spacing(0, 4) },
            flex: 1,
          }}
        >
          <img
            style={{
              height: "40%",
              width: "auto",
            }}
            src={User}
            alt="User"
          />
          <Typography variant="h2">{head}</Typography>
        </Box>

        {isHideButton === false && (
          <Box textAlign="right" sx={{ Width: "100%", padding: 0, margin: 0 }}>
            {mode === false && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={toggleEdit}
                sx={{ margin: 0, height: "35px" }}
              >
                Edit
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
