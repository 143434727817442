import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import dayjs from "dayjs";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import CloseIcon from "../../../assets/icons/close.svg";

import jsPDF from "jspdf";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: "calc(100vh - 480px)",
    overflowY: "auto",
    position: "relative",
  },
  tableHeaderCell: {
    textAlign: "center !important",
    padding: "4px !important",
    backgroundColor: "#279989 !important", // Ensure background color is applied
    border: "none !important",
    color: "#FFFFFF !important", // Ensure text color is applied
  },
  tableBodyRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#EEFEFA",
    },
    border: "none",
  },
  tableBodyCell: {
    textAlign: "center !important",
    padding: "4px !important",
    border: "none !important",
  },
  timeDifferenceText: {
    fontSize: "0.9em",
    color: "#555",
  },
});
function ClientInvoiceView({ selectedToView, closeModal }) {
  const classes = useStyles();
  const minDate = selectedToView[0]?.minVisitDate; // Likely a string or Date object
  const maxDate = selectedToView[0]?.maxVisitDate;
  const inVoiceDate = selectedToView[0]?.invoiceCreatedDate;
  const DueDate = selectedToView[0]?.invoiceDueDate;
  const finalMinDate = minDate ? dayjs(minDate).format("DD-MM-YYYY") : null;
  const finalMaxDate = maxDate ? dayjs(maxDate).format("DD-MM-YYYY") : null;
  const finalinVoiceDate = maxDate
    ? dayjs(inVoiceDate).format("DD-MM-YYYY")
    : null;
  const finalDueDate = maxDate ? dayjs(DueDate).format("DD-MM-YYYY") : null;

  const calculateTimeDifference = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const diff = Math.abs(endDate - startDate); // Difference in milliseconds
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Convert to hours
    const minutes = Math.floor((diff / (1000 * 60)) % 60); // Convert to minutes

    return `${hours.toString().padStart(2, "0")} hrs ${minutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const calculateTotalTime = (items) => {
    let totalMinutes = 0;

    items.forEach((item) => {
      const [startHour, startMinute] = item.plannedStartTime
        .split(":")
        .map(Number);
      const [endHour, endMinute] = item.plannedEndTime.split(":").map(Number);

      const startDate = new Date();
      const endDate = new Date();

      startDate.setHours(startHour, startMinute);
      endDate.setHours(endHour, endMinute);

      totalMinutes += Math.abs((endDate - startDate) / (1000 * 60)); // Total minutes
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours.toString().padStart(2, "0")} hrs ${remainingMinutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  return (
    <div>
      {selectedToView && (
        <Box>
          <Box
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 5,
              top: 6,
              width: "20px !important",
              height: "20px !important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
              cursor: "pointer",
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 3,
            }}
          >
            <img src={CareboxLogo} alt="logo" />
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  background: "#27998933",
                  p: 2,
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#000000",
                    fontFamily: "Lora",
                    fontWeight: "600",
                    p: 0,
                  }}
                >
                  Invoice : {selectedToView[0]?.invoiceNo}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#000000",
                    fontFamily: "Lora",
                    fontWeight: "600",
                    p: 0,
                  }}
                >
                  Invoice Date : {finalinVoiceDate}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#000000",
                    fontFamily: "Lora",
                    fontWeight: "600",
                    p: 0,
                  }}
                >
                  Invoice Due Date : {finalDueDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#279989",
                  p: 2,
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
                  TOTAL INVOICE
                </Typography>
                <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                  {` £ ${selectedToView.reduce(
                    (sum, item) => sum + item.totalPlannedTimePay,
                    0
                  )}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              pt: 4,
              borderBottom: "3px solid #279989 ",
              pb: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#F3FAF9",
                // width: "350px",
                p: 3,
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", fontFamily: "Lora" }}
              >
                Payment Collected by :
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", pt: 0.2, fontFamily: "Lora" }}
              >
                Lifeline Carers Pvt. Ltd
              </Typography>
              <Typography sx={{ fontSize: "14px", p: 0, fontFamily: "Lora" }}>
                The Watermark, 9-15, Ribbleton Lane, Preston, Lancashire, PR1
                5EZ
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", P: 0, pt: 1.8, fontFamily: "Lora" }}
              >
                accounts@lifelinecarers.co.uk
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", fontFamily: "Lora", p: 0 }}
              >
                +44 (0) 7755443321
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#F3FAF9",
                px: 3,
                pt: 3,
                pb: 6.8,
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", fontFamily: "Lora" }}
              >
                Invoice to:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: "14px", fontFamily: "Lora" }}
              >
                Lancashire Council
              </Typography>
              <Typography sx={{ fontSize: "14px", p: 0, fontFamily: "Lora" }}>
                The Watermark, 9-15, Ribbleton Lane,Preston, Lancashire, PR1 5EZ
              </Typography>
              <Typography variant="h7" sx={{ pt: 1.8, fontFamily: "Lora" }}>
                Maryk Aguirre
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="h7"
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "center",
              letterSpacing: "1px",
            }}
          >{`Invoice from  ${finalMinDate} to ${finalMaxDate}`}</Typography>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    Visit Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Session
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Planned Time
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Users
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Hourly Rate
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Total Pay
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedToView?.map((item, index) => (
                  <TableRow key={item.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableBodyCell}>
                      {item.visitDate}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.sessionType}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {`${item.plannedStartTime} - ${item.plannedEndTime}`}
                      <br />
                      <span className={classes.timeDifferenceText}>
                        {calculateTimeDifference(
                          item.plannedStartTime,
                          item.plannedEndTime
                        )}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.userCount}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.hourlyPay}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item?.totalPlannedTimePay}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              borderTop: "2px solid #279989",
              width: "49%",
              float: "right",
            }}
          >
            <Box sx={{ background: "#279989", mt: 1, p: 1 }}>
              <Typography
                variant="h7"
                sx={{
                  fontSize: "12px",
                  p: 1,
                  fontFamily: "Lora",
                  color: "#FFFFFF",
                }}
              >
                {calculateTotalTime(selectedToView)}{" "}
                <Typography
                  variant="h7"
                  sx={{ float: "right", fontFamily: "Lora", color: "#FFFFFF" }}
                >
                  Total: £
                  {selectedToView.reduce(
                    (sum, item) => sum + item.totalPlannedTimePay,
                    0
                  )}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ClientInvoiceView;
