import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Slider,
  Card,
} from "@mui/material";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../../themes/Theme";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";
import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";

export const AssessmentCardView = ({
  assessmentQuestions,
  assessmentId,
  assessmentName,
  user,
  name,
  selectedCard,
  setHasUnsavedChanges,
}) => {
  const signName = `${user.first_name + " " + user.last_name}`;

  const [initialForm, setInitialForm] = useState([{}]);
  const [fetchCheckVariable, setFetchCheckVariable] = useState(false);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch assessment details");
      }

      const data = await response.json();
      const activeData = data.data.filter((item) => item.status === "Active");

      setInitialForm(activeData.length > 0 ? activeData : [{}]); // Reset or set form
      // console.log("Fetched Form Data:", activeData[0]);

      if (selectedCard.assessment_id === 21) {
        const transformedData = activeData.map((item) => {
          // Filter and map the activityDetails
          const activities = item.activities.map((activity) => ({
            action_to_be_taken: activity.action_to_be_taken,
            activity: activity.activity,
            equipment: activity.equipment,
            range: activity.range,
            risk_level: activity.risk_level,
            task_support_required: activity.task_support_required,
          }));

          // Now, construct the transformed object
          return {
            activities: activities,
            client_id: item.activities[0].client_id, // Default to 0 if not available
            consent: item.activities[0].consent,
            date_1: item.activities[0].date_1,
            date_2: item.activities[0].date_2,
            require_support: item.activities[0].require_support,
            sign_1: item.activities[0].sign_1,
            sign_2: item.activities[0].sign_2,
            client_id: item.activities[0].client_id,
            id: item.activities[0].id,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedData);
      } else if (selectedCard.assessment_id === 27) {
        const transformedDataSelf = activeData.map((item) => {
          const medications = item.medicationDetails.map((medicine) => ({
            medicine_name: medicine.medicine_name,
            dose: medicine.dose,
            route: medicine.route,
            time_frequency: medicine.time_frequency,
            self_administration: medicine.self_administration,
          }));

          // Now, construct the transformed object
          return {
            medicationDetails: medications,
            client_id: item.medicationDetails?.[0].client_id, // Default to 0 if not available
            consent: item.medicationDetails?.[0].consent,
            date_1: item.medicationDetails?.[0].date_1,
            date_2: item.medicationDetails?.[0].date_2,
            sign_1: item.medicationDetails?.[0].sign_1,
            sign_2: item.medicationDetails?.[0].sign_2,
            self_administer_fully:
              item.medicationDetails?.[0].self_administer_fully,
            medicines: item.medicationDetails?.[0].medicines,
            time_to_take_medicines:
              item.medicationDetails?.[0].time_to_take_medicines,
            dosage: item.medicationDetails?.[0].dosage,
            take_medicines: item.medicationDetails?.[0].take_medicines,
            special_instructions:
              item.medicationDetails?.[0].special_instructions,
            side_effects: item.medicationDetails?.[0].side_effects,
            missed_dose: item.medicationDetails?.[0].missed_dose,
            difficulty_reading_label:
              item.medicationDetails?.[0].difficulty_reading_label,
            opens_medication: item.medicationDetails?.[0].opens_medication,
            safe_storage: item.medicationDetails?.[0].safe_storage,
            agrees_to_notify: item.medicationDetails?.[0].agrees_to_notify,
            responsible_for_reorder:
              item.medicationDetails?.[0].responsible_for_reorder,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataSelf);
      } else if (selectedCard.assessment_id === 24) {
        const transformedDataEquip = activeData.map((item) => {
          const equipments = item.equipmentDetails.map((eqp) => ({
            equipment: eqp.equipment,
            equipment_description: eqp.equipment_description,
            provided_by: eqp.provided_by,
            purpose: eqp.purpose,
            date_of_next_test: eqp.date_of_next_test,
            test_completed_on: eqp.test_completed_on,
          }));

          // Now, construct the transformed object
          return {
            equipmentDetails: equipments,
            client_id: item.equipmentDetails?.[0].client_id, // Default to 0 if not available
            consent: item.equipmentDetails?.[0].consent,
            date_1: item.equipmentDetails?.[0].date_1,
            date_2: item.equipmentDetails?.[0].date_2,
            sign_1: item.equipmentDetails?.[0].sign_1,
            sign_2: item.equipmentDetails?.[0].sign_2,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataEquip);
      } else if (selectedCard.assessment_id === 23) {
        const transformedDataEquip = activeData.map((item) => {
          const substance = item.substanceDetails.map((subs) => ({
            name_of_product: subs.name_of_product,
            type_of_harm: subs.type_of_harm,
            description_substance: subs.description_substance,
            color: subs.color,
            details_substance: subs.details_substance,
            contact_substance: subs.contact_substance,
            may_it_occur: subs.may_it_occur,
            substance_use: subs.substance_use,
            safe_alternative: subs.safe_alternative,
            controls: subs.controls,
            emergency_procedures: subs.emergency_procedures,
            staff_aware: subs.staff_aware,
            controls_reduced: subs.controls_reduced,
            action_to_be_taken: subs.action_to_be_taken,
          }));

          // Now, construct the transformed object
          return {
            substanceDetails: substance,
            client_id: item.substanceDetails?.[0].client_id, // Default to 0 if not available
            consent: item.substanceDetails?.[0].consent,
            date_1: item.substanceDetails?.[0].date_1,
            date_2: item.substanceDetails?.[0].date_2,
            sign_1: item.substanceDetails?.[0].sign_1,
            sign_2: item.substanceDetails?.[0].sign_2,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataEquip);
      } else {
        const transformedDataAll = activeData.map((item) => {
          // Now, construct the transformed object
          return {
            ...item,
            client_id: item.client_id, // Default to 0 if not available
            consent: 0,
            date_1: item.date_1,
            date_2: item.date_2,
            sign_1: item.sign_1,
            sign_2: item.sign_2,
          };
        });
        setInitialForm(transformedDataAll);
      }

      // console.log("Fetched Form Data:", initialForm);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      setInitialForm([{}]); // Fallback to an empty form if fetch fails
    }
    setHasUnsavedChanges(false);
    setFetchCheckVariable(true);
  };

  useEffect(() => {
    fetchAssessmetDetails();
    // fetchCheckVariable &&
    //   setInitialForm((prevState) => {
    //     const updatedForm = {
    //       ...prevState[0],
    //       sign_1:
    //         prevState[0]?.sign_1 && prevState[0]?.sign_1 === signName
    //           ? prevState[0]?.sign_1
    //           : signName,
    //       date_1: dayjs(),
    //       sign_2:
    //         prevState[0]?.sign_1 !== signName && prevState[0]?.sign_1
    //           ? signName
    //           : "n",
    //       date_2: prevState[0]?.date_1 ? dayjs() : "",
    //     };

    //     return [updatedForm];
    //   });
  }, [selectedCard]);

  useEffect(() => {
    console.log("Initialized Form with VIEW mode:", initialForm[0]); // Debugging
  }, [initialForm]);

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  // Activity Risk Assessment section begins

  const activityRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Activity Risk Assessment</Typography>
        <Typography variant="h6"></Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label={`Does ${name} require support with activities?`}
            name="require_support"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            // preFilledvalue={
            //   initialForm[0]?.require_support === 1
            //     ? "yes"
            //     : initialForm[0]?.require_support === 0
            //     ? "no"
            //     : ""
            // }
            preFilledvalue={initialForm[0]?.require_support}
            disabled={true}
          />
        </Container>
        {initialForm[0]?.require_support === "yes" && (
          <>
            {initialForm[0]?.activities.map((activity, index) => (
              <Box
                key={index}
                sx={{
                  marginBlock: "20px",
                  border: "1px solid #2799894d",
                  padding: "5px 15px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    position: "absolute",
                    margin: "-19px 0 0 10px",
                    backgroundColor: "#F0FCFA",
                    fontWeight: "bolder",
                    fontSize: "19px",
                    color: "#279989",
                    fontFamily: "Arvo",
                  }}
                >
                  Activity {index + 1}
                </Box>
                <Grid container spacing={2} direction="row" paddingTop={4}>
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Activity"
                      name="activity"
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.activity || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Task/Support Required"
                      name="task_support_required"
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]
                          ?.task_support_required || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12}>
                    <Dropdown
                      label="Risk"
                      name="risk_level"
                      list={[
                        { text: "Low" },
                        { text: "Medium" },
                        { text: "High" },
                      ]}
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.risk_level || ""
                      }
                      readonlytextfield
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12} alignContent="flex-end">
                    <Box sx={{ paddingInline: "50px" }}>
                      <Slider
                        aria-label="Risk Level"
                        name="range"
                        defaultValue={
                          initialForm[0]?.activities?.[index]?.range || ""
                        }
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={5}
                        sx={{
                          color: "#279989",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-track": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-thumb": {
                            backgroundColor: "#ccc",
                          },
                          "& .MuiSlider-valueLabel": {
                            backgroundColor: "#279989",
                          },
                        }}
                        disabled
                        marks={marks}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Equipment"
                      name="equipment"
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.equipment || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Name
                      className="multiline-textfield"
                      name="action_to_be_taken"
                      label="Action to be taken"
                      placeholder="Action to be taken"
                      multiline
                      rows="5"
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]
                          ?.action_to_be_taken ?? ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };
  // Activity Risk Assessment section Ends

  //Behaviours That May Challenge Risk Assessment Begins

  const behavioursThatMayChallengeRiskAssessment = () => {
    const frequencyArray = [
      "Occassionally",
      "Weekly",
      "Daily",
      "Constantly",
      "In Specific environments",
      "Other",
    ];
    const behaviourArray = ["Staff", "Relatives", "Individual", "Other"];
    const triggerArray = [
      "Personality clash",
      "Frustration",
      "Drugs/Alcohol",
      "Lack of food/water",
      "Attention seeking",
      "Domestic provocation",
      "Lack of medication",
      "Incontinence",
      "Sexual Awareness Allergic Reaction",
      "Other",
    ];
    const hazardArray = [
      "Running Away",
      "Threatening",
      "Anxiety",
      "Spitting",
      "Biting",
      "Kicking",
      "Throwing Objectives",
      "Self-harm",
      "Verbal Abuse",
      "Unpredictability",
      "Scratching",
      "Punching",
      "Head Butting",
      "Weapons",
      "Bullying",
      "Sexualised Behaviour",
      "Involuntary Movement",
      "Shouting/Screaming",
      "Other",
    ];

    return (
      <Box>
        <Typography variant="h4">
          Behaviours That May Challenge Risk Assessment
        </Typography>
        <p>
          Describe the Service User or client who requires additional help with
          day to day general routine activities’ behaviours as per the
          Behaviours That May Challenge assessment.(Note)
        </p>
        <Typography variant="h6">Frequency Potential</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {frequencyArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.frequency_potential?.includes(each) ||
                      false
                    }
                    disableRipple
                    // disabled={true}
                    // sx={{
                    //   disabled: true,
                    // }}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.frequency_potential === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                name="other_frequency_potential"
                label="If Other"
                preFilledvalue={initialForm[0]?.other_frequency_potential || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">
          Who may be affected by the behaviour
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {behaviourArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.affected_by_behaviour?.includes(each) ||
                      false
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    name={each}
                    disableRipple
                    // disabled={true}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.affected_by_behaviour === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                name="other_affected_by_behaviour"
                label="If Other"
                preFilledvalue={
                  initialForm[0]?.other_affected_by_behaviour || ""
                }
                disabled
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Triggers</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {triggerArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={Object.values(
                      initialForm[0]?.potential_triggers || {}
                    ).includes(each)}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    name={each}
                    // disabled={true}
                    disableRipple
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_triggers || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_triggers"
                preFilledvalue={initialForm[0]?.other_potential_triggers || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Hazards</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {hazardArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      Object.values(
                        initialForm[0]?.potential_hazards || {}
                      ).includes(each) || false
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    name={each}
                    // disabled={true}
                    disableRipple
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_hazards || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_hazards"
                preFilledvalue={initialForm[0]?.other_potential_hazards || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}

        {Object.keys(initialForm[0]?.potential_hazards || {})
          .filter((key) => initialForm[0]?.potential_hazards[key] !== "") // Filter out empty hazards
          .map((hazardArrayIndex) => (
            <Box
              key={hazardArrayIndex}
              sx={{
                marginBlock: "30px",
                border: "1px solid #2799894d",
                padding: "5px 15px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  position: "absolute",
                  margin: "-19px 0 0 10px",
                  backgroundColor: "#F0FCFA",
                  fontWeight: "bolder",
                  fontWeight: "600",
                  color: "#279989",
                  fontFamily: "Arvo",
                }}
              >
                {initialForm[0]?.potential_hazards?.[hazardArrayIndex]}
              </Box>
              <Grid container spacing={2} direction="row" paddingTop={4}>
                <Grid item md={5.75} xs={12}>
                  <Dropdown
                    label="Level of Risk"
                    name={`level_of_risk`}
                    list={[
                      { text: "Low" },
                      { text: "Medium" },
                      { text: "High" },
                    ]}
                    preFilledvalue={
                      initialForm[0]?.level_of_risk?.[hazardArrayIndex] || ""
                    }
                    readonlytextfield
                  />
                </Grid>

                <Grid item md={0.5} xs={false} />
                <Grid
                  item
                  md={5.75}
                  xs={12}
                  alignContent="flex-end"
                  paddingTop={4}
                >
                  <Box sx={{ paddingInline: "50px" }}>
                    <Slider
                      // aria-label="Risk Level"
                      defaultValue={
                        initialForm[0]?.risk_range?.[hazardArrayIndex] || 1
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      marks={marks}
                      min={1}
                      max={5}
                      sx={{
                        color: "#279989",
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "#ccc",
                        },

                        "&.Mui-disabled .MuiSlider-track": {
                          backgroundColor: "#ccc",
                        },
                        "&.Mui-disabled .MuiSlider-thumb": {
                          backgroundColor: "#ccc",
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#279989",
                        },
                      }}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item md={5.75} xs={12}>
                  <Name
                    label="Methods of support/management"
                    preFilledvalue={
                      initialForm[0]?.support_methods?.[hazardArrayIndex] || ""
                    }
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid spacing={3}>
                <Grid item md={5.75} xs={12}>
                  <Container sx={{ marginLeft: "-10px" }}>
                    <RadioInput
                      label="Are the controls adequate?"
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      preFilledvalue={
                        initialForm[0]?.controls_adequate?.[hazardArrayIndex] ||
                        ""
                      }
                      disabled
                    />
                  </Container>
                  {initialForm[0]?.controls_adequate?.[hazardArrayIndex] ===
                    "yes" && (
                    <Name
                      label="Methods of support/management"
                      preFilledvalue={
                        initialForm[0]?.regulatory_measures?.[
                          hazardArrayIndex
                        ] || ""
                      }
                      disabled
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
    );
  };
  //Behaviours That May Challenge Risk Assessment Ends

  //COSHH Risk Assessment Begins

  const coshhRiskAssessment = () => {
    const substanceArray = ["Liquid", "Solid", "Vapor", "Gas"];
    const defaultSubstance = {
      name_of_product: "",
      type_of_harm: "",
      description_substance: "",
      color: "",
      details_substance: "",
      contact_substance: "",
      may_it_occur: "",
      substance_use: "",
      safe_alternative: "",
      controls: "",
      emergency_procedures: "",
      staff_aware: "",
      controls_reduced: "",
      action_to_be_taken: "",
    };
    const initializeSubstanceDetails = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (
        !updatedForm[0]?.substanceDetails ||
        !updatedForm[0].substanceDetails.length
      ) {
        updatedForm[0].substanceDetails = [{ ...defaultSubstance }];
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeSubstanceDetails();
    return (
      <Box>
        <Typography variant="h4">COSHH Risk Assessment</Typography>
        <p>
          This assessment will only address the risk to health from the
          substances listed. Additional risk assessments may be required to
          control the risk from other hazards associated with the work being
          conducted.(Note)
        </p>
        {initialForm[0]?.substanceDetails.map((sub, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#F0FCFA",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Substance {index + 1}
            </Box>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Name of product/substance"
                  name="name_of_product"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.name_of_product || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Type of harm the substance could cause"
                  name="type_of_harm"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.type_of_harm ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Typography variant="h6">
                  Description of the substance
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {substanceArray.map((each) => (
                    <FormControlLabel
                      key={each}
                      label={each}
                      name="description_substance"
                      style={{ marginRight: "40px" }}
                      control={
                        <Checkbox
                          checked={
                            initialForm[0]?.substanceDetails?.[index]
                              ?.description_substance === each || false
                          }
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                          disabled={true}
                        />
                      }
                    />
                  ))}
                </Box>
                <Name
                  label="Color"
                  name="color"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.color || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Detail how the substance causes harm (i.e. being breathed in, being swallowed etc)"
                  name="details_substance"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.details_substance || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Who is likely to come into contact with the substance?"
                  name="contact_substance"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.contact_substance || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="How often is it used, or may it occur?"
                  name="may_it_occur"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.may_it_occur ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="What will the substance be used for or what circumstances/activity may produce the substance?"
                  name="substance_use"
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.substance_use ||
                    ""
                  }
                  disabled
                />
              </Grid>
            </Grid>

            <Container sx={{ marginLeft: "-10px" }}>
              <RadioInput
                name="safe_alternative"
                label="Can the hazardous substance, circumstances or activity be eliminated, or a safe alternative be used?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.substanceDetails?.[index]?.safe_alternative ||
                  ""
                }
                disabled
              />
            </Container>

            {initialForm[0]?.substanceDetails?.[index]?.safe_alternative ===
              "no" && (
              <Box>
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Controls (list control measures for storage, use and handling, as appropriate)"
                      name="controls"
                      preFilledvalue={
                        initialForm[0]?.substanceDetails?.[index]?.controls ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Detail emergency procedures in case of accidental spillage or contact"
                      name="emergency_procedures"
                      preFilledvalue={
                        initialForm[0]?.substanceDetails?.[index]
                          ?.emergency_procedures || ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="staff_aware"
                        label="Are all staff aware of this assessment, in particular the controls and emergency procedures?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        preFilledvalue={
                          initialForm[0]?.substanceDetails?.[index]
                            ?.staff_aware || ""
                        }
                        disabled
                      />
                    </Container>
                    {initialForm[0]?.substanceDetails?.[index]?.staff_aware ===
                      "no" && (
                      <Typography sx={{ marginTop: "10px", fontWeight: 600 }}>
                        ***NOTE: Train all staff***
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="controls_reduced"
                        label="Have controls reduced the risk of harm to an acceptable level?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        preFilledvalue={
                          initialForm[0]?.substanceDetails?.[index]
                            ?.controls_reduced || ""
                        }
                        disabled
                      />
                    </Container>
                    {initialForm[0]?.substanceDetails?.[index]
                      ?.controls_reduced === "no" && (
                      <Box sx={{ marginBottom: "-15px" }}>
                        <Name
                          name="action_to_be_taken"
                          className="multiline-textfield"
                          label="Action to be taken where required"
                          placeholder="Action to be taken"
                          multiline
                          rows="5"
                          preFilledvalue={
                            initialForm[0]?.substanceDetails?.[index]
                              ?.action_to_be_taken || ""
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  //COSHH Risk Assessment Ends

  //Medication Risk Assessment Begin

  const medicationRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Medication Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="ordering"
                label="Ordering"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.ordering || ""}
                disabled
              />
              <Name
                name="ordering_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.ordering_comments || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="collecting"
                label="Collecting"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.collecting || ""}
                disabled
              />
              <Name
                name="collecting_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.collecting_comments || ""}
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="verbal_prompt"
                label="Verbal Prompt"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.verbal_prompt || ""}
                disabled
              />
              <Name
                name="verbal_prompt_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.verbal_prompt_comments || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="assisting"
                label="Assisting (physically opening boxes/bottles)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.assisting || ""}
                disabled
              />
              <Name
                name="assisting_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.assisting_comments || ""}
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="administering"
                label="Administering* (including creams, patches or lotions)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.administering || ""}
                disabled
              />
              <Name
                name="administering_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.administering_comments || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="specialized_support"
                label="Specialized Support*"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.specialized_support || ""}
                disabled
              />
              <Name
                name="specialized_support_comments"
                label="Comments"
                preFilledvalue={
                  initialForm[0]?.specialized_support_comments || ""
                }
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="time_specific"
                label="Time Specific"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.time_specific || ""}
                disabled
              />
              <Name
                name="time_specific_comments"
                label="Comments"
                preFilledvalue={initialForm[0]?.time_specific_comments || ""}
                disabled
              />
            </Grid>
          </Grid>
        </Container>
        <p>
          *Where /AGENCY NAME/ is providing verbal, physical or specialized
          support or administering, a <b>MAR chart must be completed.</b> Where
          prescribed external medication (e.g. creams, patches or lotions)
          support is provided by /AGENCY NAME/, a{" "}
          <b>TMAR must also be completed.*(Notes)</b>
        </p>
        <Typography variant="h5">Controlled Drugs:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="controlled_drugs"
                label="Are any of the medicines a Controlled Drug?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.controlled_drugs || ""}
                disabled
              />
              {initialForm[0]?.controlled_drugs === 1 && (
                <Name
                  name="controlled_drugs_details"
                  label="If yes, give details:"
                  preFilledvalue={
                    initialForm[0]?.controlled_drugs_details || ""
                  }
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Container>

        <Typography variant="h5">Ordering and Collecting:</Typography>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              name="agency_notification"
              label="Where <Agency name> is responsible for the collecting and/or ordering how will #Agency be notified of this? "
              preFilledvalue={initialForm[0]?.agency_notification || ""}
              disabled
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              name="medication_collection_details"
              label="Where not responsible, when should <Agency name> expect your medication to be collected/ordered?"
              preFilledvalue={
                initialForm[0]?.medication_collection_details || ""
              }
              disabled
            />
          </Grid>
        </Grid>

        <Typography variant="h5">PRN Medication:</Typography>
        <Grid container spacing={3}>
          <Grid item md={5.75} xs={12}>
            <Name
              name="prn_medication"
              label="How will you notify the Care Worker when a dose of PRN medication is required?"
              preFilledvalue={initialForm[0]?.prn_medication || ""}
              disabled
            />
          </Grid>
        </Grid>

        <Typography variant="h5">Storage:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="safe_storage"
                label="Is there a safe place to store medication? "
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={initialForm[0]?.safe_storage || ""}
                disabled
              />
              {initialForm[0]?.safe_storage === "yes" && (
                <Name
                  name="storage_location"
                  label="If yes, please specify where:"
                  preFilledvalue={initialForm[0]?.storage_location || ""}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };
  //Medication Risk Assessment Ends

  //Equipment Register Begins

  const equipmentRegister = () => {
    const defaultEquipment = {
      equipment: "",
      equipment_description: "",
      provided_by: "",
      purpose: "",
      date_of_next_test: "",
      test_completed_on: "",
    };
    const initializeEquipmentDetails = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (
        !updatedForm[0]?.equipmentDetails ||
        !updatedForm[0].equipmentDetails.length
      ) {
        updatedForm[0].equipmentDetails = [{ ...defaultEquipment }];
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeEquipmentDetails();
    return (
      <Box>
        <Typography variant="h4">Equipment Register</Typography>
        <p>
          As part of delivering care, our Care Workers will often have to use
          care equipment, such as hoists or stand aids. These will usually be
          supplied and serviced by the Local Authority or other third party. It
          is an expectation that the Care Workers will routinely check that the
          equipment 'looks' suitable for use before carrying out any activity
          using that equipment. However, under legislation we are required to
          ensure any equipment being used by our employees is fit for use.
          <br />
          <br />
          <b>Note: </b>This form must be used to record all equipment that will
          be used during the provision of care, and where appropriate, the
          equipment service/test dates.
        </p>
        {initialForm[0]?.equipmentDetails.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#F0FCFA",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Equipment {index + 1}
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <Name
                name="equipment"
                placeholder="Equipment"
                label="Equipment"
                preFilledvalue={
                  initialForm[0]?.equipmentDetails[index]?.equipment || ""
                }
                disabled
              />
            </Box>
            <Name
              name="equipment_description"
              className="multiline-textfield"
              label="Equipment Description"
              placeholder="Equipment Description"
              multiline
              rows="5"
              preFilledvalue={
                initialForm[0]?.equipmentDetails[index]
                  ?.equipment_description || ""
              }
              disabled
            />
            <Name
              name="provided_by"
              placeholder="Provided by"
              label="Provided by"
              preFilledvalue={
                initialForm[0]?.equipmentDetails[index]?.provided_by || ""
              }
              disabled
            />
            <Name
              name="purspose"
              className="multiline-textfield"
              label="Purpose"
              placeholder="Purpose"
              multiline
              rows="5"
              preFilledvalue={
                initialForm[0]?.equipmentDetails[index]?.purpose || ""
              }
              disabled
            />
            <Grid container spacing={3}>
              <Grid item md={5.75} xs={12}>
                <Calendar
                  name="date_of_next_test"
                  label="Date of next test"
                  preFilledvalue={
                    initialForm[0]?.equipmentDetails[index]
                      ?.date_of_next_test || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Calendar
                  name="test_completed_on"
                  label="Test completed on"
                  preFilledvalue={
                    initialForm[0]?.equipmentDetails[index]
                      ?.test_completed_on || ""
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    );
  };
  //Equipment Register Ends

  //Self-Administration of Medication Risk Assessment Begins

  const selfAdministrationofMedicationRiskAssessment = () => {
    const radioLabels = [
      {
        qQues: `Does ${name} know what medicines they are taking and what they are for?`,
        qName: "medicines",
      },
      {
        qQues: `Does ${name} know what time to take the medicines?`,
        qName: "time_to_take_medicines",
      },
      { qQues: `Does ${name} know what dosage to take?`, qName: "dosage" },
      {
        qQues: `Does ${name} know how to take the medicines?`,
        qName: "take_medicines",
      },
      {
        qQues: `Does ${name} know about any special instructions?`,
        qName: "special_instructions",
      },
      {
        qQues: `Does ${name} know about common, possible side effects?`,
        qName: "side_effects",
      },
      {
        qQues: `Does ${name} know what to do if they miss a dose?`,
        qName: "missed_dose",
      },
      {
        qQues: `Does ${name} have any difficulty in reading the label on the medicines?`,
        qName: "difficulty_reading_label",
      },
      {
        qQues: `Does ${name} open their medication (blister packs, bottles)?`,
        qName: "opens_medication",
      },
      {
        qQues: `Does ${name} understand the principles of safe storage for medicines, including their responsibility for safe keeping?`,
        qName: "safe_storage",
      },
      {
        qQues: `Does ${name} agree to notify staff of any changes to the prescribed medication and/or over-the-counter/homely medications?`,
        qName: "agrees_to_notify",
      },
      {
        qQues: `Would ${name} be responsible for the re-ordering of prescribed medication and its collection?`,
        qName: "responsible_for_reorder",
      },
    ];
    return (
      <Box>
        <Typography variant="h4">
          Self-Administration of Medication Risk Assessment
        </Typography>
        {/* <Name label="Name of GP" placeholder="Name of GP" disabled="true" /> */}
        <Typography variant="h5">Name of GP</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="self_administer_fully"
            label={`Does ${name} want to self-administer fully or partially?`}
            options={[
              { value: "fully", label: "Fully" },
              { value: "partially", label: "Partially" },
            ]}
            preFilledvalue={initialForm[0]?.self_administer_fully || ""}
            disabled
          />
          {radioLabels.map((eachLabel, index) => (
            <RadioInput
              name={eachLabel.qName}
              label={eachLabel.qQues}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              preFilledvalue={initialForm[0]?.[eachLabel.qName] || ""}
              disabled
            />
          ))}
        </Container>
        <Typography variant="h6">
          Please note all the medications used by {name} and Medication Details:
        </Typography>
        {initialForm[0]?.medicationDetails?.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#F0FCFA",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Medication {index + 1}
            </Box>
            <Grid container spacing={3} paddingTop={3}>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="medicine_name"
                  label="Name of Medicine"
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.medicine_name ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="dose"
                  label="Dose"
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.dose || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="route"
                  label="Route"
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.route || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="time_frequency"
                  label="Time/Frequency"
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.time_frequency ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Container sx={{ marginLeft: "-10px" }}>
                  <RadioInput
                    name="self_administration"
                    label="Self-Administration"
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    preFilledvalue={
                      initialForm[0]?.medicationDetails?.[index]
                        ?.self_administration || ""
                    }
                    disabled
                  />
                </Container>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    );
  };
  //Self-Administration of Medication Risk Assessment Ends

  //Financial Risk Assessment Begins

  const financialRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Financial Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="requires_assistance"
            label={`Does ${name} require assistance with managing your finances?`}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            preFilledvalue={
              initialForm[0]?.requires_assistance
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.requires_assistance === 0
              //   ? "no"
              //   : ""
            }
            disabled
          />
        </Container>
        {initialForm[0]?.requires_assistance === "yes" && (
          <Grid container spacing={3} direction="row">
            <Grid item md={5.75} xs={12}>
              <Name
                label="Family Member"
                name="family_member_name"
                preFilledvalue={initialForm[0]?.family_member_name || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Name
                label="Local Authority"
                name="local_authority_name"
                preFilledvalue={initialForm[0]?.local_authority_name || ""}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5">Agency Name</Typography>
            </Grid>
            <Grid item md={5.75} xs={12}>
              <Name
                label="Other "
                name="other"
                preFilledvalue={initialForm[0]?.other || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" sx={{ marginBlock: "15px" }}>
          Where /AGENCY NAME/ has responsibility please complete
        </Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a spending budget/ limit in place?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="spending_limit"
            preFilledvalue={
              initialForm[0]?.spending_limit
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.spending_limit === 0
              //   ? "no"
              //   : ""
            }
            disabled
          />
        </Container>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              label="Details"
              name="spending_details"
              preFilledvalue={initialForm[0]?.spending_details || ""}
              disabled
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              label="Who has responsibility for deciding how the money is spent?"
              name="responsible_party"
              preFilledvalue={initialForm[0]?.responsible_party || ""}
              disabled
            />
          </Grid>
        </Grid>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Are there any risks associated with the Service User or client who requires additional help with day to day general routine activities,’s finances?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="activities_finances"
            preFilledvalue={
              initialForm[0]?.activities_finances
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.activities_finances === 0
              //   ? "no"
              //   : ""
            }
            disabled
          />
        </Container>
        {initialForm[0]?.activities_finances === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="activities_finances_details"
                preFilledvalue={
                  initialForm[0]?.activities_finances_details || ""
                }
                disabled
              />
            </Grid>
          </Grid>
        )}
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a safe location to store cash and receipts? "
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="safe_location"
            preFilledvalue={
              initialForm[0]?.safe_location
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.safe_location === 0
              //   ? "no"
              //   : ""
            }
            disabled
          />
        </Container>
        {initialForm[0]?.safe_location === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="provide_details"
                preFilledvalue={initialForm[0]?.provide_details || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };
  //Financial Risk Assessment Ends
  // Determine the next question ID dynamically
  const lastQuestionId = Math.max(
    ...Object.keys(initialForm[0] || {})
      .filter((key) => key.startsWith("status_")) // Find all keys that start with "status_"
      .map((key) => parseInt(key.split("_")[1], 10)) // Extract the numeric part of the keys
  );

  // Use the next question ID for "comment"
  const finalNoteName = `comment_${lastQuestionId + 1}`;
  return (
    <ThemeProvider theme={ViewTheme}>
      <Box
        sx={{
          textAlign: "Left",
          height: "100%",
          overflow: "auto",
          paddingRight: 2,
          paddingLeft: 1,
          width: "100%",
          boxShadow: "0px 0px 19px 7px #",
        }}
      >
        {assessmentId < 21 ? (
          <Box>
            {assessmentQuestions.length > 0 ? (
              assessmentQuestions
                .filter(
                  (assessment) => assessment.assessment_name === assessmentName
                )
                .map((assessment, index) => (
                  <Box key={index}>
                    <Typography variant="h4">
                      {assessment.assessment_name}
                    </Typography>
                    {assessment.questionslist.map((question, index, arr) => (
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "4px",
                            marginBottom: "-20px",
                          }}
                        >
                          {question.questionname}
                        </Typography>

                        {index === arr.length - 1 ? null : (
                          <Container sx={{ marginLeft: "-10px" }}>
                            <RadioInput
                              name={`${index + 1}`}
                              options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                              ]}
                              // onValueChange={(e) => {
                              //   handleRadioChange(e, `status_${index}`);
                              // }}
                              disabled={true}
                              preFilledvalue={
                                initialForm[0]?.[
                                  `status_${question.questionid}`
                                ]?.toLowerCase() || "" // Dynamically access the status field
                              }
                            />

                            {initialForm[0]?.[
                              `status_${question.questionid}`
                            ]?.toLowerCase() === "yes" ? (
                              <Name
                                name={`comment_${question.questionid}`}
                                className="multiline-textfield"
                                label="Please Mention"
                                placeholder="Please Mention"
                                multiline
                                rows="7"
                                preFilledvalue={
                                  initialForm[0]?.[
                                    `comment_${question.questionid}`
                                  ] || "" // Dynamically access the status field
                                }
                                disabled
                              ></Name>
                            ) : null}
                          </Container>
                        )}
                      </>
                    ))}
                    <Name
                      className="multiline-textfield"
                      placeholder="Care properties and safety concerns"
                      multiline
                      rows="7"
                      preFilledvalue={initialForm[0]?.[finalNoteName] || ""}
                      disabled={true}
                    />
                  </Box>
                ))
            ) : (
              <p>No assessment questions available</p>
            )}
          </Box>
        ) : assessmentId === 21 ? (
          <Box>{activityRiskAssessment()}</Box>
        ) : assessmentId === 22 ? (
          <Box>{behavioursThatMayChallengeRiskAssessment()}</Box>
        ) : assessmentId === 23 ? (
          <Box>{coshhRiskAssessment()}</Box>
        ) : assessmentId === 24 ? (
          <Box>{equipmentRegister()}</Box>
        ) : assessmentId === 25 ? (
          <Box>{financialRiskAssessment()}</Box>
        ) : assessmentId === 26 ? (
          <Box>{medicationRiskAssessment()}</Box>
        ) : assessmentId === 27 ? (
          <Box>{selfAdministrationofMedicationRiskAssessment()}</Box>
        ) : null}
        <FormLabel sx={{ fontSize: 18 }}>
          Signatures of All Involved Admins in the Assessment
        </FormLabel>

        <Grid //First signature
          container
          direction="row"
          alignItems="top"
          sx={{
            gap: 8,
            p: 2,
            borderRadius: "8px",
            backgroundColor: "#F0FCFA",
            mb: -2,
          }}
        >
          <Grid item xs={10} lg={4}>
            <Name
              name="sign_1"
              label="Name"
              placeholder="Name"
              // placeholder={sign1 ? sign1 : "Name"}
              // onValueChange={handleSign1}
              preFilledvalue={initialForm[0]?.sign_1} // Prefill with sign_1 or signName
              disabled={true}
            />
          </Grid>
          <Grid item xs={10} lg={4}>
            <Calendar
              label="Date"
              name="date_1"
              preFilledvalue={
                initialForm[0]?.date_1 // Prefill with date_1 or current date
              }
              disabled={true}
            />
          </Grid>
        </Grid>

        {initialForm[0]?.sign_2 !== "" &&
        initialForm[0]?.sign_1 !== initialForm[0]?.sign_2 ? (
          <Grid //Second signature
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
            }}
          >
            <Grid item xs={10} lg={4}>
              <Name
                name="sign_2"
                label="Name"
                placeholder="Name"
                preFilledvalue={initialForm[0]?.sign_2}
                disabled={true}
              />
            </Grid>
            <Grid item xs={10} lg={4}>
              <Calendar
                label="Date"
                name="date_2"
                // onDateChange={handleDateChange}
                preFilledvalue={initialForm[0]?.date_2}
                disabled={true}
              />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </ThemeProvider>
  );
};
