import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  //TextField,
} from "@mui/material";
import { WeekCalendar } from "../../reusableComponents";
//import CustomButton from "../../reusableComponents/CustomButton";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import AddVisitDialog from "./AddVisitDialog";
import CancelVisitDialog from "./CancelMultipleVisitsDialog";
import VisitCard from "./RenderVisit"; // Import VisitCard component
import { getCookie } from "../../../utils/utils";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosRounded";
import dayjs from "dayjs"; // For handling date manipulation
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"; // Localization provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Dayjs adapter for date handling
import HangingTodoMedicationDialog from "./HangingTodoMedicationDialog";

export const VisitsDashboard = ({ clientId, clientMainData }) => {
  const [name, setName] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [addVisitOpenDialog, setAddVisitOpenDialog] = useState(false);
  const [cancelVisitOpenDialog, setCancelVisitOpenDialog] = useState(false);
  const [visitData, setVisitData] = useState([]); // State to store visit data
  const [todoData, setTodoData] = useState([]); // State to store visit data
  const [medicationData, setMedicationData] = useState([]); // State to store visit data
  const [isLoading, setIsLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling
  const [height, setHeight] = useState("1"); // State for error handling
  const [refreshVisits, setRefreshVisits] = useState(null); // State for error handling
  const topRef = useRef(null); // Reference for the top container
  const bottomRef = useRef(null); // Reference for the bottom container
  const [hangingOpenDialog, setHangingOpenDialog] = useState(false);
  const [hangingtodoData, setHangingTodoData] = useState([]); // State to store visit data
  const [hangingMedicationData, setHangingMedicationData] = useState([]); // State to store visit data

  const [hangingVisits, setHangingVisits] = useState([]); // State to store visit data

  useEffect(() => {
    const topElement = topRef.current;
    const bottomElement = bottomRef.current;

    // Sync scroll position between top and bottom
    const handleScroll = () => {
      if (topElement && bottomElement) {
        topElement.scrollLeft = bottomElement.scrollLeft;
      }
    };

    // Add the scroll listener to the bottom container
    if (bottomElement) {
      bottomElement.addEventListener("scroll", handleScroll);
    }

    // Clean up the listener when the component is unmounted
    return () => {
      if (bottomElement) {
        bottomElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Handle Previous Week Button
  const handlePreviousWeek = () => {
    // Subtract 7 days from the selected date
    const newDate = dayjs(selectedDate).subtract(7, "day");
    setSelectedDate(newDate);

    // Format the new date in MM/DD/YYYY format
    const formattedDate = newDate.format("MM/DD/YYYY");

    // Fetch visits with the formatted date
    fetchVisits(formattedDate);
    fetchTodos(formattedDate);
    fetchMedications(formattedDate);
  };

  // Handle Next Week Button
  const handleNextWeek = () => {
    // Add 7 days to the selected date using dayjs
    const newDate = dayjs(selectedDate).add(7, "day");

    // Update the selected date state
    setSelectedDate(newDate);

    // Format the new date in MM/DD/YYYY format
    const formattedDate = newDate.format("MM/DD/YYYY");

    // Fetch visits with the formatted date
    fetchVisits(formattedDate);
    fetchTodos(formattedDate);
    fetchMedications(formattedDate);
  };
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const newsDate = dayjs(newDate);
    // Update selected date and format it for fetching visits
    const formattedDate = newsDate.format("MM/DD/YYYY");

    fetchVisits(formattedDate);
    fetchTodos(formattedDate);
    fetchMedications(formattedDate);
  };

  // Fetch visit data
  const getMondayOfCurrentWeek = (date) => {
    const today = new Date(date);
    console.log("Today: ", today);
    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = today.getDay();

    // Calculate the number of days to subtract to get Monday (1 = Monday, 0 = Sunday)
    const daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;

    // Set the new date to Monday

    const monday = new Date(today);
    monday.setDate(today.getDate() + daysToMonday);

    // Format the date in YYYY-MM-DD format
    //const formattedMondayDate = monday.toISOString().split("T")[0];
    const formattedMondayDate = dayjs(monday).format("YYYY-MM-DD");
    //console.log("formattedMondayDate: ", formattedMondayDate);

    return formattedMondayDate;
  };

  // Fetch visit data
  const fetchVisits = async (date) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = getCookie();
      const customDate = date ? new Date(date) : new Date();
      const mondayDate = getMondayOfCurrentWeek(customDate);
      //console.log("customDate: ", customDate);
      console.log("Monday: ", mondayDate);
      const URL = `${process.env.REACT_APP_BASE_URL}/visits-get-all?client_id=${clientId}&visit_date=${mondayDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setVisitData(info); // Store the fetched visit data in the state
      } else {
        setError("Failed to fetch visit data.");
        setVisitData([]); // Clear the visit data if there's an error
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setError("An error occurred while fetching data.");
      setVisitData([]); // Clear the visit data if there's an error
    } finally {
      setIsLoading(false); // Stop the loading state
    }
  };

  const fetchTodos = async (date) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = getCookie();
      const customDate = date ? new Date(date) : new Date();
      const mondayDate = getMondayOfCurrentWeek(customDate);
      //console.log("customDate: ", customDate);
      console.log("Monday: ", mondayDate);
      const URL = `${process.env.REACT_APP_BASE_URL}/todo-get-todo-idle-list?client_id=${clientId}&todo_date=${mondayDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setTodoData(info); // Store the fetched visit data in the state
      } else {
        setError("Failed to fetch visit data.");
        setTodoData([]); // Clear the visit data if there's an error
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setError("An error occurred while fetching data.");
      setTodoData([]); // Clear the visit data if there's an error
    } finally {
      setIsLoading(false); // Stop the loading state
    }
  };

  const fetchMedications = async (date) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = getCookie();
      const customDate = date ? new Date(date) : new Date();
      const mondayDate = getMondayOfCurrentWeek(customDate);
      //console.log("customDate: ", customDate);
      console.log("Monday: ", mondayDate);
      const URL = `${process.env.REACT_APP_BASE_URL}/medication-get-idle-list?client_id=${clientId}&date=${mondayDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setMedicationData(info); // Store the fetched visit data in the state
      } else {
        setError("Failed to fetch Medication data.");
        setMedicationData([]); // Clear the visit data if there's an error
      }
    } catch (error) {
      console.error("Error fetching Medication data:", error);
      setError("An error occurred while fetching data.");
      setMedicationData([]); // Clear the visit data if there's an error
    } finally {
      setIsLoading(false); // Stop the loading state
    }
  };

  useEffect(() => {
    fetchVisits(selectedDate); // Fetch data when clientId is available
    fetchTodos(selectedDate); // Fetch data when clientId is available
    fetchMedications(selectedDate);
  }, [clientId, refreshVisits]);

  useEffect(() => {
    if (clientMainData) {
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  // Array of day labels
  const dayLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // Array of time slots
  const timeSlots = ["Morning", "Afternoon", "Tea", "Dinner", "Night"];
  // Array of time ranges
  const times = [
    "07:00-11:00",
    "11:00-15:00",
    "15:00-18:00",
    "18:00-22:00",
    "22:00-07:00",
  ];
  const visitCardHeight = "calc((100vh - 400px)/5)";

  const handleAddVisitOpen = () => {
    setAddVisitOpenDialog(true);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
    //console.log("Name: ", clientMainData);
  };

  const handleCancelVisitOpen = () => {
    setCancelVisitOpenDialog(true);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };

  const handleHangingopen = (todo, medication, visit) => {
    setHangingTodoData(todo);
    setHangingMedicationData(medication);

    setHangingVisits(visit);
    setHangingOpenDialog(true);

    console.log("Todo's  : ", todo);
    console.log("Todo's  : ", todo);
  };

  //const handleClose = () => setAddVisitOpenDialog(false);
  const handleClose = () => {
    setAddVisitOpenDialog(false);
    setCancelVisitOpenDialog(false);
    setHangingOpenDialog(false);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };

  const abbreviateDay = (fullDayName) => {
    switch (fullDayName) {
      case "Monday":
        return "Mon";
      case "Tuesday":
        return "Tue";
      case "Wednesday":
        return "Wed";
      case "Thursday":
        return "Thu";
      case "Friday":
        return "Fri";
      case "Saturday":
        return "Sat";
      case "Sunday":
        return "Sun";
      default:
        return fullDayName;
    }
  };

  // Helper function to find if a visit exists for a specific day and session
  const getVisitForDayAndSession = (day, session) => {
    // Ensure visitData is available and check its length
    if (!visitData || visitData.length === 0) return [];

    // Store visits found for the specified day and session
    const visitsForDayAndSession = visitData.filter((visit) => {
      const visitDate = new Date(visit.start_date); // Convert start_date to Date object
      //const isInCurrentWeek = visitDate >= startDate && visitDate <= endDate; // Check if the visit date is within the current week
      return (
        abbreviateDay(visit.day_name) === day && // Match the day
        visit.session_type === session
        // && // Match the session type
        // isInCurrentWeek // Check if the visit is within the current week
      );
    });

    // Use a Set to filter out duplicates based on a unique identifier (e.g., visit.id)
    const uniqueVisits = Array.from(
      new Set(visitsForDayAndSession.map((visit) => visit.visit_details_id)) // Create a Set of unique visit IDs
    ).map((id) => {
      return visitsForDayAndSession.find(
        (visit) => visit.visit_details_id === id
      ); // Find the visit by ID
    });

    return uniqueVisits; // Return the array of unique visits
  };

  const getTodoForDayAndSession = (day, session) => {
    // Ensure todoData is available and check its length
    if (!todoData || todoData.length === 0) return [];

    // Store todos found for the specified day and session
    const todosForDayAndSession = todoData.filter((todo) => {
      const todoDate = new Date(todo.todo_date); // Convert start_date to Date object
      return (
        abbreviateDay(todo.day_name) === day && // Match the day
        todo.session_type === session
        // && // Match the session type
        // isInCurrentWeek // Check if the todo is within the current week
      );
    });

    // Use a Set to filter out duplicates based on a unique identifier (e.g., todo.id)
    const uniqueTodos = Array.from(
      new Set(todosForDayAndSession.map((todo) => todo.todo_details_id)) // Create a Set of unique todo IDs
    ).map((id) => {
      return todosForDayAndSession.find((todo) => todo.todo_details_id === id); // Find the todo by ID
    });

    return uniqueTodos; // Return the array of unique todos
  };
  const getMedicationForDayAndSession = (day, session) => {
    // Ensure medicationData is available and check its length
    if (!medicationData || medicationData.length === 0) return [];
    {
      //console.log("medicationData", medicationData);
    }
    // Store medications found for the specified day and session
    const medicationsForDayAndSession = medicationData.filter((medication) => {
      const medicationDate = new Date(medication.medication_date); // Convert start_date to Date object
      return (
        abbreviateDay(medication.day_name) === day && // Match the day
        medication.session_type === session
        // && // Match the session type
        // isInCurrentWeek // Check if the medication is within the current week
      );
    });

    // Use a Set to filter out duplicates based on a unique identifier (e.g., medication.id)
    const uniqueMedications = Array.from(
      new Set(
        medicationsForDayAndSession.map(
          (medication) => medication.medication_id
        )
      ) // Create a Set of unique medication IDs
    ).map((id) => {
      return medicationsForDayAndSession.find(
        (medication) => medication.medication_id === id
      ); // Find the medication by ID
    });

    return uniqueMedications; // Return the array of unique medications
  };

  // useEffect(() => {
  //   // Function to calculate the maximum height per row
  //   const updateRowHeights = () => {
  //     // Query all the rows
  //     const rows = document.querySelectorAll(".visit-row");

  //     rows.forEach((row) => {
  //       const gridItems = row.querySelectorAll(".visit-grid-item");
  //       let maxHeight = 0;

  //       // Calculate the maximum height for this row
  //       gridItems.forEach((item) => {
  //         const itemHeight = item.offsetHeight;
  //         if (itemHeight > maxHeight) {
  //           maxHeight = itemHeight;
  //         }
  //       });

  //       // Apply the maximum height to all grid items in this row
  //       gridItems.forEach((item) => {
  //         item.style.height = `${maxHeight}px`;
  //       });
  //     });
  //   };

  //   // Call the update function once the visits are loaded or updated
  //   if (!isLoading) {
  //     updateRowHeights();
  //   }

  //   // Re-calculate heights whenever the window resizes (for responsiveness)
  //   window.addEventListener("resize", updateRowHeights);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", updateRowHeights);
  //   };
  // }, [visitData, isLoading]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          padding: "20px 30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "15px",
          }}
        >
          {/* Client Header */}
          <div style={{ flex: 1 }}>
            <ClientHeader head={`${name}'s Visits`} isHideButton={true} />
          </div>
          {/* WeekCalendar and Week Navigation Centered */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}{" "}
          {/* Add LocalizationProvider */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Center the WeekCalendar box
              gap: 2, // Space between items
              flexGrow: 1, // Ensures it takes up available space
            }}
          >
            {/* Previous Week Button */}
            <IconButton
              onClick={handlePreviousWeek}
              sx={{ marginRight: "0px ! important" }}
            >
              <ArrowBackIcon sx={{ width: "85%", height: "85%" }} />
            </IconButton>

            {/* WeekCalendar (DatePicker from Material UI) */}
            <WeekCalendar
              //label="Select Date"
              preFilledvalue={selectedDate}
              onDateChange={handleDateChange}
              nolabel
            />

            {/* Next Week Button */}
            <IconButton onClick={handleNextWeek}>
              <ArrowForwardIcon sx={{ width: "85%", height: "85%" }} />
            </IconButton>
          </Box>
          {/* </LocalizationProvider>{" "} */}
          {/* Close LocalizationProvider */}
          {/* Add Visit Button */}
          <Button variant="outlinedSecondary" onClick={handleAddVisitOpen}>
            + Add Visit
          </Button>
          {/* Cancel Multiple Visits Button */}
          <Button
            variant="outlinedSecondary"
            disabled={visitData.length <= 0 ? true : false}
            sx={{ marginLeft: "20px", marginRight: "6px" }}
            onClick={handleCancelVisitOpen}
          >
            Cancel Multiple Visits
          </Button>
        </Box>

        <Box>
          {/* Header Row */}
          <Box
            sx={{
              marginRight: "6px",
              width: "100%",
              overflowX: "hidden", // Hide scrollbar for the header
              borderRadius: "10px 10px 0 0",
            }}
            ref={topRef} // Attach ref to the top container
          >
            <Grid
              container
              direction="row"
              alignItems="top"
              sx={{
                border: "1px solid #279989",
                height: "35px",
                minWidth: "1500px", // Fixed width to match content
                backgroundColor: "#279989",
                color: "white",
                borderRadius: "10px 10px 0 0",
                marginBottom: "0px",
                pointerEvents: "none", // Prevent user interaction with the header
              }}
            >
              <Grid
                item
                xs={1.5}
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  backgroundColor: "#279989",
                }}
              ></Grid>

              {dayLabels.map((day) => (
                <Grid
                  key={day}
                  item
                  xs={1.5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "0px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "white" }}>
                    {day}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Time Slots Rows */}
          <Box
            sx={{
              height: "calc(100vh - 355px)", // Adjust this as necessary
              width: "100%",
              overflow: "auto", // Allow both horizontal and vertical scrolling
            }}
            ref={bottomRef} // Attach ref to the bottom container
          >
            {timeSlots.map((timeSlot, index) => {
              const maxVisitCount = Math.max(
                ...dayLabels.map(
                  (dayLabel) =>
                    getVisitForDayAndSession(dayLabel, timeSlot).length
                )
              );
              const maxtodoCount = Math.max(
                ...dayLabels.map(
                  (dayLabel) =>
                    getTodoForDayAndSession(dayLabel, timeSlot).length
                )
              );
              const maxmedicationCount = Math.max(
                ...dayLabels.map(
                  (dayLabel) =>
                    getMedicationForDayAndSession(dayLabel, timeSlot).length
                )
              );

              // Set the row height based on the max visit count
              const cardHeight =
                maxVisitCount > 1 ? { visitCardHeight } : visitCardHeight;
              //console.log("cardHeight", cardHeight);
              // console.log(
              //   "Grid Height",
              //   `calc(${visitCardHeight} * ${maxVisitCount})`
              // );

              return (
                <Grid
                  container
                  direction="row"
                  alignItems="stretch" // Ensure all grid items stretch to match row height
                  justifyContent="center"
                  key={timeSlot}
                  sx={{ minWidth: "1500px", borderBottom: "1px solid #279989" }}
                >
                  {/* Time Slot Column */}
                  <Grid
                    item
                    xs={1.5}
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      borderRight: "1px solid #279989",
                      borderLeft: "1px solid #279989",
                      minHeight:
                        maxVisitCount > 1
                          ? `${
                              185 * maxVisitCount +
                              34 * (maxtodoCount + maxmedicationCount)
                            }px` // Dynamically adjusting height based on visit count
                          : "185px", // Default height for one visit
                      height:
                        maxVisitCount > 1
                          ? `calc(${visitCardHeight} * ${maxVisitCount})`
                          : visitCardHeight,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#e0f2f1",
                    }}
                  >
                    <Typography variant="h6">{timeSlot}</Typography>
                    <Box sx={{ fontSize: "12px" }}>
                      <Typography variant="h6">{times[index]}</Typography>
                    </Box>
                  </Grid>

                  {/* Daily Visits */}
                  {dayLabels.map((dayLabel) => {
                    const visit = getVisitForDayAndSession(dayLabel, timeSlot);
                    const visitCount = visit.length; // Get the number of visits for this slot
                    const todo = getTodoForDayAndSession(dayLabel, timeSlot);
                    const medication = getMedicationForDayAndSession(
                      dayLabel,
                      timeSlot
                    );

                    const todoCount = todo.length; // Get the number of visits for this slot
                    const medicationCount = medication.length; // Get the number of visits for this slot

                    return (
                      <Grid
                        item
                        xs={1.5}
                        key={`${dayLabel}-${timeSlot}`}
                        sx={{
                          borderRight: "1px solid #279989",

                          minHeight:
                            maxVisitCount > 1
                              ? `${
                                  185 * maxVisitCount +
                                  34 * (maxtodoCount + maxmedicationCount)
                                }px` // Dynamically adjusting height based on visit count
                              : "185px", // Default height for one visit
                          height:
                            maxVisitCount > 1
                              ? `calc(${visitCardHeight} * ${maxVisitCount})`
                              : visitCardHeight,
                          display: "flex",
                          flexDirection: "column",
                          //justifyContent: "center",
                          alignItems: "Top",
                        }}
                      >
                        {(todoCount > 0 || medicationCount > 0) &&
                        visitCount > 1 ? ( //(todoCount > 0 && visitCount > 1)  replace
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              //justifyContent: "center",

                              alignItems: "Top",
                              minHeight:
                                maxVisitCount > 1
                                  ? `${
                                      185 * maxVisitCount +
                                      34 * (maxtodoCount + maxmedicationCount)
                                    }px` // Dynamically adjusting height based on visit count
                                  : "185px", // Default height for one visit
                              height:
                                maxVisitCount > 1
                                  ? `calc(${visitCardHeight} * ${maxVisitCount})`
                                  : visitCardHeight,
                            }}
                          >
                            <Box
                              onClick={() =>
                                handleHangingopen(todo, medication, visit)
                              } // Pass the individual todo item
                              sx={{
                                backgroundColor: "#DBF9F4",
                                margin: "4px",
                                borderRadius: "8px",
                                "&:hover": {
                                  backgroundColor: "#279989", // Hover color

                                  //border: "1px solid #279989",
                                },
                              }}
                            >
                              {todo.map((item, index) => (
                                <Box
                                  key={index} // Ensure each Box has a unique key
                                  sx={{
                                    height: "30px",
                                    minHeight: "30px",
                                    backgroundColor: "#ffffff",
                                    border: "0.5px solid #279989",
                                    borderRadius: "8px",
                                    margin: "5px",
                                    cursor: "pointer", // Pointer cursor on hover
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="body4"
                                    sx={{
                                      margin: "5px",
                                      fontSize: "11px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {item.todo_description}
                                    {/* Replace `id` with the correct property of your todo item */}
                                  </Typography>
                                </Box>
                              ))}
                              {medication.map((item, index) => (
                                <Box
                                  key={index} // Ensure each Box has a unique key
                                  sx={{
                                    height: "30px",
                                    minHeight: "30px",
                                    backgroundColor: "#ffffff",
                                    border: "0.5px solid #279989",
                                    borderRadius: "8px",
                                    margin: "5px",
                                    cursor: "pointer", // Pointer cursor on hover
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="body4"
                                    sx={{
                                      margin: "5px",
                                      fontSize: "11px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {item.nhs_medicine_name}
                                    {/* Replace `id` with the correct property of your todo item */}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              {visit.length > 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    //border: "1px solid red",
                                    margin: "0",
                                    padding: 0,
                                    width: "100%",
                                    //height: `calc((100% * ${visit.length} / ${maxVisitCount}) )`,
                                    height: `calc((100% * ${
                                      visit.length
                                    } / ${maxVisitCount}) - ${
                                      34 *
                                      (maxmedicationCount -
                                        medicationCount +
                                        maxtodoCount -
                                        todoCount)
                                    }px)`,
                                    gap: 0,
                                  }}
                                >
                                  {visit.map((v, idx) => (
                                    <VisitCard
                                      key={idx}
                                      visit={v}
                                      setRefreshVisits={setRefreshVisits}
                                      clientData={clientMainData}
                                    />
                                  ))}
                                </Box>
                              ) : (
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#aaa" }}
                                >
                                  {/* No Visits */}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ flex: 1 }}>
                            {visit.length > 0 ? (
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  //
                                  //border: "1px solid red",
                                  margin: "0",
                                  padding: 0,
                                  width: "100%",
                                  height: `calc((100% - ${
                                    (maxVisitCount > 1 ? 34 : 0) *
                                    (maxtodoCount + maxmedicationCount)
                                  }px) * ${visit.length} / ${maxVisitCount})`,
                                  //height: `calc(((100%-30px) * ${visit.length} / ${maxVisitCount}) )`,

                                  gap: 0,
                                }}
                              >
                                {visit.map((v, idx) => (
                                  <VisitCard
                                    key={idx}
                                    visit={v}
                                    setRefreshVisits={setRefreshVisits}
                                    clientData={clientMainData}
                                  />
                                ))}
                              </Box>
                            ) : (
                              <Typography
                                variant="caption"
                                sx={{ color: "#aaa" }}
                              >
                                {/* No Visits */}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Box>
        </Box>
        {/* )} */}
      </Box>

      {/* Add Visit Dialog */}
      {addVisitOpenDialog && (
        <AddVisitDialog
          openDialog={addVisitOpenDialog}
          clientId={clientId}
          competencyId={0}
          handleCloseDialog={handleClose}
          setRefreshVisits={setRefreshVisits}
        />
      )}

      {/* Cancel Visit Dialog */}
      {cancelVisitOpenDialog && (
        <CancelVisitDialog
          openDialog={cancelVisitOpenDialog}
          clientId={clientId}
          competencyId={0}
          handleCloseDialog={handleClose}
          setRefreshVisits={setRefreshVisits}
        />
      )}
      {hangingOpenDialog && (
        <HangingTodoMedicationDialog
          openDialog={hangingOpenDialog}
          clientId={clientId}
          todos={hangingtodoData}
          medications={hangingMedicationData}
          visits={hangingVisits}
          handleCloseDialog={handleClose}
          setRefreshVisits={setRefreshVisits}
          clientData={clientMainData}
        />
      )}
    </ThemeProvider>
  );
};

export default VisitsDashboard;
