import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Card, Button, Box, Typography } from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Admin from "../../../assets/images/users/Admin.svg";
import { useNavigate } from "react-router-dom";
import { ViewTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import EYE from "../../../assets/images/users/view.svg";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import { getCookie } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import { AssessmentCardView } from "./AssessmentCardView";
import { AssessmentCardEdit } from "./AssessmentCardEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoricalAssessmentCard from "./HistoricalAssessmentCards";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import dayjs from "dayjs";

const AssessmentFormView = ({
  clientId,
  name,
  // assessmentName,
  // assessmentId,
  selectedCard,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  dialogOpen,
  setDialogOpen,
  isSidebarChanged,
  setIsSidebarChanged,
  pendingAssessment,
  setPendingAssessment,
  setSelectedCard,
  documentDialogOpen,
  setIsDraft,
  setAssessmentName,
}) => {
  // const assessmentName = selectedCard.assessment_name;
  // const assessmentId = selectedCard.assessment_id;
  // console.log("selectedcardclient:", selectedCard);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const navigate = useNavigate();

  const [isEditToggle, setIsEditToggle] = useState(false);

  const [inActiveAssessment, setInActiveAssessment] = useState([{}]);

  const [isNewAssessmentToggle, setIsNewAssessmentToggle] = useState(false);

  const [isNewAssessmentVisible, setIsNewAssessmentVisible] = useState(true);

  const [refreshVariable, setRefreshVariable] = useState(false);
  const [refreshVariableEdit, setRefreshVariableEdit] = useState(false);

  const [fetchCheckVariable, setFetchCheckVariable] = useState(false);

  const [isCancelButton, setIsCancelButton] = useState(false);

  const resetNewAssessmentVisibility = () => {
    setIsEditToggle(false); // Exit edit mode
    setIsNewAssessmentToggle(false); // Ensure new assessment mode is canceled
    setIsNewAssessmentVisible(true); // Show "New Assessment" button again
    setRefreshVariableEdit((prevState) => !prevState);
  };
  const handleBackButtonClick = () => {
    if (hasUnsavedChanges) {
      setDialogOpen(true);
    } else {
      setAssessmentName(null);
    }
  };

  const toggleEditMode = () => {
    if (!isEditToggle) {
      setIsEditToggle(true);
      setIsNewAssessmentToggle(false); // Ensure "Edit" mode doesn't trigger "New Assessment" logic
    } else {
      setIsEditToggle(false);
    }
    setRefreshVariableEdit((prevState) => !prevState);
  };

  const toggleNewVisible = () => {
    if (hasUnsavedChanges) {
      setDialogOpen(true);
      return;
    }
    // else if (!dialogOpen) {
    setIsNewAssessmentToggle((prevState) => {
      if (!prevState) {
        setIsEditToggle(true); // Enable edit mode for "New Assessment"
        setIsNewAssessmentVisible(false); // Hide "New Assessment" button
      }
      return !prevState;
    });
    // }
  };

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const signName = `${user.first_name + " " + user.last_name}`;

  const fetchAssessmentQuestions = async () => {
    //if (!clientId) return;
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment-questions-list`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        // Map the response to transform it as required
        const modifiedAssessmentList = data.finalData.map((assessment) => ({
          assessment_name: assessment.assessment_name,
          questionslist: assessment.questions.map((question) => ({
            questionid: question.id,
            questionname: question.question_name.replace("<name>", name),
          })),
        }));

        // Update the state with the modified data
        setAssessmentQuestions(modifiedAssessmentList);
      } else {
        setAssessmentQuestions([]);
      }
    } catch (e) {
      console.log("Error fetching assessment questions:", e.message);
      setAssessmentQuestions([]);
    }
  };
  useEffect(() => {
    fetchAssessmentQuestions();
  }, []);

  useEffect(() => {
    if (selectedCard.status === "" || selectedCard.status === "Open") {
      setIsEditToggle(true); // Enable edit mode for "New Assessment"
      setIsNewAssessmentVisible(false);
      setIsNewAssessmentToggle(true); // Ensure new assessment mode is canceled
      console.log(
        "setting the assessment toggle for first assessment",
        selectedCard.status
      );
    }
  }, [selectedCard]);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch assessment details");
      }

      const data = await response.json();
      const activeData = data.data.filter((item) => item.status === "Inactive");

      setInActiveAssessment(activeData.length > 0 ? activeData : [{}]); // Reset or set form
      // console.log("Fetched Form Data:", activeData[0]);

      if (selectedCard.assessment_id === 21) {
        const transformedData = activeData.map((item) => {
          // Filter and map the activityDetails
          const activities = item.activities.map((activity) => ({
            action_to_be_taken: activity.action_to_be_taken,
            activity: activity.activity,
            equipment: activity.equipment,
            range: activity.range, // Default range to 2
            risk_level: activity.risk_level,
            task_support_required: activity.task_support_required,
          }));

          // Now, construct the transformed object
          return {
            activities: activities,
            client_id: item.activities[0].client_id, // Default to 0 if not available
            consent: item.activities[0].consent,
            date_1: item.activities[0].date_1,
            date_2: item.activities[0].date_2,
            require_support: item.activities[0].require_support,
            sign_1: item.activities[0].sign_1,
            sign_2: item.activities[0].sign_2,
            client_id: item.activities[0].client_id,
            id: item.activities[0].id,
          };
        });

        // Setting the transformed data into the state
        setInActiveAssessment(transformedData);
      } else if (selectedCard.assessment_id === 27) {
        const transformedDataSelf = activeData.map((item) => {
          const medications = item.medicationDetails.map((medicine) => ({
            medicine_name: medicine.medicine_name,
            dose: medicine.dose,
            route: medicine.route,
            time_frequency: medicine.time_frequency,
            self_administration: medicine.self_administration,
          }));

          // Now, construct the transformed object
          return {
            medicationDetails: medications,
            client_id: item.medicationDetails?.[0].client_id, // Default to 0 if not available
            consent: item.medicationDetails?.[0].consent,
            date_1: item.medicationDetails?.[0].date_1,
            date_2: item.medicationDetails?.[0].date_2,
            sign_1: item.medicationDetails?.[0].sign_1,
            sign_2: item.medicationDetails?.[0].sign_2,
            self_administer_fully:
              item.medicationDetails?.[0].self_administer_fully,
            medicines: item.medicationDetails?.[0].medicines,
            time_to_take_medicines:
              item.medicationDetails?.[0].time_to_take_medicines,
            dosage: item.medicationDetails?.[0].dosage,
            take_medicines: item.medicationDetails?.[0].take_medicines,
            special_instructions:
              item.medicationDetails?.[0].special_instructions,
            side_effects: item.medicationDetails?.[0].side_effects,
            missed_dose: item.medicationDetails?.[0].missed_dose,
            difficulty_reading_label:
              item.medicationDetails?.[0].difficulty_reading_label,
            opens_medication: item.medicationDetails?.[0].opens_medication,
            safe_storage: item.medicationDetails?.[0].safe_storage,
            agrees_to_notify: item.medicationDetails?.[0].agrees_to_notify,
            responsible_for_reorder:
              item.medicationDetails?.[0].responsible_for_reorder,
          };
        });

        // Setting the transformed data into the state
        setInActiveAssessment(transformedDataSelf);
      } else if (selectedCard.assessment_id === 24) {
        const transformedDataEquip = activeData.map((item) => {
          const equipments = item.equipmentDetails.map((eqp) => ({
            equipment: eqp.equipment,
            equipment_description: eqp.equipment_description,
            provided_by: eqp.provided_by,
            purpose: eqp.purpose,
            date_of_next_test: eqp.date_of_next_test,
            test_completed_on: eqp.test_completed_on,
          }));

          // Now, construct the transformed object
          return {
            equipmentDetails: equipments,
            client_id: item.equipmentDetails?.[0].client_id, // Default to 0 if not available
            consent: item.equipmentDetails?.[0].consent,
            date_1: item.equipmentDetails?.[0].date_1,
            date_2: item.equipmentDetails?.[0].date_2,
            sign_1: item.equipmentDetails?.[0].sign_1,
            sign_2: item.equipmentDetails?.[0].sign_2,
          };
        });

        // Setting the transformed data into the state
        setInActiveAssessment(transformedDataEquip);
      } else if (selectedCard.assessment_id === 23) {
        const transformedDataEquip = activeData.map((item) => {
          const substance = item.substanceDetails.map((subs) => ({
            name_of_product: subs.name_of_product,
            type_of_harm: subs.type_of_harm,
            description_substance: subs.description_substance,
            color: subs.color,
            details_substance: subs.details_substance,
            contact_substance: subs.contact_substance,
            may_it_occur: subs.may_it_occur,
            substance_use: subs.substance_use,
            safe_alternative: subs.safe_alternative,
            controls: subs.controls,
            emergency_procedures: subs.emergency_procedures,
            staff_aware: subs.staff_aware,
            controls_reduced: subs.controls_reduced,
            action_to_be_taken: subs.action_to_be_taken,
          }));

          // Now, construct the transformed object
          return {
            substanceDetails: substance,
            client_id: item.substanceDetails?.[0].client_id, // Default to 0 if not available
            consent: item.substanceDetails?.[0].consent,
            date_1: item.substanceDetails?.[0].date_1,
            date_2: item.substanceDetails?.[0].date_2,
            sign_1: item.substanceDetails?.[0].sign_1,
            sign_2: item.substanceDetails?.[0].sign_2,
          };
        });

        // Setting the transformed data into the state
        setInActiveAssessment(transformedDataEquip);
      }
      // else {
      //   setInActiveAssessment((prevState) => [
      //     {
      //       ...prevState[0],
      //     },
      //   ]);
      // }

      // console.log("Fetched Form Data:", inActiveAssessment);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      setInActiveAssessment([]); // Fallback to an empty form if fetch fails
    }
    setFetchCheckVariable(true);
  };

  useEffect(() => {
    fetchAssessmetDetails();
    fetchCheckVariable &&
      // console.log("INACTIVE ASSESSMENT LENGTH", inActiveAssessment);
      setInActiveAssessment((prevState) => {
        const updatedForm = {
          ...prevState[0],
          sign_1:
            prevState[0]?.sign_1 && prevState[0]?.sign_1 === signName
              ? prevState[0]?.sign_1
              : signName,
          date_1: dayjs(),
          sign_2:
            prevState[0]?.sign_1 !== signName && prevState[0]?.sign_1
              ? signName
              : "n",
          date_2: prevState[0]?.date_1 ? dayjs() : "",
        };

        return [updatedForm];
      });
  }, [selectedCard, refreshVariable]);

  // useEffect(() => {
  //   console.log("Assessment Qurestions List", assessmentQuestions);
  // }, [assessmentQuestions]);

  // useEffect(() => {
  //   console.log("Is sideBARChanged", isSidebarChanged);
  // }, [isSidebarChanged]);

  // Handle ConfirmationDialog actions
  const handleDialogConfirm = () => {
    if (isCancelButton) {
      setIsCancelButton(false);
      if (resetNewAssessmentVisibility) {
        resetNewAssessmentVisibility();
      } else {
        toggleEditMode();
      }
      setHasUnsavedChanges(false);
      setDialogOpen(false); // Close dialog
    } else if (isSidebarChanged && pendingAssessment) {
      setSelectedCard(pendingAssessment);

      // setRefreshVariable((prevState) => !prevState);
      // setRefreshVariableEdit((prevState) => !prevState);
      setPendingAssessment(null);
      setIsSidebarChanged(false);
      setHasUnsavedChanges(false);
      setIsNewAssessmentToggle(true);
      setDialogOpen(false);
    } else {
      setIsNewAssessmentToggle(true);
      setIsNewAssessmentVisible(false);
      setHasUnsavedChanges(false);
      setDialogOpen(false); // Close dialog
    }
  };

  const handleDialogCancel = () => {
    isSidebarChanged && setIsSidebarChanged(false);
    setDialogOpen(false); // Close dialog without saving
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        {/* <Card> */}
        <ClientHeader head={`${name}'s Care Plan`} isHideButton={true} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align all buttons to the right
            alignItems: "center", // Vertically center the buttons
            position: "absolute",
            top: "16px",
            right: "25px",
            zIndex: 11,
          }}
        >
          {!isEditToggle && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={toggleEditMode}
              sx={{
                marginRight: "10px", // Space between buttons
                height: "35px",
              }}
            >
              Edit
            </Button>
          )}

          {isNewAssessmentVisible && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={toggleNewVisible}
              sx={{
                marginRight: "10px", // Space between buttons
                height: "35px",
              }}
            >
              New Assessment
            </Button>
          )}

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBackButtonClick}
            sx={{
              height: "35px",
            }}
          >
            Back
          </Button>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  margin: 0,
                  marginRight: "20px",
                  backgroundColor: isEditToggle ? "white" : "#F0FCFA",
                }}
              >
                {isEditToggle ? (
                  <AssessmentCardEdit
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={selectedCard.assessment_name}
                    assessmentId={selectedCard.assessment_id}
                    user={user}
                    toggleEditMode={toggleEditMode}
                    name={name}
                    selectedCard={selectedCard}
                    isNewAssessmentToggle={isNewAssessmentToggle}
                    resetNewAssessmentVisibility={resetNewAssessmentVisibility} // Pass the callback
                    setIsNewAssessmentVisible={setIsNewAssessmentVisible}
                    refreshVariableEdit={refreshVariableEdit}
                    setRefreshVariable={setRefreshVariable}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    hasUnsavedChanges={hasUnsavedChanges}
                    setDialogOpen={setDialogOpen}
                    setIsCancelButton={setIsCancelButton}
                    setIsDraft={setIsDraft}
                  ></AssessmentCardEdit>
                ) : (
                  <AssessmentCardView
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={selectedCard.assessment_name}
                    assessmentId={selectedCard.assessment_id}
                    user={user}
                    name={name}
                    selectedCard={selectedCard}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  ></AssessmentCardView>
                )}
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                  <Box sx={{ paddingBlock: "15px" }}>
                    {inActiveAssessment.length > 0 &&
                    inActiveAssessment.some(
                      (assessment) => assessment.sign_1 || assessment.client_id
                    )
                      ? inActiveAssessment.map((inactive, index) => (
                          <HistoricalAssessmentCard
                            index={index} // Use a unique key
                            inactive={inactive} // Pass assessment data
                            inActiveAssessment={inActiveAssessment} // Pass assessment data
                            assessmentQuestions={assessmentQuestions}
                            assessmentName={selectedCard.assessment_name}
                            selectedCard={selectedCard}
                            assessmentId={selectedCard.assessment_id}
                            user={user}
                            name={name}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {dialogOpen ? (
          <ConfirmationDialog
            openDialog={hasUnsavedChanges}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={handleDialogCancel}
            onConfirmFun={handleDialogConfirm}
            // handleCloseDialog={handleClose}
          />
        ) : null}
      </Container>
    </ThemeProvider>
  );
};

export default AssessmentFormView;
