import React, { useState } from "react";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";
import { Box, Button, Typography } from "@mui/material";
import UserPayRoll from "./UserPayRoll";
import BillingDashMain from "./BillingDashMain";
import ClientBillingDashBoard from "./ClientBillingDashBoard";
const ToggleViewAndGridView = ({ isGridSelected, setIsGridSelected }) => {
  const toggleOptions = [
    {
      key: "userPayroll",
      label: "User Payroll",
      isSelected: !isGridSelected,
      onClick: () => setIsGridSelected(false),
    },
    {
      key: "clientInvoice",
      label: "Client Invoice",
      isSelected: isGridSelected,
      onClick: () => setIsGridSelected(true),
    },
  ];
  return (
    <Box
      sx={{
        border: "1px solid #279989",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        overflow: "hidden", // Ensures no layout breaking
      }}
    >
      {toggleOptions.map(({ key, label, isSelected, onClick }) => (
        <Box
          key={key}
          onClick={onClick}
          style={{
            border: "0px",
            height: "36px",
            flexGrow: 1, // Ensures text takes necessary width
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: key === "clientInvoice" ? "0px 8px 8px 0px" : "0px",
            background: isSelected ? "#279989" : "none",
            color: isSelected ? "#FFFFFF" : "#969999",
            fontWeight: "bold",
            padding: "0 8px", // Adds padding for better spacing
            textAlign: "center",
            whiteSpace: "nowrap", // Prevents text wrapping
          }}
        >
          {label}
        </Box>
      ))}
    </Box>
  );
};
function BillingsMain() {
  const [isGridSelected, setIsGridSelected] = useState(false);
  return (
    <Box sx={{ px: 8 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
        }}
      >
        <Typography
          // component="h1"
          variant="h5"
          sx={{ color: "#279989" }}
        >
          Billing
        </Typography>
        <ToggleViewAndGridView
          isGridSelected={isGridSelected}
          setIsGridSelected={setIsGridSelected}
        />
      </Box>
      <Box>{isGridSelected ? <ClientBillingDashBoard ></ClientBillingDashBoard> : <UserPayRoll />}</Box>
    </Box>
  );
}

export default BillingsMain;
