import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import usericon from "../../assets/images/reports/usersicon.png";
import clienticon from "../../assets/images/reports/clientsicon.png";
import visiticon from "../../assets/images/reports/visitsicon.png";
import todoicon from "../../assets/images/reports/todosicon.png";
import medicationicon from "../../assets/images/reports/medicationIcon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import alerticon from "../../assets/images/reports/alerticon.png";
import UserReports from "./users/UserReports";
import MilesTravelled from "./users/MilesTravelled";
import TimeSpentOnTravel from "./users/TimeSpentOnTravel";
import ClientsOverview from "./clients/ClientsOverview";
import CareAssessments from "./clients/CareAssessments";
import ClientTimeSpentOnTravel from "./clients/ClientTimeSpentOnTravel";
import CareHoursLogged from "./visits/CareHoursLogged";
import VisitTimeLine from "./visits/VisitTimeLine";
import ListOfTodos from "./todos/ListOfTodos";
import SubMittedTodos from "./todos/SubMittedTodos";
import ListOfMedications from "./medication/ListofMedication";
import SubMittedMedication from "./medication/SubMittedMedication";
import AlertsRaisedandResolved from "./alerts/AlertsRaisedandResolved";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Tooltip from "@mui/material/Tooltip";
import overview1 from "../../assets/icons/overview.svg";
import Client1 from "../../assets/icons/pajamas_overview.svg";
import careAssessment1 from "../../assets/icons/careassessments.svg";
import careHours1 from "../../assets/icons/CareHoursLogged1.svg";
import timeSpentOnTravel1 from "../../assets/icons/timeline1.svg";
import timeTravel1 from "../../assets/icons/timetravel.svg";
import todo1 from "../../assets/icons/todo.svg";
import overview2 from "../../assets/icons/mdi_speedometer.svg";
import medicationsub1 from "../../assets/icons/med-list1.svg";
import medicationsub2 from "../../assets/icons/submittedmedication1.svg";
import alerts1 from "../../assets/icons/si_alert-fill.svg";
import visiticon1 from "../../assets/icons/submitted1.svg";
import alerts2 from "../../assets/icons/alerts1.svg";
import clientA1 from '../../assets/icons/client1.svg';
import clientA2 from '../../assets/icons/client2.svg';
import visit1 from  '../../assets/icons/visit1.svg';
import visit2 from  '../../assets/icons/visit2.svg';
import todo2 from  '../../assets/icons/todos2.svg';
import todos1 from  '../../assets/icons/todos1.svg';
import medication1 from  '../../assets/icons/medication1.svg';
import medication2 from  '../../assets/icons/medication2.svg';
import user1 from  '../../assets/icons/user1.svg';
import user2 from  '../../assets/icons/user2.svg';
import user3 from  '../../assets/icons/user3.svg';


function ReportsMain() {
  const [selectedPage, setSelectedPage] = useState("Users");
  const [collapsed, setCollapsed] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState("Users"); // Track expanded menu sections
  const [selectedSubPage, setSelectedSubPage] = useState("Users Overview"); // Track selected subpage

  // Menu items grouped by headings with submenus
  const menuSections = [
    {
      heading: "Reports List",
      items: [
        {
          name: "Users",
          icon: usericon,
          subPages: [
            "Users Overview",
            "Miles Travelled",
            "Time Spent on Travel",
          ],
          subCollapsedIcons: [overview1, overview2, timeTravel1],
          afterSelecting:[user1,user2,user3]
        },
        {
          name: "Clients",
          icon: clienticon,
          subPages: [
            "Clients Overview",
            "Care Assessments",
            "Time spent on travel",
          ],
          subCollapsedIcons: [Client1, careAssessment1,timeTravel1],
          afterSelecting:[clientA1,clientA2,user3]
        },
      ],
    },
   // careHours1
    {
      heading: "Care Management",
      items: [
        {
          name: "Visits",
          icon: visiticon,
          subPages: ["Care Hours Logged", "Visit Timeliness"],
          subCollapsedIcons: [careHours1,timeSpentOnTravel1],
          afterSelecting:[visit1,visit2]
        },
        //visiticon1
        {
          name: "To-Do's",
          icon: todoicon,
          subPages: ["List of Todos", "Submited Todos"],
          subCollapsedIcons: [todo1, visiticon1],
          afterSelecting:[todos1,todo2]
        },
        //todoicon
        {
          name: "Medication",
          icon: medicationicon,
          subPages: ["List of Medications", "Submitted Medications"],
          subCollapsedIcons: [medicationsub1, medicationsub2],
          afterSelecting:[medication1,medication2]
        },
      ],
    },
    {
      heading: "Others",
      items: [
        {
          name: "Alert Response",
          icon: alerticon,
          subPages: ["Alerts raised & resolved"],
          subCollapsedIcons: [alerts1],
          afterSelecting:[alerts2]
        },
      ],
    },
  ];

  // Render selected page component
  const renderContent = () => {
    if (selectedSubPage) {
      switch (selectedSubPage) {
        case "Users Overview":
          return <UserReports />;
        case "Miles Travelled":
          return <MilesTravelled />;
        case "Time Spent on Travel":
          return <TimeSpentOnTravel />;
        case "Clients Overview":
          return <ClientsOverview />;
        case "Care Assessments":
          return <CareAssessments />;
        case "Time spent on travel":
          return <ClientTimeSpentOnTravel />;
        case "Care Hours Logged":
          return <CareHoursLogged />;
        case "Visit Timeliness":
          return <VisitTimeLine />;
        case "List of Todos":
          return <ListOfTodos />;
        case "Submited Todos":
          return <SubMittedTodos />;
        case "List of Medications":
          return <ListOfMedications />;
        case "Submitted Medications":
          return <SubMittedMedication />;
        case "Alerts raised & resolved":
          return <AlertsRaisedandResolved />;
        default:
          return <Typography>Select a SubPage</Typography>;
      }
    }

    return <Typography>Select a page</Typography>;
  };
  const toggleCollapse = () => {
    setCollapsed((prev) => {
      const newCollapsedState = !prev;

      if (!newCollapsedState) {
        setExpandedMenu(selectedPage);
      }

      return newCollapsedState;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1px",
        width: "96%",
        margin: "0 auto",
        overflowY: "hidden",
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: collapsed ? "5%" : "20%",
          bgcolor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          borderTopLeftRadius: "10px", // Top-left corner
          borderBottomLeftRadius: "10px",
          overflowY: "auto",
          height: "calc(100vh - 100px)",
          padding: collapsed ? 0 : 1,
          marginTop: "20px",
          position: "relative", // For toggle button placement
        }}
      >
        {/* Toggle Collapse/Expand Button */}
        <Box
          sx={{
            position: "absolute",
            top: collapsed ? "1px" : "10px",
            left: collapsed ? "1%" : "84%",
            cursor: "pointer",
            zIndex: 10,
            transition: "left 0.3s ease",
          }}
          onClick={toggleCollapse}
        >
          {!collapsed ? (
            <ArrowCircleLeftIcon sx={{ color: "#279989", fontSize: "30px" }} />
          ) : (
            <ArrowCircleRightIcon sx={{ color: "#279989", fontSize: "28px" }} />
          )}{" "}
          {/* Arrow toggle */}
        </Box>

        <Box sx={{ mt: 4 }}>
          {collapsed ? (
            <List sx={{ px: 1.5,}}>
              {menuSections.map((section) => (
                <React.Fragment key={section.heading}>
                  {section.items.map((item) => (
                    <React.Fragment key={item.name}>
                      {collapsed &&
                        item.subCollapsedIcons.map((icon, index) => (
                          <Tooltip
                            title={item.subPages[index]} // Tooltip text based on subpage
                            placement="right" // Position of the tooltip
                            arrow // Add an arrow to the tooltip
                            key={`${item.name}-${index}`}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#279989", // Tooltip background color
                                  color: "white", // Tooltip text color
                                  fontSize: "12px",
                                  fontFamily: "Arvo",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "#279989", // Arrow color to match tooltip background
                                },
                              },
                            }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                setSelectedPage(item.name);
                                setSelectedSubPage(item.subPages[index]); // Update to the corresponding subpage
                              }}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                 my:2.6,
                                borderRadius: 2,
                                textAlign: "center",
                                 backgroundColor:
                                 selectedSubPage === item.subPages[index]
                                   ? "#279989" // Selected icon background color
                                   : "transparent",
                                   transition: "background-color 0.3s ease", // Smooth hover effect
                                   ":hover": {
                                     backgroundColor:
                                       selectedSubPage === item.subPages[index]
                                         ? "#279989" // Hover background for selected
                                         : "#F0FCFA", // Hover background for not selected
                                   },
                              }}
                            >
                              <img
                                  src={
                                    selectedPage === item.name &&
                                    selectedSubPage === item.subPages[index]
                                      ? item.afterSelecting[index]
                                      : icon
                                  }
                                alt={item.subPages[index]}
                                style={{
                                  width: "29px",
                                  height: "29px",
                                }}
                              />
                            </ListItem>
                          </Tooltip>
                        ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <>
              {menuSections.map((section) => (
                <Box key={section.heading} sx={{ mb: 3 }}>
                  {/* Section Heading */}
                  <Typography
                    sx={{
                      fontWeight: "600",
                      textAlign: "left",
                      textTransform: "uppercase",
                      color: "#279989",
                      fontSize: "14px",
                    }}
                  >
                    {section.heading}
                  </Typography>

                  {/* Menu Items */}
                  <List>
                    {section.items.map((item) => (
                      <React.Fragment key={item.name}>
                        <ListItem
                          button
                          // onClick={() =>
                          //   setExpandedMenu(expandedMenu === item.name ? null : item.name)
                          // }
                          onClick={() => {
                            setExpandedMenu(
                              expandedMenu === item.name ? null : item.name
                            );
                            if (collapsed) {
                              // In collapsed mode, update selectedPage only
                              setSelectedPage(item.name);
                            }
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "6px 14px",
                            borderRadius: 2,
                            // bgcolor: expandedMenu === item.name ? "#d1e9ff" : "transparent",
                             ":hover": { bgcolor: "#F0FCFA" },
                          }}
                        >
                          {/* Left Icon and Text */}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={item.icon}
                              alt={item.name}
                              style={{ width: "22px", height: "22px" }}
                            />
                            <ListItemText>
                              <Typography
                                sx={{ fontSize: "15px", color: "#000000" }}
                              >
                                {item.name}
                              </Typography>
                            </ListItemText>
                          </Box>

                          {/* Arrow Icon */}
                          {expandedMenu === item.name ? (
                            <ExpandMoreIcon sx={{ color: "#000000" }} />
                          ) : (
                            <NavigateNextIcon sx={{ color: "#000000" }} />
                          )}
                        </ListItem>
                        {/* Submenu */}
                        {expandedMenu === item.name && item.subPages && (
                          <List
                            sx={{
                              ml: 4,
                              mr: 5,
                              borderLeft: "2px solid #279989",
                            }}
                          >
                            {item.subPages.map((subPage) => (
                              <ListItem
                                button
                                key={subPage}
                                // onClick={() => {
                                //   setSelectedPage(item.name);
                                //   setSelectedSubPage(subPage);
                                // }}
                                onClick={() => {
                                  setSelectedPage(item.name);
                                  setSelectedSubPage(subPage);
                                  if (!collapsed) {
                                    // Sync expandedMenu when clicking a subpage in expanded mode
                                    setExpandedMenu(item.name);
                                  }
                                }}
                                sx={{
                                  ml: 2,
                                  borderRadius: 2,
                                  bgcolor:
                                    selectedSubPage === subPage
                                      ? "#279989"
                                      : "transparent",

                                  ":hover": {
                                    bgcolor:
                                      selectedSubPage === subPage
                                        ? "#279989"
                                        : "#F0FCFA",
                                  }, 
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color:
                                      selectedSubPage === subPage
                                        ? "white"
                                        : "#000000",
                                    fontWeight:
                                      selectedSubPage === subPage
                                        ? "bold"
                                        : "normal",
                                    padding: "1px",
                                  }}
                                >
                                  {subPage}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>

      {/* Main Content Area */}
      <Box
        sx={{
          width: collapsed ? "95%" : "80%",
          marginTop: "20px",
          borderTopRightRadius: "10px", // Top-left corner
          borderBottomRightRadius: "10px",
          backgroundColor: "#FFFFFF",

          //  boxShadow: '0px 0px 5px 5px rgba(39, 153, 137, 0.2)',
        }}
      >
        <Box>{renderContent()}</Box>
      </Box>
    </Box>
  );
}

export default ReportsMain;
