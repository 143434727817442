import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { TabButton } from "../clientReusableComponents/components";
import faceFront from "../../../assets/images/clients/medication/faceFront.png";
import faceBack from "../../../assets/images/clients/medication/faceBack.png";
import rightFront from "../../../assets/images/clients/medication/rightFront.png";
import leftFront from "../../../assets/images/clients/medication/leftFront.png";
import rightBack from "../../../assets/images/clients/medication/rightback.png";
import leftBack from "../../../assets/images/clients/medication/leftBack.png";
import leftHeel from "../../../assets/images/clients/medication/leftHeel.png";
import leftBackleg from "../../../assets/images/clients/medication/leftBackleg.png";
import rightBackleg from "../../../assets/images/clients/medication/rightBackleg.png";
import rightHeel from "../../../assets/images/clients/medication/rightHeel.png";
import rightFrontleg from "../../../assets/images/clients/medication/rightFrontleg.png";
import leftFrontleg from "../../../assets/images/clients/medication/leftFrontleg.png";
import pelvisFront from "../../../assets/images/clients/medication/pelvisFront.png";
import pelvisBack from "../../../assets/images/clients/medication/pelvisBack.png";
import bodyFront from "../../../assets/images/clients/medication/bodyFront.png";
import bodyBack from "../../../assets/images/clients/medication/bodyBack.png";
import pencil from "../../../assets/images/clients/medication/rotated.png";
import edit from "../../../assets/images/clients/medication/edit.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CreateIcon from "@mui/icons-material/Create";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";
import BrushIcon from "@mui/icons-material/Brush";
function convertImagesToBase64(imagePaths) {
  return Promise.all(
    imagePaths.map((imagePath) =>
      fetch(imagePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image: ${imagePath}`);
          }
          return response.blob(); // Convert response to a Blob
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result); // Resolve Base64 string
              reader.onerror = () =>
                reject(new Error("Error reading file as Base64"));
              reader.readAsDataURL(blob); // Convert Blob to Base64
            })
        )
        .catch((error) => {
          console.error(
            `Error converting image to Base64 for path: ${imagePath}`,
            error
          );
          return null; // Return null for failed conversions
        })
    )
  );
}

function BodyMap({ setBodyData, setBodyData1, setBodyData2 }) {
  const bodyMapConst = {
    Body: { "Body Front": bodyFront, "Body Back": bodyBack },
    Face: { "Face Front": faceFront, "Face Back": faceBack },
    Hands: {
      "RightHand Front": rightFront,
      "RightHand Back": rightBack,
      "LeftHand Front": leftFront,
      "LeftHand Back": leftBack,
    },
    Pelvis: { "Pelvis Front": pelvisFront, "Pelvis Back": pelvisBack },
    Feet: {
      "Right Front": rightFrontleg,
      "Right Back": rightBackleg,
      "Right Heel": rightHeel,
      "Left Front": leftFrontleg,
      "Left Back": leftBackleg,
      "Left Heel": leftHeel,
    },
  };
  const [base64Images, setBase64Images] = useState([]);
  const [currentBodypart, setCurrentBodypart] = useState(["", ""]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [canvasData, setCanvasData] = useState({});
  const [canvasData1, setCanvasData1] = useState({});
  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [hasSketch, setHasSketch] = useState(false);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [paths, setPaths] = useState([]); // Store drawn paths

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const mainButtons = Object.keys(bodyMapConst);
  const subButtons = bodyMapConst[currentBodypart[0]] || {};
  const isTwoButtons = Object.keys(subButtons).length === 2;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = 290;
      canvas.height = 290;
      ctxRef.current = canvas.getContext("2d");

      // Ensure the context is available
      if (ctxRef.current) {
        ctxRef.current.lineCap = "round";
        ctxRef.current.strokeStyle = "black";
        ctxRef.current.lineWidth = 2;

        if (canvasData[currentBodypart[1]]) {
          const img = new Image();
          img.src = canvasData[currentBodypart[1]];
          img.onload = () => {
            ctxRef.current.drawImage(img, 0, 0);
          };
        } else {
          ctxRef.current.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
    }
  }, [currentBodypart, canvasData]);

  const saveCanvasData = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    setCanvasData((prevData) => ({
      ...prevData,
      [currentBodypart[1]]: dataURL,
    }));
    setCanvasData1((prevData) => ({
      ...prevData,
      [currentBodypart[1]]: dataURL,
    }));
  };
  const pencilTipOffsetX = 0;
  const pencilTipOffsetY = 0;

  const handleMouseDown = (e) => {
    if (!isDrawing && !isErasing) return;
    setIsMouseDown(true);

    const offsetX = e.nativeEvent.offsetX + pencilTipOffsetX;
    const offsetY = e.nativeEvent.offsetY + pencilTipOffsetY;

    ctxRef.current.beginPath();
    ctxRef.current.moveTo(offsetX, offsetY);

    if (isDrawing) {
      setPaths((prevPaths) => [
        ...prevPaths,
        { x: offsetX, y: offsetY, path: [] },
      ]);
    }

    setCursorPosition({ x: offsetX, y: offsetY }); // Set initial cursor position
  };
  const handleMouseUp = () => {
    setIsMouseDown(false); // Stop drawing

    // Ensure drawing paths are updated only when actively drawing
    if (isDrawing) {
      setPaths((prevPaths) => {
        if (!prevPaths || prevPaths.length === 0) return prevPaths;

        const updatedPaths = [...prevPaths];
        const lastPath = updatedPaths[updatedPaths.length - 1];

        // Ensure lastPath exists and is valid before modifying
        if (lastPath && lastPath.path) {
          lastPath.path.push({ end: true }); // Mark the end of the path
        }

        return updatedPaths;
      });
    }

    // // Optionally save the canvas data after mouse up
    // saveCanvasData();
  };

  const isCanvasEmpty = (canvas) => {
    const context = canvas.getContext("2d");
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
    return !pixelBuffer.some((color) => color !== 0); // Returns true if all pixels are transparent
  };
  const handleMouseMove = (e) => {
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    if ((isDrawing || isErasing) && isMouseDown) {
      const adjustedX = offsetX + pencilTipOffsetX;
      const adjustedY = offsetY + pencilTipOffsetY;

      if (isDrawing) {
        const lastPath = paths[paths?.length - 1];
        lastPath.path.push({ x: adjustedX, y: adjustedY });

        ctxRef.current.lineTo(adjustedX, adjustedY);
        ctxRef.current.stroke();
        if (!hasSketch) setHasSketch(true);
      } else if (isErasing) {
        ctxRef.current.clearRect(adjustedX - 10, adjustedY - 10, 20, 20);

        // Check if the canvas is cleared after erasing
        const canvas = canvasRef.current;
        const isCleared = isCanvasEmpty(canvas);
        setHasSketch(!isCleared); // Update sketch status
      }
    }
  };
  const handleDrawMode = () => {
    setIsDrawing(true);
    setIsErasing(false);
  };

  const handleEraseMode = () => {
    setIsErasing(true);
    setIsDrawing(false);
  };
  const handleMainButtonClick = (part) => {
    if (paths?.length > 0 && selectedImages.length > 0) {
      setShowConfirmModal(true);
    } else {
      setCurrentBodypart([part, ""]);
    }
  };
  const handleImageSelect = (image, text) => {
    if (image && text) {
      if (paths?.length > 0 && selectedImages.length > 0) {
        // setNewImage({ image, text }); // Store the new image selection to apply later
        setShowConfirmModal(true); // Show confirmation modal
        return;
      }
      setCurrentBodypart([currentBodypart[0], text]); // Update current body part state
      setSelectedImages((prevImages) => {
        if (!prevImages.some((img) => img?.image === image)) {
          return [...prevImages, { image, text }]; // Add image and text if not already selected
        }
        return prevImages;
      });
    }
    setIsDrawing(false);
    setIsErasing(false);
  };
  // const handleSaveClick = () => {
  //   //
  //   if (isDrawing && paths?.length > 0 && selectedImages.length > 0) {
  //     setSelectedImages1((prevImages) => {
  //       return [...prevImages, selectedImages[selectedImages?.length - 1]];
  //     });
  //     saveCanvasData();
  //   }
  //   setPaths([]);
  //   setCanvasData({});
  //   setSelectedImages([]);
  //   setCurrentBodypart(["", ""]);
  //   setIsDrawing(false);
  //   setIsErasing(false);
  // };
  const handleSaveClick = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const newCanvas = document.createElement("canvas");
    const ctx = newCanvas.getContext("2d");

    // Set canvas size to match the original canvas
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;

    // Draw the base image first
    const baseImage = new Image();
    baseImage.src = subButtons[currentBodypart[1]] || "placeholder.png";
    const currentText = currentBodypart[1]; // Get the label for the selected sub-button

    baseImage.onload = () => {
      ctx.drawImage(baseImage, 0, 0, newCanvas.width, newCanvas.height);

      // Draw the sketch (canvas content) on top
      ctx.drawImage(canvas, 0, 0);

      // Convert the combined image to a data URL
      const finalImage = newCanvas.toDataURL();

      // Update the state with the new image and label
      setSelectedImages1((prev) => [
        ...prev,
        { image: finalImage, text: currentText || "Unnamed" },
      ]);

      // Reset all states
      setPaths([]);
      setCanvasData({});
      setSelectedImages([]);
      setCurrentBodypart(["", ""]);
      setIsDrawing(false);
      setIsErasing(false);
    };
  };

  const handleImageRemove = (image) => {
    // Remove the image from selected images
    setSelectedImages1((prevImages) =>
      prevImages.filter((img) => img.image !== image)
    );

    // Also clear the corresponding canvas data (sketch) for this image
    setCanvasData1((prevData) => {
      const updatedData = { ...prevData };
      const imageKey = selectedImages1.find((img) => img.image === image)?.text;
      if (imageKey) {
        delete updatedData[imageKey];
      }
      return updatedData;
    });

    // Clear the canvas only if the removed image matches the currently displayed one
    if (canvasRef.current && currentBodypart[1] === image) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear entire canvas
    }
  };

  useEffect(() => {
    const selctimage = selectedImages1
      .map((item) => item?.image)
      .filter(Boolean);
    // const FileName = selctimage.map((item) => item?.split(".").pop());

    setBodyData1(["png"]);
    const body_parts1 = selectedImages1
      .map((item) => item?.text)
      .filter(Boolean);
    setBodyData(body_parts1);
    const canvasimages = Object.values(canvasData1);
    console.log("canvasimages=================================>", canvasimages);
    console.log(
      "============================selectedImages1============================",
      selectedImages1
    );
    convertImagesToBase64(selctimage)
      .then((base64Array) => {
        //base64Images
        setBase64Images(base64Array);
        console.log(
          "============================base64Array============================",
          base64Array
        );
        let result = "";
        base64Array?.map((item, index) => {
          result = result + item + "," + canvasimages[index] + ",";
        });
        setBodyData2(base64Array);
      })
      .catch((error) => {
        console.error("Error converting images to Base64:", error);
      });
  }, [selectedImages1, setBodyData, setBodyData1, setBodyData2]);
  // Function to handle canceling the changes
  const handleCancelClick = () => {
    // Close the confirmation modal, and stay on the current image
    setShowConfirmModal(false);
  };
  const handlemodalSaveClick = () => {
    // Save the drawn image data here
    // Reset paths and selectedImages after saving
    setPaths([]);
    setSelectedImages([]);
    setCurrentBodypart(["", ""]);
    setShowConfirmModal(false); // Close the confirmation modal
    // Proceed to select the new image
  };

  return (
    <Box sx={{ pb: "20px", borderRadius: "15px" }}>
      <Box sx={{ pt: "20px", pb: "0px", borderBottom: "1px solid #27998933" }}>
        {/* Main buttons */}
        <Box display="flex" marginBottom={2} ml={2}>
          {mainButtons.map((part) => (
            <TabButton
              key={part}
              data={{
                id: part,
                text: part,
              }}
              isActive={currentBodypart[0] === part}
              onClick={() => handleMainButtonClick(part)}
            />
          ))}
        </Box>

        {/* Sub-buttons for the selected main body part */}
        <Box
          display="grid"
          gap={isTwoButtons ? 26 : 2}
          marginBottom={6}
          sx={{
            gridTemplateColumns: `repeat(${
              Object.keys(subButtons).length
            }, 1fr)`,
            mr: "23px",
            ml: 2,
          }}
        >
          {Object.keys(subButtons).map((subPart) => (
            <Button
              key={subPart}
              onClick={() => handleImageSelect(subButtons[subPart], subPart)}
              sx={{
                backgroundColor: selectedImages?.some(
                  (img) => img?.image === subButtons[subPart]
                )
                  ? "#279989"
                  : "#DFF1F8",
                color: selectedImages?.some(
                  (img) => img?.image === subButtons[subPart]
                )
                  ? "#fff"
                  : "#1E303799",
                textTransform: "none",
                fontSize: "12px",
                padding: "6px 10px",
                marginTop: "10px ",
                borderRadius: "8px",
                mx: "10px",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#279989",
                },
              }}
            >
              {subPart}
            </Button>
          ))}
        </Box>

        {/* Display selected image */}

        <Box
          sx={{
            position: "relative",
            width: "290px",
            height: "290px",
            left: "30%",
          }}
        >
          {currentBodypart[1] && (
            <>
              <img
                src={subButtons[currentBodypart[1]] || "placeholder.png"} // Show image only after selection
                alt={`${currentBodypart[0]} ${currentBodypart[1]}`}
                style={{ width: "100%", height: "100%" }}
              />
              <canvas
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: isDrawing
                    ? `url(${pencil}) -1 -1, auto`
                    : isErasing
                    ? `url(${edit}) 0 0, auto`
                    : "none",
                  pointerEvents: isDrawing || isErasing ? "auto" : "none",
                }}
              />
            </>
          )}
        </Box>
        {/* Save button */}
        <Box
          sx={{ display: "flex", justifyContent: "space-between", my: "18px" }}
        >
          <Box sx={{ display: "flex", gap: 4, marginTop: "15px" }}>
            <Button
              onClick={handleDrawMode}
              sx={{
                mr: "10px",
                cursor: "pointer",
                backgroundColor: "#DFF1F8",
                ml: "20px",
              }}
            >
              <CreateIcon sx={{ color: "#279989" }} />
            </Button>
            <Button
              onClick={handleEraseMode}
              sx={{ mr: "10px", backgroundColor: "#DFF1F8" }}
            >
              <BrushIcon sx={{ color: "#279989" }} />
            </Button>
          </Box>
          <Button
            variant="customsave"
            onClick={handleSaveClick}
            disabled={
              paths?.length === 0 ||
              selectedImages.length < 1 ||
              showConfirmModal ||
              !hasSketch
            }
            color="primary"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              width: "80px",
              height: "40px",
              mr: "20px",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      {/* Display selected images as buttons (labels) */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {selectedImages1.map(({ image, text }, index) => (
          <Box key={index} sx={{ position: "relative" }}>
            <Button
              sx={{
                backgroundColor: "#DFF1F8",
                color: "#279989",
                fontSize: "12px",
                padding: "6px 16px",
                my: "15px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                ml: 2,
              }}
              onClick={() => handleImageRemove(image)}
            >
              {text}
              <HighlightOffIcon sx={{ ml: 1.5, fontSize: "18px", mb: 1 }} />
            </Button>
          </Box>
        ))}
      </Box>
      {showConfirmModal && (
        <ConfirmationDialog
          openDialog={showConfirmModal}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleCancelClick()}
          onConfirmFun={handlemodalSaveClick}
          handleCloseDialog={handleCancelClick}
        />
      )}
      {selectedImages1.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            mt: "20px",
          }}
        >
          {base64Images.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: "290px",
                height: "290px",
              }}
            >
              <img
                src={item}
                alt={`Saved sketch ${index + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default BodyMap;
