import React from "react";
import CustomButtom from "../reusableComponents/CustomButton";
import CareboxLogo from '../../assets/images/users/logo.jpg';
import agencyLogo from '../../assets/images/users/agency2.jpg';
import jsPDF from "jspdf";
import "jspdf-autotable";

function Download({ data, columns, selectToDownload,heading,rotateMode }) {
  const doc = new jsPDF(rotateMode ? "landscape" : "portrait")
  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 4; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo

    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(
      CareboxLogo,
      "JPEG",
      tableMargin,
      imageYPosition,
      logoWidthLeft,
      imageHeight
    ); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    const leftDistance = rotateMode ? 110 : 70;
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`${heading}`, leftDistance, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 15, 30);
    doc.autoTable({
      head: [columns],
      body: data,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 12,
        halign: "center", // Center-align header text horizontally
        valign: "middle", // Center-align header text vertically
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 }, // Adjust padding for better alignment
        textColor: "#FFFFFF", // White text for better contrast
      },
      styles: {
        fontSize: 13,
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
        cellPadding: { top: 5, right: 5, bottom: 5, left: 5 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center", valign: "middle" },
        1: { cellWidth: "auto", halign: "center", valign: "middle" },
        2: { cellWidth: "auto", halign: "center", valign: "middle" },
        3: { cellWidth: "auto", halign: "center", valign: "middle" },
        4: { cellWidth: "auto", halign: "center", valign: "middle" },
        5: { cellWidth: "auto", halign: "center", valign: "middle" },
        6: { cellWidth: "auto", halign: "center", valign: "middle" },
        7: { cellWidth: "auto", halign: "center", valign: "middle" },
        8: { cellWidth: "auto", halign: "center", valign: "middle" },
        9: { cellWidth: "auto", halign: "center", valign: "middle" },
        10: { cellWidth: "auto", halign: "center", valign: "middle" },
        11: { cellWidth: "auto", halign: "center", valign: "middle" },
      },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw;

        // If the column has no values (e.g., "Specialist"), enforce center alignment
        if (data.column.index === 4 && !cellContent) {
          data.cell.styles.valign = "middle";
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`${heading}_${today}.pdf`);
  };
  return (
    <div>
      <CustomButtom
        text="Download"
        onClick={exportClientsPdf}
        disabled={selectToDownload.length === 0}
      />
    </div>
  );
}

export default Download;
