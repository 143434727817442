import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import Download from "../Download";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "../../clients/styles/useStyles";
import { getCookie } from "../../../utils/utils";
import CustomCalendar from "../../logs/CustomCalendar";
import CustomButtom from "../../reusableComponents/CustomButton";
import { toast, ToastContainer } from "react-toastify";
const paginationModel = { page: 0, pageSize: 20 };
const columnsDownload = [
  "ClientName",
  "VisitDate",
  "Session",
  "Planned Duration",
  // "ActualTime",
  "HourlyPay",
  "TotalPTPay",
  // "TotalATPay",
];
const columns = [
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visitDate",
    headerName: " Visit Date",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "session",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalPlannedTime",
    headerName: "Planned Duration",
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "totalActualTime",
  //   headerName: "Actual Duration",
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "hourlyPay",
    headerName: "Hourly Pay",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalPlannedTimePay",
    headerName: "Total PT Pay",
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "totalActualTimePay",
  //   headerName: "Total At Pay",
  //   headerAlign: "center",
  //   align: "center",
  // },
 
];
function ToBeConfirmed() {
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [billingData, setBillingData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const classes = useStyles();
  const showToastMessage = () => {
    toast.success("Visits Confirmed Successfully", {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };

  const showToastFailureMessage = () => {
    toast.error("Failed to add visit", {
      position: "top-right",
    });
  };
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/billingtobeconfirmedvisits?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setBillingData(data.data);
      } else {
        console.error("API call failed");
        setBillingData([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  useEffect(() => {
    const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(initialStartDate, initialEndDate);
  }, []);
  useEffect(() => {
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const finalDate =
    startDate === endDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : `${dayjs(startDate).format("YYYY-MM-DD")} - ${dayjs(endDate).format(
          "YYYY-MM-DD"
        )}`;
  const rows = billingData?.map((billing) => ({
    id: billing?.visitDetailsId,
    clientName: billing?.clientName,
    visitDate: dayjs(billing?.visitDate).format("DD-MM-YYYY"), 
    session: billing?.session,
    totalPlannedTime: billing?.totalPlannedTime,
    // totalActualTime: billing?.totalActualTime,
    hourlyPay: billing?.hourlyPay,
    totalPlannedTimePay: billing?.totalPlannedTimePay,
    // totalActualTimePay: billing?.totalActualTimePay,
  }));
  const adjustedColumns = columns?.map((col) => {
    const isaddressColumn = col?.field === "clientName";
    return {
      ...col,
      flex: 1, // Dynamic width for `userName`
      minWidth: isaddressColumn ? 130 : 130, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}>
          {params.value || "N/A"} {/* Display placeholder if value is empty */}
        </Box>
      ),
    };
  });
  const finalDataToDownLoad = billingData?.filter((item) =>
    rowSelectionModel.includes(item?.visitDetailsId)
  );

  const bodyRows = finalDataToDownLoad?.map((item) => {
    return [
      item?.clientName,
      dayjs(item?.visitDate).format("DD-MM-YYYY"),
      item?.session,
      item?.plannedStartTime,
      // item.actualStartTime,
      item?.hourlyPay,
      item?.totalPlannedTimePay,
      // item.totalActualTimePay,
    ];
  });
  const visitIds = finalDataToDownLoad?.map((item) => {
    return item?.visitDetailsId;
  });
  const visitsToConform = async () => {
    console.log("function called");
    try {
      const URL = " https://3.8.158.142:8443/api/updatedconfirmvisits";
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ visitIds: visitIds }),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("data saved successfully");
        showToastMessage();

        const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
        fetchUsersAPI(updatedStartDate, updatedEndDate);
      } else {
        console.log("error updating the medication");
        showToastFailureMessage();
      }
    } catch (error) {
      console.log("there was an error", error);
    }
  };
  return (
    <Paper
      sx={{
        background: "#FFFFFF",
        borderRadius: "8px",
        px: 3,
        py: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1.5,
        }}
      >
        <Box sx={{ width: "33%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>

        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <CustomButtom
            text="Confirm Visits"
            onClick={visitsToConform}
            disabled={rowSelectionModel.length === 0}
          />
          <Download
            data={bodyRows}
            columns={columnsDownload}
            selectToDownload={rowSelectionModel}
            heading="User Payroll Billing"
            rotateMode ={false}
          />
        </Box>
      </Box>
      <Box
        className={classes.tableMainContainer}
        sx={{ height: "calc(100vh - 270px) !important" }}
      >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
        />
      </Box>
      <ToastContainer />
    </Paper>
  );
}

export default ToBeConfirmed;
