import React from 'react'
import Header from '../components/common/header/Header'
import BillingsMain from '../components/billings/BillingsMain'
import { Box } from '@mui/material'

const BillingPage = () => {
  return (
    <div>
      <Header/>
       <Box sx={{mt:4}}>
        <BillingsMain />
       </Box>
    </div>
  )
}

export default BillingPage