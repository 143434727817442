import React, { useEffect } from 'react'
import { getCookie } from '../../../utils/utils';
import { Box, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useStyles from '../../clients/styles/useStyles';
import { width } from '@mui/system';
import CustomButtom from '../../reusableComponents/CustomButton';
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loader from '../Loader';

const doc = new jsPDF();
const columns = [ 
  { field: 'clientName', headerName: 'Client Name',  headerAlign: 'center', align: 'center', },
  { field: 'status', headerName: 'Status',  headerAlign: 'center', align: 'center' },
  { field: 'contactNumber', headerName: 'Contact Number',  headerAlign: 'center', align: 'center' },
  {field: 'gender', headerName: 'Gender',  headerAlign: 'center', align: 'center' },
  {field: 'dob', headerName: 'Date of Birth',  headerAlign: 'center', align: 'center' }, 
  {field: 'age', headerName: 'Age',  headerAlign: 'center', align: 'center' },
  {field: 'serviceStartDate', headerName: 'ServiceStartDate',  headerAlign: 'center', align: 'center' },
  {field: 'serviceEndDate', headerName: 'ServiceEndDate', headerAlign: 'center', align: 'center' },
  {field: 'riskLevel', headerName: 'RiskLevel',  headerAlign: 'center', align: 'center' },
  {field: 'NHSNo', headerName: 'NHSNo',  headerAlign: 'center', align: 'center' },
  {field: 'address', headerName: 'Address',  headerAlign: 'center', align: 'center' },
];
const paginationModel = { page: 0, pageSize: 20 };
function ClientsOverview() {
  const [clientReports , setClientReports] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles()
  useEffect(()=>{
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = 'https://3.8.158.142:8443/api/getclientoverview';
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
      console.log(data.data,'==============================================cleients overview');
  
        // setmedicationroute(transformedData);
      
        if (response.ok === true) {
          setClientReports(data.data);
        } else {
          console.log("get all failed");  
          setClientReports([]);
        }
      } catch (e) {
        console.log("error", e.message);
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of success or failure
      }
    };
    fetchUsersAPI();
  },[])

if(loading){
  return (
    <Loader />
  )
}
  const rows = clientReports.map((item,index)=>({
    id:item.id,
    clientName:item.clientName,
    status:item.status,
    contactNumber:item.contactNumber,
    gender:item.gender,
    dob:item.dob,
    age:item.age,
    serviceStartDate:item.serviceStartDate,
    serviceEndDate:item.serviceEndDate,
    riskLevel:item.riskLevel,
    NHSNo:item.NHSNo,
    address:item.address,
  }))
  const adjustedColumns = columns.map((col) => {
    const isAddressColumn = col.field === "address" || col.field === "clientName"; // Check if it's the `address` column
    
    const maxColumnWidth = Math.max(
      col.headerName.length * 8 + 3, // Header text width
      ...rows.map((row) => String(row[col.field] || "").length * 8 + 1) // Row text width
    );
  
    return {
      ...col,
      width: isAddressColumn ? 200 : maxColumnWidth, // Set max width for address column
      minWidth: 70, // Set a minimum width to avoid extremely narrow columns
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            textAlign: "center", 
            height: "100%", // Take full cell height
            paddingTop:'13px',
            paddingBottom:'13px'
          }}
        >
          {params.value}
        </div>
      ),
    };
  });
  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 1; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo
  
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(CareboxLogo, "JPEG", tableMargin, imageYPosition, logoWidthLeft, imageHeight); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`Client Overview Reports`, 50, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 15, 30);
  
    const clientColumns = [
      "clientName",
      "status",
      "contactNumber",
      "Gender",
     " Date of Birth",
    ];
  
    const finalClients = rows.filter((u) => rowSelectionModel.includes(u.id));
    const body = 
        finalClients.map((d) => {
        return [
        d.clientName,
        d.status,
        d.contactNumber,
        d.gender,
        d.dob,
    ]})
    
  
    doc.autoTable({
      head: [clientColumns],
      body: body,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 12,
        halign: "center", // Center-align header text horizontally
        valign: "middle", // Center-align header text vertically
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 }, // Adjust padding for better alignment
        textColor: "#FFFFFF", // White text for better contrast
      },
      styles: {
        fontSize: 13,
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
        cellPadding: { top: 5, right: 5, bottom: 5, left: 5 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center", valign: "middle" },
        1: { cellWidth: "auto", halign: "center", valign: "middle" },
        2: { cellWidth: "auto", halign: "center", valign: "middle" },
        3: { cellWidth: "auto", halign: "center", valign: "middle" },
        4: { cellWidth: "auto", halign: "center", valign: "middle" },
        5: { cellWidth: "auto", halign: "center", valign: "middle" },
        6: { cellWidth: "auto", halign: "center", valign: "middle" },
        7: { cellWidth: "auto", halign: "center", valign: "middle" },
        8: { cellWidth: "auto", halign: "center", valign: "middle" },
        9: { cellWidth: "auto", halign: "center", valign: "middle" },
        10: { cellWidth: "auto", halign: "center", valign: "middle" },
        11: { cellWidth: "auto", halign: "center", valign: "middle" },
      },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw;
    
        // If the column has no values (e.g., "Specialist"), enforce center alignment
        if (data.column.index === 4 && !cellContent) {
          data.cell.styles.valign = "middle";
        }
      },
    });
    
  
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`clientsOverviewReports_${today}.pdf`);
  };
  return (
    <Box sx={{ px: 3,pt:4 }}   className="custom-datagrid-container">
     <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",px:2,pb:3,alignItems: "center" }}>
      <Typography variant="h5"  sx={{p:0,m:0,color:"#279989"}}>
      Clients  Reports  By Clients  Overview 
      </Typography>
      <CustomButtom text="Download" disabled={rowSelectionModel.length === 0} onClick={exportClientsPdf} />
      </Box>
    
      <Box className={classes.tableMainContainer} 
      >
       <DataGrid
        rows={rows}
        columns={adjustedColumns}
        getRowId={(row) => row.id}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[20, 30, 40]}
        checkboxSelection 
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        className={classes.datagridmain}
      
        getRowHeight={() => "auto"}  />
        </Box>
  </Box>
  )
}

export default ClientsOverview