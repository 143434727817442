import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import { getCookie } from "../../../utils/utils";
import MangeMedicationTable from "./MangeMedicationTable";
import useStyles from "../styles/useStyles";
import Loader from "../../../assets/images/common/logo_animation.gif";
import CustomButton from "../../reusableComponents/CustomButton";

function ManageMedication({
  clientMainData,
  clientId,
  setActiveTabId,
  setHasUnsavedChanges,
}) {
  const [userName, setUserName] = useState("");
  const [medicationData, setMedicationData] = useState([]);
  const [selectToDownload, setSelectToDownload] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const childRef = useRef();

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  // Fetch medication data with caching
  const fetchMedicationData = useCallback(async () => {
    const apiUrl = `https://3.8.158.142:8443/api/medication?client_id=${clientId}`;
    setLoading(true)
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.data?.length > 0) {
          setMedicationData(data.data)
        } else {
          setMedicationData([]);
        }
      } else {
        console.error(`API Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching medication data:", error);
      setMedicationData([]);
    } finally {
      setLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    fetchMedicationData();
  }, [fetchMedicationData]);

  const refreshData = async () => {
    await fetchMedicationData();
  };

  // Handle PDF download
  const handleDownloadClick = () => {
    if (childRef.current) {
      childRef.current.exportClientsPdf();
      childRef.current.setSelectedMedications([]);
      setSelectToDownload([]);
    }
  };
  return (
    <Grid container sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box className={classes.header}>
          <ClientHeader head={`Manage Medication for ${userName}`} />
        </Box>
        <CustomButton
          text="Download"
          onClick={handleDownloadClick}
          disabled={selectToDownload.length < 1}
        />
      </Box>
      <Box sx={{ mt: 3, width: "100%" }}>
        {loading && medicationData.length === 0 ? (
          <Box
            sx={{
              width: "100%",
              height: "calc(100% - 50px)",
              backgroundColor: "white",
              border: "1px solid #dddddd",
              overflowY: "auto",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Loader} alt="Loading..." />
          </Box>
        ) : (
          <MangeMedicationTable
            ref={childRef}
            medicationData={medicationData}
            setActiveTabId={setActiveTabId}
            refreshData={refreshData}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setSelectToDownload={setSelectToDownload}
          />
        )}
      </Box>
    </Grid>
  );
}
export default React.memo(ManageMedication);
