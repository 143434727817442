export const sections = [
  {
    title: "Training and Policy",
    questions: [
      {
        id: "c1",
        text: "Has the member of staff completed training on the safe handling of medicines?",
      },
      {
        id: "c2",
        text: "Has the member of staff read the medication policy and signed to indicate that they have done so?",
      },
      {
        id: "c3",
        text: "Does the member of staff know how to access the medication policy if they wish to check any information?",
      },
    ],
  },
  {
    title: "Administration of Medicines",
    subtitle: "Preparation and hygiene",
    questions: [
      {
        id: "c4",
        text: "Did the member of staff wash their hands before starting to administer any medication and follow appropriate hygiene measures throughout the medication round? E.g. wear gloves when applying creams.",
      },
      {
        id: "c5",
        text: "Did the member of staff make sure that everything was properly prepared before starting the medication round, e.g. was there plenty of medication cups, jug of water, beakers etc.",
      },
    ],
  },
  {
    subtitle: "Consent",
    questions: [
      {
        id: "c6",
        text: "Before preparing or administering the medication did the member of staff obtain the person's consent?",
      },
      {
        id: "c7",
        text: "If consent was not obtained was this part of a documented protocol for this person, such as covert administration, and is the member of staff satisfied that the correct procedures have been followed in the best interests of the person?",
      },
    ],
  },
  {
    subtitle: "Selection and preparation of medication",
    questions: [
      {
        id: "c8",
        text: "Before selecting, preparing or administering any medication did the member of staff read the MAR chart accurately?",
      },
      {
        id: "c9",
        text: "Did the member of staff check whether a dose had already been administered or if the medication had been cancelled?",
      },
      {
        id: "c10",
        text: "If any directions are unclear or illegible on the MAR did the member of staff take appropriate steps to clarify the directions?",
      },
      {
        id: "c11",
        text: "Was the medication selected checked against the correct MAR chart including checking the person's name on the label and MAR?",
      },
      {
        id: "c12",
        text: "If the directions on the MAR chart differed from those on the label did the member of staff take the appropriate steps to satisfy themselves as to the correct dose to be given?",
      },
      {
        id: "c13",
        text: "Was the correct medication and dose selected at the correct time? Was consideration given to timing in terms of food or other directions on the label?",
      },
      {
        id: "c14",
        text: "Was the medication prepared according to the directions and information on the MAR chart or any accompanying protocol?",
      },
      {
        id: "c15",
        text: "Did the member of staff use the appropriate measure for any doses of liquid medication? e.g. oral syringe, graduated measuring cup?",
      },
    ],
  },
  {
    subtitle: "Administration of medication",
    questions: [
      {
        id: "c16",
        text: "Did the member of staff check the records to see how the individual prefers to take their medication or demonstrate that they knew this information and administer the medication accordingly?",
      },
      {
        id: "c17",
        text: "Did the member of staff offer information, support and reassurance throughout to the person, in a manner which encourages their co-operation, promotes dignity and which is appropriate to their needs and concerns?",
      },
      {
        id: "c18",
        text: "Was the medicine administered correctly and a glass of water offered where appropriate?",
      },
    ],
  },
  {
    title: "Please tick the items you have witnessed being administered.",
    subtitle: "Medicine form",
    questions: [
      { id: 'c18_c1', text: "Tablets/capsules" },
      { id: 'c18_c2', text: "Inhaler devices" },
      { id: 'c18_c3' , text: "Ear Drops" },
      { id: 'c18_c4', text: "Creams and Ointments" },
      { id: 'c18_c5', text: "Liquids" },
      { id: 'c18_c6', text: "Eye Drops" },
      { id: 'c18_c7', text: "Nose Drops" },
      { id: 'c18_c8', text: "Transdermal patches" },
      { id:'c18_c9', text: "Sachets and powders" },
      { id: 'c18_c10', text: "Eye ointment" },
      { id: 'c18_c11', text: "Nasal sprays" },
    ],
  },
  {
    questions: [
      {
        id: "c19",
        text: "Was the security of all medication maintained throughout? Eg. Medication not left on the dining room table, medication trolley locked when staff not present.",
      },
      {
        id: "c20",
        text: "Did the member of staff visually witness the individual taking all their medication?",
      },
      {
        id: "c21",
        text: "If the medication was not taken was the appropriate advice sought and documented including checking information in the care plan, if appropriate?",
      },
      {
        id: "c22",
        text: "If the medication was not taken was it dealt with as given in the medication policy?",
      },
    ],
  },
  {
    title: "Record Keeping",
    questions: [
      {
        id: "c23",
        text: "Did the member of staff sign the MAR chart immediately after the medication was administered?",
      },
      {
        id: "c24",
        text: "If the medication was not given was an appropriate code entered on the MAR chart?",
      },
      {
        id: "c25",
        text: "If the medication is a controlled drug did the member of staff ask a trained colleague to witness the entire process and sign the CD register? ",
      },
      {
        id: "c26",
        text: "If the medication is a controlled drug was the controlled drug register completed as well as the MAR chart?",
      },
      {
        id: "c27",
        text: "Were the MAR charts returned to the proper place after the medication round? ",
      },
    ],
  },
  {
    title: "Stock Control ",
    questions: [
      
      {
        id: "c28",
        text: "Did the member of staff check that there was sufficient stock in place to complete future medication rounds? ",
      },
      {
        id: "c29",
        text: "If there are shortages in medication noted did the member of staff take appropriate action to ensure the stock was replaced? ",
      },
      {
        id: "c30",
        text: "Was all medication returned to the secure storage area once the medication round was completed and placed tidily?",
      },
    ],
  },
  {
    title: "Ordering, Receipt and Disposal of Medication ",
    questions: [
      {
        id: "c31",
        text: "Does the member of staff record any medication received into the home in a timely fashion using the correct documentation? ",
      },
      {
        id: "c32",
        text: "Does the member of staff order medication in accordance with the home's procedures after checking currently held stock? ",
      },
      {
        id: "c33",
        text: "Is any out of date medication or medication no longer required recorded on the appropriate documentation and stored securely, clearly seperated from 'in use' medication until it can be safely disposed of following the homes procedures?",
      },
    ],
  },
  {
    title: "Storage",
    questions: [
      {
        id: "c34",
        text: "Is the member of staff aware of the correct storage conditions for medicines and where to find this information?",
      },
      {
        id: "c35",
        text: "Is the member of staff aware of the correct temperature range for the medication fridge and how to use the thermometer?",
      },
      {
        id: "c36",
        text: "Does the member of staff fill in the fridge temperature records correctly?",
      },
      {
        id: "c37",
        text: "If new medication is received is the stock put away so that older supplies are used first?",
      },
    ],
  },
  {
    title: "Non prescribed medication",
    questions: [
      {
        id: "c38",
        text: "Is the member of staff aware of what action to take if a person living at the home wants to take 'over the counter' medication? ",
      },
      {
        id: "c39",
        text: "Is the member of staff aware of what to do if a person living at the home has a minor ailment?",
      },
      {
        id: "c40",
        text: "If a non prescribed medication was administered was this from the original container as purchased and was the dose offered within the directions given on the packaging? ",
      },
      {
        id: "c41",
        text: "If a non prescribed medication was administered did the member of staff record this correctly on the MAR chart? ",
      },
    ],
  },
  {
    title: "Accessing advice and information",
    questions: [
      {
        id: "c42",
        text: "Does the member of staff know who to contact if they need advice on medication?",
      },
      {
        id: "c43",
        text: "Is the staff member aware of the information sources held at the home particularly patient information leaflets which should be available for the person and staff? ",
      },
    ],
  },
  {
    title: "Dealing with errors",
    questions: [
      {
        id: "c44",
        text: "Can the member of staff describe the correct process for what to do if they make an error?",
      },
      {
        id: "c45",
        text: "Can the member of staff describe the correct process for what to do if they discover an error made by another member of staff? ",
      },
    ],
  },
];

export const nonequestions = [
  "c7",
  "c10",
  "c12",
  "c21",
  "c22",
  "c25",
  "c26",
  "c29",
  "c31",
  "c32",
  "c33",
  "c40",
  "c41",
];

export const emptyCompetency = {
  c1: "",
  c2: "",
  c3: "",
  c4: "",
  c5: "",
  c6: "",
  c7: "",
  c8: "",
  c9: "",
  c10: "",
  c11: "",
  c12: "",
  c13: "",
  c14: "",
  c15: "",
  c16: "",
  c17: "",
  c18: "",
  c18_c1: false,
  c18_c2: false,
  c18_c3: false,
  c18_c4: false,
  c18_c5: false,
  c18_c6: false,
  c18_c7: false,
  c18_c8: false,
  c18_c9: false,
  c18_c10: false,
  c18_c11: false,
  c19: "",
  c20: "",
  c21: "",
  c22: "",
  c23: "",
  c24: "",
  c25: "",
  c26: "",
  c27: "",
  c28: "",
  c29: "",
  c30: "",
  c31: "",
  c32: "",
  c33: "",
  c34: "",
  c35: "",
  c36: "",
  c37: "",
  c38: "",
  c39: "",
  c40: "",
  c41: "",
  c42: "",
  c43: "",
  c44: "",
  c45: "",
  info: "",
  action: "",
  sign_1: "",
  date_1: "",
  sign_2: "",
  date_2: "",
  "c46-checkbox": false,
};
