import React, { useState, useEffect, useCallback } from "react";

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { getCookie } from "../../../utils/utils";
import careLogoUrl from "../../../assets/images/common/CareEsteemPDFLogoRight.png";
import logoUrl from "../../../assets/images/common/careEsteemPDFLogo.png";

import loraBoldUrl from "../../../assets/fonts/Lora-Bold.ttf";
import loraMediumUrl from "../../../assets/fonts/Lora-Medium.ttf";
import loraSemiBoldUrl from "../../../assets/fonts/Lora-SemiBold.ttf";
import loraRegularUrl from "../../../assets/fonts/Lora-Regular.ttf";

Font.register({
  family: "Lora",
  fonts: [
    {
      src: loraRegularUrl, // Regular font
    },
    {
      src: loraBoldUrl,
      fontWeight: "bold", // Bold font
    },
    {
      src: loraMediumUrl,
      fontWeight: "500", // Medium weight font
    },
    {
      src: loraSemiBoldUrl,
      fontWeight: "600", // SemiBold weight font
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logoLeft: {
    position: "absolute",
    top: 10,
    left: 10,
    width: 50,
    height: 50,
  },
  logoRight: {
    position: "absolute",
    top: 16,
    right: 10,
    width: 85,
    height: 30,
  },
  headingContainer: {
    width: "100%", // Ensure it takes the full width
    fontFamily: "Lora",
    textAlign: "center", // Center the text
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold", // Already specifying fontWeight here, no need to override
    textDecoration: "underline",
    fontFamily: "Lora",
    marginTop: 10,
  },
  subHeading: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Lora",
    marginBottom: 5,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  rectangle: {
    backgroundColor: "#F6F6F6",
    padding: 10,
    flex: 1,
    margin: 5,
  },
  rectangleColorless: {
    padding: 10,
    flex: 1,
    marginHorizontal: 5,
  },
  rectangleAssessments: {
    backgroundColor: "#F6F6F6",
    padding: 10,
    margin: 5,
    // height: "auto",
  },
  leftColumn: {
    flexDirection: "column",
    flex: 1,
  },
  rightColumn: {
    flexDirection: "column",
    flex: 1,
  },
  text: {
    fontSize: 12,
    fontFamily: "Lora",
  },
  textForAnswer: {
    fontSize: 12,
    fontFamily: "Lora",
    marginLeft: 25,
    marginBottom: 2,
  },
  textForOverFlow: {
    fontSize: 12,
    flex: 1, // Take up remaining space
    flexWrap: "wrap", // Enable wrapping within the text
    fontFamily: "Lora",
  },
  textAllAssessment: {
    textAlign: "left",
    // flexWrap: "wrap", // Ensure text wraps within its container
    fontFamily: "Lora",
  },
  textAllAssessmentQuestion: {
    fontSize: 12,
    marginBottom: 4, // Add some space between questions
    marginTop: 3, // Add some space between questions
    fontFamily: "Lora",
    flexWrap: "wrap", // Make sure text can wrap
  },
  label: {
    fontFamily: "Lora",
    fontWeight: 500,
    width: 100, // Fixed width for the label to ensure alignment of colons
    fontSize: 12,
  },
  row: {
    flexDirection: "row",
    marginBottom: 6,
  },
  rowEmail: {
    flexDirection: "row",
    marginBottom: 6,
    flexWrap: "wrap", // Enable wrapping for child elements
  },
  rowAllAssessmentsName: {
    textAlign: "center",
    width: "100%",
    marginBottom: 2,
  },
  rowAllAssessmentsBy: {
    textAlign: "center",
    width: "100%",
    marginBottom: 4,
    marginLeft: "40px",
  },
  rowForOverFlow: {
    flexDirection: "row",
    alignItems: "flex-start", // Ensure items are aligned at the top
    marginBottom: 6, // Add spacing between rows
    width: "100%", // Allow the row to take full width
    flexWrap: "wrap", // Enable wrapping for child elements
  },
  lineHeight: {
    lineHeight: 1.5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    flex: 1,
    fontSize: 12,
    padding: 6,
    borderRightWidth: 1,
    borderColor: "#000",
    flexWrap: "wrap",
    overflow: "hidden",
    hyphens: "auto", // Allow automatic hyphenation
    textAlign: "left", // Align text properly within the cell
  },
});

const DownloadCarePlan = ({
  clientMainData,
  name,
  finalAssessment,
  finalMedicationData,
  finalTodoData,
  finalCareNetwork,
  filteredAssessments,
  filteredRiskAssessments,
}) => {
  // useEffect(() => {
  //   console.log(
  //     "Contents of filteredRiskAssessments:",
  //     filteredRiskAssessments
  //   );
  // }, [filteredRiskAssessments]);

  const {
    personalInfo: {
      dob = "N/A",
      age = "N/A",
      gender = "N/A",
      marital_status = "N/A",
      ethnicity_name = "N/A",
      religion_name = "N/A",
    } = {},

    fundingNo: {
      nhs_number = "N/A",
      funding_organization_name = "N/A",
      purchase_order = "N/A",
      service_agreement = "N/A",
      patient_access = "N/A",
    } = {},

    clientInfo: {
      prefix = "N/A",
      first_name = "N/A",
      middle_name = "",
      last_name = "N/A",
      contact_number = "N/A",
      email = "N/A",
      address = "N/A",
      city = "N/A",
      country_name = "N/A",
      postcode = "N/A",
      country_code = "N/A",
    } = {},

    nextToKinInfo: {
      prefix: nextKinPrefix = "N/A",
      name: nextKinName = "N/A",
      relationship_service_user = "N/A",
      address: nextKinAddress = "N/A",
      city: nextKinCity = "N/A",
      country_name: nextKinCountry = "N/A",
      postcode: nextKinPostcode = "N/A",
      contact_number: nextKinContactNumber = "N/A",
      email: nextKinEmail = "N/A",
      telephone_codes: nextKinTelephoneCode = "N/A",
    } = {},
  } = clientMainData || {};

  const DividerWithDots = () => (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginVertical: 30, // Add spacing above and below the line
      }}
    >
      {/* Left Dot */}
      <View
        style={{
          width: 6,
          height: 6,
          borderRadius: 3, // Makes it a circle
          backgroundColor: "black", // Adjust color if needed
        }}
      />
      {/* Line */}
      <View
        style={{
          height: 1.5,
          width: "90%", // Line width
          backgroundColor: "black", // Adjust color if needed
        }}
      />
      {/* Right Dot */}
      <View
        style={{
          width: 6,
          height: 6,
          borderRadius: 3,
          backgroundColor: "black",
        }}
      />
    </View>
  );

  const renderRiskAssessmentContent = (assessment) => {
    switch (assessment.assessment_id) {
      case 21: {
        return (
          <View style={styles.textAllAssessment}>
            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                {`1. Does ${name} require support with activities?`}
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.require_support}
              </Text>
            </View>

            {assessment.answerList[0]?.require_support === "yes" && (
              <View style={styles.textAllAssessment}>
                {assessment.answerList[0]?.activities.map((activity, index) => (
                  <View key={index} style={styles.textAllAssessment}>
                    <Text
                      style={[
                        styles.textAllAssessmentQuestion,
                        { fontFamily: "Lora", fontWeight: 500 },
                      ]}
                    >
                      {`Activity ${index + 1}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Activity: ${activity.activity}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Task/Support Required: ${activity.task_support_required}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Risk: ${activity.risk_level}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Risk Level: ${activity.range}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Equipment: ${activity.equipment}`}
                    </Text>
                    <Text style={styles.textForAnswer}>
                      {`Action to be taken: ${activity.action_to_be_taken}`}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
        );
      }
      case 22: {
        return (
          <View style={styles.textAllAssessment}>
            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                1. Frequency Potential
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.frequency_potential}
              </Text>

              {assessment.answerList[0]?.frequency_potential === "Other" && (
                <Text style={styles.textForAnswer}>
                  {`If Other: ${assessment.answerList[0]?.other_frequency_potential}`}
                </Text>
              )}
            </View>

            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                2. Who may be affected by the behaviour
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.affected_by_behaviour}
              </Text>

              {assessment.answerList[0]?.affected_by_behaviour === "Other" && (
                <Text style={styles.textForAnswer}>
                  {`If Other: ${assessment.answerList[0]?.other_affected_by_behaviour}`}
                </Text>
              )}
            </View>

            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                3. Potential Triggers
              </Text>
              {assessment.answerList[0]?.potential_triggers.map(
                (trigger, index) => (
                  <View key={index}>
                    {trigger !== "" && (
                      <Text style={styles.textForAnswer}>{trigger}</Text>
                    )}
                    {trigger === "Other" && (
                      <Text style={styles.textForAnswer}>
                        {`If Other: ${assessment.answerList[0]?.other_potential_triggers}`}
                      </Text>
                    )}
                  </View>
                )
              )}
            </View>

            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                4. Potential Hazards
              </Text>
              {assessment.answerList[0]?.potential_hazards.map(
                (hazard, index) => {
                  if (!hazard) return null; // Skip the iteration if the hazard is falsy
                  return (
                    <View key={index}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          {
                            fontFamily: "Lora",
                            fontWeight: 500,
                            marginLeft: "18px",
                          },
                        ]}
                      >
                        {hazard}
                      </Text>

                      <Text
                        style={[styles.textForAnswer, { marginLeft: "23px" }]}
                      >
                        {`Level of Risk: ${assessment.answerList[0]?.level_of_risk[index]}`}
                      </Text>

                      <Text
                        style={[styles.textForAnswer, { marginLeft: "23px" }]}
                      >
                        {`Range: ${assessment.answerList[0]?.risk_range[index]}`}
                      </Text>

                      <Text
                        style={[styles.textForAnswer, { marginLeft: "23px" }]}
                      >
                        {`Methods of support/management: ${assessment.answerList[0]?.support_methods[index]}`}
                      </Text>

                      <Text
                        style={[styles.textForAnswer, { marginLeft: "23px" }]}
                      >
                        {`Are the controls adequate?: ${assessment.answerList[0]?.controls_adequate[index]}`}
                      </Text>

                      {assessment.answerList[0]?.controls_adequate[index] ===
                        "yes" && (
                        <Text
                          style={[styles.textForAnswer, { marginLeft: "23px" }]}
                        >
                          {`Regulatory Measures: ${assessment.answerList[0]?.regulatory_measures[index]}`}
                        </Text>
                      )}
                    </View>
                  );
                }
              )}
            </View>
          </View>
        );
      }
      case 23: {
        return (
          <View style={styles.textAllAssessment}>
            <View style={styles.textAllAssessment}>
              {assessment.answerList[0]?.substanceDetails.map(
                (substance, index) => (
                  <View key={index} style={styles.textAllAssessment}>
                    <Text
                      style={[
                        styles.textAllAssessmentQuestion,
                        { fontFamily: "Lora", fontWeight: 500 },
                      ]}
                    >
                      {`Substance ${index + 1}`}
                    </Text>
                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Name of product/substance
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.name_of_product}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Type of harm the substance could cause
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.type_of_harm}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Description of the substance
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.description_substance}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Detail how the substance causes harm (i.e. being
                        breathed in, being swallowed etc)
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.details_substance}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Who is likely to come into contact with the substance?
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.contact_substance}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        How often is it used, or may it occur?
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.may_it_occur}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        What will the substance be used for or what
                        circumstances/activity may produce the substance?
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.substance_use}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text
                        style={[
                          styles.textAllAssessmentQuestion,
                          { marginLeft: "15px" },
                        ]}
                      >
                        Can the hazardous substance, circumstances or activity
                        be eliminated, or a safe alternative be used?
                      </Text>

                      <Text style={styles.textForAnswer}>
                        {substance.safe_alternative}
                      </Text>
                    </View>

                    {substance.safe_alternative.toLowerCase() === "no" && (
                      <View style={styles.textAllAssessment}>
                        <View style={styles.textAllAssessment}>
                          <Text
                            style={[
                              styles.textAllAssessmentQuestion,
                              { marginLeft: "15px" },
                            ]}
                          >
                            Controls (list control measures for storage, use and
                            handling, as appropriate)
                          </Text>

                          <Text style={styles.textForAnswer}>
                            {substance.controls}
                          </Text>
                        </View>

                        <View style={styles.textAllAssessment}>
                          <Text
                            style={[
                              styles.textAllAssessmentQuestion,
                              { marginLeft: "15px" },
                            ]}
                          >
                            Detail emergency procedures in case of accidental
                            spillage or contact
                          </Text>

                          <Text style={styles.textForAnswer}>
                            {substance.emergency_procedures}
                          </Text>
                        </View>

                        <View style={styles.textAllAssessment}>
                          <Text
                            style={[
                              styles.textAllAssessmentQuestion,
                              { marginLeft: "15px" },
                            ]}
                          >
                            Are all staff aware of this assessment, in
                            particular the controls and emergency procedures?
                          </Text>

                          <Text style={styles.textForAnswer}>
                            {substance.staff_aware}
                          </Text>
                        </View>

                        <View style={styles.textAllAssessment}>
                          <Text
                            style={[
                              styles.textAllAssessmentQuestion,
                              { marginLeft: "15px" },
                            ]}
                          >
                            Have controls reduced the risk of harm to an
                            acceptable level?
                          </Text>

                          <Text style={styles.textForAnswer}>
                            {substance.controls_reduced}
                          </Text>
                        </View>

                        {substance.controls_reduced.toLowerCase() === "no" && (
                          <View style={styles.textAllAssessment}>
                            <Text
                              style={[
                                styles.textAllAssessmentQuestion,
                                { marginLeft: "15px" },
                              ]}
                            >
                              Action to be taken where required
                            </Text>

                            <Text style={styles.textForAnswer}>
                              {substance.action_to_be_taken}
                            </Text>
                          </View>
                        )}
                      </View>
                    )}
                  </View>
                )
              )}
            </View>
          </View>
        );
      }
      case 24: {
        return (
          <View style={styles.textAllAssessment}>
            <View style={styles.textAllAssessment}>
              {assessment.answerList[0]?.equipmentDetails.map(
                (equipment, index) => (
                  <View key={index} style={styles.textAllAssessment}>
                    <Text
                      style={[
                        styles.textAllAssessmentQuestion,
                        { fontFamily: "Lora", fontWeight: 500 },
                      ]}
                    >
                      {`Equipment ${index + 1}`}
                    </Text>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Equipment: ${equipment.equipment}`}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Equipment Description: ${equipment.equipment_description}`}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Provided by: ${equipment.provided_by}`}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Purpose: ${equipment.purpose}`}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Date of next test: ${equipment.date_of_next_test}`}
                      </Text>
                    </View>

                    <View style={styles.textAllAssessment}>
                      <Text style={styles.textForAnswer}>
                        {`Test completed on: ${equipment.test_completed_on}`}
                      </Text>
                    </View>
                  </View>
                )
              )}
            </View>
          </View>
        );
      }
      case 25: {
        return (
          <View style={styles.textAllAssessment}>
            <View style={styles.textAllAssessment}>
              <Text style={styles.textAllAssessmentQuestion}>
                {`1. Does ${name} require assistance with managing your finances?`}
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.requires_assistance}
              </Text>
            </View>
            {assessment.answerList[0]?.requires_assistance === "yes" && (
              <View style={styles.textAllAssessment}>
                <Text
                  style={styles.textForAnswer}
                >{`Family Member: ${assessment.answerList[0]?.family_member_name}`}</Text>
                <Text
                  style={styles.textForAnswer}
                >{`Local Authority: ${assessment.answerList[0]?.local_authority_name}`}</Text>
                <Text
                  style={styles.textForAnswer}
                >{`Other: ${assessment.answerList[0]?.other}`}</Text>
              </View>
            )}

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                2. Is there a spending budget/ limit in place?
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.spending_limit}
              </Text>
            </View>

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>3. Details</Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.spending_details}
              </Text>
            </View>

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                4. Who has responsibility for deciding how the money is spent?
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.responsible_party}
              </Text>
            </View>

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                5. Are there any risks associated with the Service User or
                client who requires additional help with day to day general
                routine activities’s finances?
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.activities_finances}
              </Text>
            </View>

            {assessment.answerList[0]?.activities_finances === "yes" && (
              <View style={styles.textAllAssessment}>
                <Text style={styles.textForAnswer}>
                  {`If yes, provide details: ${assessment.answerList[0]?.activities_finances_details}`}
                </Text>
              </View>
            )}

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                6. Is there a safe location to store cash and receipts?
              </Text>
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.safe_location}
              </Text>
            </View>

            {assessment.answerList[0]?.safe_location === "yes" && (
              <View style={styles.textAllAssessment}>
                <Text style={styles.textForAnswer}>
                  {`If yes, provide details: ${assessment.answerList[0]?.provide_details}`}
                </Text>
              </View>
            )}
          </View>
        );
      }
      case 26: {
        // Define any required data before returning the JSX
        const medicationRiskData = [
          {
            name: "Ordering",
            status: assessment.answerList[0]?.ordering,
            comment: assessment.answerList[0]?.ordering_comment,
          },
          {
            name: "Collecting",
            status: assessment.answerList[0]?.collecting,
            comment: assessment.answerList[0]?.collecting_comment,
          },
          {
            name: "Verbal Prompt",
            status: assessment.answerList[0]?.verbal_prompt,
            comment: assessment.answerList[0]?.verbal_prompt_comment,
          },
          {
            name: "Assisting (physically opening boxes/bottles)",
            status: assessment.answerList[0]?.assisting,
            comment: assessment.answerList[0]?.assisting_comment,
          },
          {
            name: "Administering* (including creams, patches or lotions)",
            status: assessment.answerList[0]?.administering,
            comment: assessment.answerList[0]?.administering_comment,
          },
          {
            name: "Specialized Support",
            status: assessment.answerList[0]?.specialized_support,
            comment: assessment.answerList[0]?.specialized_support_comment,
          },
          {
            name: "Time Specific",
            status: assessment.answerList[0]?.time_specific,
            comment: assessment.answerList[0]?.time_specific_comment,
          },
          {
            name: "Are any of the medicines a Controlled Drug?",
            status: assessment.answerList[0]?.controlled_drugs,
            comment: assessment.answerList[0]?.controlled_drugs_details,
          },
        ];

        return (
          <View style={styles.textAllAssessment}>
            {/* Iterate over the medicationRiskData if needed */}
            {medicationRiskData.map((data, index) => (
              <View key={index} style={styles.textAllAssessment}>
                {/* Question */}
                <Text style={styles.textAllAssessmentQuestion}>
                  {`${index + 1}. ${data.name}`}
                </Text>

                {/* Status */}
                <Text style={styles.textForAnswer}>{data.status}</Text>

                {/* Comment */}
                <Text style={styles.textForAnswer}>
                  {data.comment ? `Comment: ${data.comment}` : ""}
                </Text>
              </View>
            ))}
            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                9. Where Agency name is responsible for the collecting and/or
                ordering how will #Agency be notified of this?
              </Text>

              {/* Status */}
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.agency_notification}
              </Text>
            </View>

            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                10. Where not responsible, when should Agency name expect your
                medication to be collected/ordered?
              </Text>

              {/* Status */}
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.medication_collection_details}
              </Text>
            </View>
            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                11. How will you notify the Care Worker when a dose of PRN
                medication is required?
              </Text>

              {/* Status */}
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.prn_medication}
              </Text>
            </View>
            <View style={styles.textAllAssessment}>
              {/* Question */}
              <Text style={styles.textAllAssessmentQuestion}>
                12. Is there a safe place to store medication?
              </Text>

              {/* Status */}
              <Text style={styles.textForAnswer}>
                {assessment.answerList[0]?.safe_storage}
              </Text>
            </View>
            {(assessment.answerList[0]?.safe_storage).toLowerCase() ===
              "yes" && (
              <View style={styles.textAllAssessment}>
                {/* Question */}
                <Text style={styles.textAllAssessmentQuestion}>
                  13. If yes, please specify where:
                </Text>

                {/* Status */}
                <Text style={styles.textForAnswer}>
                  {assessment.answerList[0]?.storage_location}
                </Text>
              </View>
            )}
          </View>
        );
      }
      case 27: {
        const selfMedRiskData = [
          {
            name: `Does ${name} know what medicines they are taking and what they are for?`,
            status: assessment.answerList[0]?.medicines,
          },
          {
            name: `Does ${name} know what time to take the medicines?`,
            status: assessment.answerList[0]?.time_to_take_medicines,
          },
          {
            name: `Does ${name} know what dosage to take?`,
            status: assessment.answerList[0]?.dosage,
          },
          {
            name: `Does ${name} know how to take the medicines?`,
            status: assessment.answerList[0]?.take_medicines,
          },
          {
            name: `Does ${name} know about any special instructions?`,
            status: assessment.answerList[0]?.special_instructions,
          },
          {
            name: `Does ${name} know about common, possible side effects?`,
            status: assessment.answerList[0]?.side_effects,
          },
          {
            name: `Does ${name} know what to do if they miss a dose?`,
            status: assessment.answerList[0]?.missed_dose,
          },
          {
            name: `Does ${name} have any difficulty in reading the label on the medicines?`,
            status: assessment.answerList[0]?.difficulty_reading_label,
          },
          {
            name: `Does ${name} open their medication (blister packs, bottles)?`,
            status: assessment.answerList[0]?.opens_medication,
          },
          {
            name: `Does ${name} understand the principles of safe storage for medicines, including their responsibility for safe keeping?`,
            status: assessment.answerList[0]?.safe_storage,
          },
          {
            name: `Does ${name} agree to notify staff of any changes to the prescribed medication and/or over-the-counter/homely medications?`,
            status: assessment.answerList[0]?.agrees_to_notify,
          },
          {
            name: `Would ${name} be responsible for the re-ordering of prescribed medication and its collection?`,
            status: assessment.answerList[0]?.responsible_for_reorder,
          },
        ];
        return (
          <View style={styles.textAllAssessment}>
            {selfMedRiskData.map((data, index) => (
              <View key={index} style={styles.textAllAssessment}>
                <Text style={styles.textAllAssessmentQuestion}>
                  {`${index + 1}. ${data.name}`}
                </Text>

                <Text style={styles.textForAnswer}>{data.status}</Text>
              </View>
            ))}

            {assessment.answerList[0]?.medicationDetails.map(
              (medicine, index) => (
                <View key={index} style={styles.textAllAssessment}>
                  <Text
                    style={[
                      styles.textAllAssessmentQuestion,
                      { fontFamily: "Lora", fontWeight: 500 },
                    ]}
                  >
                    {`Medication ${index + 1}`}
                  </Text>

                  <View style={styles.textAllAssessment}>
                    <Text style={styles.textForAnswer}>
                      {`Name of Medicine: ${medicine.medicine_name}`}
                    </Text>
                  </View>

                  <View style={styles.textAllAssessment}>
                    <Text style={styles.textForAnswer}>
                      {`Dose: ${medicine.dose}`}
                    </Text>
                  </View>

                  <View style={styles.textAllAssessment}>
                    <Text style={styles.textForAnswer}>
                      {`Route: ${medicine.route}`}
                    </Text>
                  </View>

                  <View style={styles.textAllAssessment}>
                    <Text style={styles.textForAnswer}>
                      {`Time/Frequency: ${medicine.time_frequency}`}
                    </Text>
                  </View>

                  <View style={styles.textAllAssessment}>
                    <Text style={styles.textForAnswer}>
                      {`Self-Administration: ${medicine.self_administration}`}
                    </Text>
                  </View>
                </View>
              )
            )}
          </View>
        );
      }
      default:
        return (
          <Text style={[styles.textAllAssessment, { fontSize: "12px" }]}>
            {`No content available for Assessment ID ${assessment.assessment_id}`}
          </Text>
        );
    }
  };

  const dayMapping = {
    1: "M", // Monday
    2: "Tu", // Tuesday
    3: "W", // Wednesday
    4: "Th", // Thursday
    5: "F", // Friday
    6: "Sa", // Saturday
    7: "Su", // Sunday
  };
  return (
    <Document>
      <Page style={styles.page}>
        {/* Logos */}
        <Image style={styles.logoLeft} src={logoUrl} />
        <Image style={styles.logoRight} src={careLogoUrl} />

        <View style={styles.headingContainer}>
          <Text
            style={styles.heading}
          >{`${first_name} ${last_name} Care Plan`}</Text>
        </View>

        {/* Container for Two Rectangles */}
        <View style={styles.container}>
          {/* Left Column (Personal info) */}
          <View style={[styles.rectangle, styles.leftColumn]}>
            <Text
              style={styles.subHeading}
            >{`${first_name} ${last_name} (${first_name})`}</Text>
            <View style={styles.row}>
              <Text style={[styles.label]}>DOB</Text>
              <Text style={styles.text}>{`: ${dob}`}</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label]}>Age</Text>
              <Text style={styles.text}>{`: ${age}`}</Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>NHS No.</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text style={[styles.textForOverFlow]}>{`${nhs_number}`}</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label]}>Gender</Text>
              <Text style={styles.text}>{`: ${gender}`}</Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Religion</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text style={[styles.textForOverFlow]}>{`${religion_name}`}</Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Marital Status</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text
                style={[styles.textForOverFlow]}
              >{`${marital_status}`}</Text>
            </View>

            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Ethnicity</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text
                style={[styles.textForOverFlow]}
              >{`${ethnicity_name}`}</Text>
            </View>
          </View>

          {/* Right Column (Contact info) */}
          <View style={[styles.rectangle, styles.rightColumn]}>
            <View style={styles.row}>
              <Text style={[styles.label]}>Contact Number</Text>
              <Text
                style={styles.text}
              >{`: ${country_code} ${contact_number}`}</Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Email Address</Text>
              <Text style={[styles.text]}>{`: `}</Text>
              <Text
                style={[
                  styles.textForOverFlow,
                  // { marginLeft: -10, marginTop: 5 },
                ]}
              >
                {`${email}`}
              </Text>
            </View>

            <View style={styles.rowForOverFlow}>
              <Text style={[styles.label]}>Address</Text>
              <Text style={[styles.text]}>{`:`}</Text>
              <Text style={[styles.textForOverFlow, { marginLeft: 3 }]}>
                {`${
                  [address, city, country_name, postcode]
                    .filter((part) => part && part.trim()) // Remove falsy or empty values
                    .join(", ") // Join the valid parts with a comma
                }`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.container}>
          {/* Left Column (Personal info) */}
          <View style={[styles.rectangleColorless, styles.leftColumn]}>
            <Text
              style={styles.subHeading}
            >{`Next to Kin for ${first_name} ${last_name}`}</Text>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Name</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text style={[styles.textForOverFlow]}>{`${nextKinName}`}</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label]}>Relation</Text>
              <Text
                style={styles.text}
              >{`: ${relationship_service_user}`}</Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={[styles.label]}>Address</Text>
              <Text style={[styles.text]}>{`:`}</Text>
              <Text style={[styles.textForOverFlow, { marginLeft: 5 }]}>
                {`${
                  [nextKinAddress, nextKinCity, nextKinCountry, nextKinPostcode]
                    .filter((part) => part && part.trim()) // Filter out falsy or empty values
                    .join(", ") // Join valid parts with a comma and space
                }`}
              </Text>
            </View>
            <View style={styles.rowForOverFlow}>
              <Text style={styles.label}>Email Address</Text>
              <Text style={styles.text}>{`: `}</Text>
              <Text style={[styles.textForOverFlow]}>{`${nextKinEmail}`}</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label]}>Contact Number</Text>
              <Text
                style={styles.text}
              >{`: ${nextKinTelephoneCode} ${nextKinContactNumber}`}</Text>
            </View>
          </View>
        </View>

        {(filteredAssessments.length > 0 ||
          filteredRiskAssessments.length > 0) && (
          <View>
            <DividerWithDots />
            {filteredAssessments && filteredAssessments.length > 0 && (
              <Text
                style={styles.subHeading}
              >{`Assessments of ${first_name} ${last_name}`}</Text>
            )}
            {filteredAssessments &&
              filteredAssessments.length > 0 &&
              filteredAssessments.map((assessment, index) => (
                <View style={[styles.rectangleAssessments]}>
                  <View style={styles.rowAllAssessmentsName}>
                    <Text
                      key={index}
                      style={[
                        styles.text,
                        { fontFamily: "Lora", fontWeight: 500 },
                      ]}
                    >
                      {assessment.assessment_name}
                    </Text>
                  </View>
                  <View style={styles.rowAllAssessmentsBy}>
                    <Text
                      style={[
                        styles.text,
                        {
                          fontSize: "8px",
                          fontFamily: "Lora",
                          fontWeight: 200,
                        },
                      ]}
                    >
                      {`- ${assessment.sign_name} @ ${assessment.sign_date}`}
                    </Text>
                  </View>
                  <View style={styles.textAllAssessment}>
                    {assessment.questionList.map((question, qIndex) => {
                      const statusKey = `status_${question.questionid}`;
                      const commentKey = `comment_${question.questionid}`;

                      const status =
                        assessment.answerList[0]?.[statusKey] || "";
                      const comment =
                        assessment.answerList[0]?.[commentKey] || "";

                      return (
                        <View key={qIndex}>
                          <Text
                            style={styles.textAllAssessmentQuestion}
                            wrap={false}
                          >
                            {`${qIndex + 1}. ${question.questionname}`}
                          </Text>

                          <Text style={styles.textForAnswer}>{status}</Text>

                          <Text style={styles.textForAnswer}>
                            {comment ? `Comment: ${comment}` : ""}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              ))}

            {filteredRiskAssessments && filteredRiskAssessments.length > 0 && (
              <Text
                style={styles.subHeading}
              >{`Risk Assessments of ${first_name} ${last_name}`}</Text>
            )}
            {filteredRiskAssessments &&
              filteredRiskAssessments.length > 0 &&
              filteredRiskAssessments.map((assessment, index) => (
                <View>
                  <View style={[styles.rectangleAssessments]}>
                    <View style={styles.rowAllAssessmentsName}>
                      <Text
                        key={index}
                        style={[
                          styles.text,
                          { fontFamily: "Lora", fontWeight: 500 },
                        ]}
                      >
                        {assessment.assessment_name}
                      </Text>
                    </View>
                    <View style={styles.rowAllAssessmentsBy}>
                      <Text
                        style={[
                          styles.text,
                          {
                            fontSize: "8px",
                            fontFamily: "Lora",
                            fontWeight: 200,
                          },
                        ]}
                      >
                        {`- ${assessment.sign_name} @ ${assessment.sign_date}`}
                      </Text>
                    </View>
                    {/* <View style={styles.textAllAssessment}> */}
                    {renderRiskAssessmentContent(assessment)}
                    {/* </View> */}
                  </View>
                </View>
              ))}
          </View>
        )}

        {finalCareNetwork &&
          finalCareNetwork.some(
            (item) =>
              item.name_organization ||
              item.contact_number ||
              item.email ||
              item.address ||
              item.city ||
              item.postcode
          ) && (
            <View>
              <DividerWithDots />

              <View wrap={false}>
                <Text
                  style={styles.subHeading}
                >{`Care Network of ${first_name} ${last_name}`}</Text>
                <View
                  style={{
                    marginTop: 10,
                    borderWidth: 1,
                    borderBottomWidth: 0,
                    color: "#000",
                  }}
                >
                  {/* Table Header */}
                  <View style={[styles.tableRow]}>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          fontFamily: "Lora",
                          fontWeight: "bold",
                          flexBasis: 80, // Set initial width of the first column
                          flex: 0, // Prevent it from growing
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontFamily: "Lora", fontWeight: "bold" },
                      ]}
                    >
                      Name
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontFamily: "Lora", fontWeight: "bold" },
                      ]}
                    >
                      Contact Number
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontFamily: "Lora", fontWeight: "bold" },
                      ]}
                    >
                      Email Address
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          fontFamily: "Lora",
                          fontWeight: "bold",
                          borderRightWidth: 0,
                        },
                      ]}
                    >
                      Address
                    </Text>
                  </View>

                  {["Next Of Kin", "District Nurse", "GP Practice"].map(
                    (type) => {
                      const filteredCareNet = finalCareNetwork.find(
                        (item) =>
                          item.occupation_type?.toLowerCase() ===
                          type.toLowerCase()
                      );

                      return (
                        <View style={styles.tableRow} key={type}>
                          <Text
                            style={[
                              styles.tableCell,
                              {
                                fontFamily: "Lora",
                                fontWeight: "bold",
                                flexBasis: 80, // Set initial width of the first column
                                flex: 0, // Prevent it from growing
                              },
                            ]}
                          >
                            {type}
                          </Text>
                          <Text style={styles.tableCell}>
                            {filteredCareNet?.name_organization || "N/A"}
                          </Text>
                          <Text style={styles.tableCell}>
                            {`${filteredCareNet?.telephone_country_code} ${filteredCareNet?.contact_number}`.trim() ||
                              "N/A"}
                          </Text>
                          <Text
                            style={[styles.tableCell]}
                            hyphenationCallback={(word) => word.split("")}
                          >
                            {filteredCareNet?.email || "N/A"}
                          </Text>
                          <Text
                            style={[
                              styles.tableCell,
                              {
                                borderRightWidth: 0,
                              },
                            ]}
                          >
                            {`${filteredCareNet?.address || ""} \n${
                              filteredCareNet?.city || ""
                            }  \n${filteredCareNet?.postcode || ""}`.trim() ||
                              "N/A"}
                          </Text>
                        </View>
                      );
                    }
                  )}
                </View>
              </View>
            </View>
          )}

        <DividerWithDots />

        <Text style={[styles.subHeading, { marginBottom: 15 }]}>
          {`Visits of ${first_name} ${last_name}`}
        </Text>

        <View
          style={{
            height: 180,
            width: "100%",
            backgroundColor: "#D9D9D9",
          }}
        ></View>

        {finalTodoData && finalTodoData.length > 0 && (
          <View>
            <DividerWithDots />

            <Text style={[styles.subHeading, { marginBottom: 15 }]}>
              {`To-Do's of ${first_name} ${last_name}`}
            </Text>

            <View style={[styles.rectangleAssessments]}>
              {finalTodoData.map((todo, index) => (
                <View style={styles.textAllAssessment} key={index}>
                  <View>
                    <Text style={styles.textAllAssessmentQuestion}>
                      {`${index + 1}. ${todo.todo_description} `}
                      <Text style={{ fontWeight: 500, fontFamily: "Lora" }}>
                        ({todo.essential === 1 ? "Essential" : "Non-Essential"})
                      </Text>
                    </Text>

                    <Text style={styles.textForAnswer}>
                      Frequency:{" "}
                      <Text style={{ fontWeight: 500, fontFamily: "Lora" }}>
                        {todo.select_days
                          .map((day) => dayMapping[day])
                          .join(", ")}
                      </Text>
                    </Text>

                    <Text style={styles.textForAnswer}>
                      Session:{" "}
                      <Text style={{ fontWeight: 500, fontFamily: "Lora" }}>
                        {todo.session_type.map((sess) => sess).join(", ")}
                      </Text>
                    </Text>

                    <Text style={styles.textForAnswer}>
                      {`Notes: ${todo.additional_notes}`}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}

        {finalMedicationData && finalMedicationData.length > 0 && (
          <View>
            <DividerWithDots />

            <Text style={[styles.subHeading, { marginBottom: 15 }]}>
              {`Medication of ${first_name} ${last_name}`}
            </Text>

            <View style={[styles.rectangleAssessments]}>
              {finalMedicationData.map((medicine, index) => {
                let renderedContent;

                if (medicine.medication_type === "Scheduled") {
                  renderedContent = (
                    <>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Medication Type:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.medication_type}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            Dose:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.scheduled_times_per_day}
                            </Text>
                          </Text>
                        </Text>
                      </View>

                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Frequency:{" "}
                            <Text
                              style={{ fontWeight: "500", fontFamily: "Lora" }}
                            >
                              {medicine.scheduled_select_days
                                .map((day) => dayMapping[day])
                                .join(", ")}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            Start Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.scheduled_start_date}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Session:{" "}
                            <Text
                              style={{ fontWeight: "500", fontFamily: "Lora" }}
                            >
                              {medicine.scheduled_choose_sessions_session_type.join(
                                ", "
                              )}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            End Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.scheduled_end_date}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                    </>
                  );
                } else if (medicine.medication_type === "Blister Pack") {
                  renderedContent = (
                    <>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Medication Type:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.medication_type}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            Dose:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.blister_pack_times_per_day}
                            </Text>
                          </Text>
                        </Text>
                      </View>

                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Frequency:{" "}
                            <Text
                              style={{ fontWeight: "500", fontFamily: "Lora" }}
                            >
                              {medicine.blister_pack_select_days
                                .map((day) => dayMapping[day])
                                .join(", ")}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            Start Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.blister_pack_start_date}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Session:{" "}
                            <Text
                              style={{ fontWeight: "500", fontFamily: "Lora" }}
                            >
                              {medicine.blister_pack_choose_sessions_session_type.join(
                                ", "
                              )}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            End Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.blister_pack_end_date}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                    </>
                  );
                } else if (medicine.medication_type === "PRN") {
                  renderedContent = (
                    <>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Medication Type:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.medication_type}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            Dose:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {`${medicine.prn_dose_per} per ${medicine.prn_doses} ${medicine.prn_time_frame}`}
                            </Text>
                          </Text>
                        </Text>
                      </View>

                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Start Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.prn_start_date}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            End Date:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.prn_end_date}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                      <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>
                          <Text style={styles.textForAnswer}>
                            Why PRN Offered:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.prn_offered}
                            </Text>
                          </Text>
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            {
                              borderRightWidth: 0,
                            },
                          ]}
                        >
                          <Text style={styles.textForAnswer}>
                            When PRN Given:{" "}
                            <Text
                              style={{ fontWeight: 500, fontFamily: "Lora" }}
                            >
                              {medicine.prn_be_given}
                            </Text>
                          </Text>
                        </Text>
                      </View>
                    </>
                  );
                }
                // else {
                //   renderedContent = (
                //     <>
                //       <Text style={styles.textForAnswer}>Frequency: </Text>
                //       <Text style={styles.textForAnswer}>Session: </Text>
                //     </>
                //   );
                // }

                return (
                  <View
                    style={styles.textAllAssessment}
                    key={index}
                    wrap={false}
                  >
                    <Text
                      style={[
                        styles.textAllAssessmentQuestion,
                        { marginTop: 8 },
                      ]}
                    >
                      {`${index + 1}. ${medicine.nhs_medicine_name}`}
                    </Text>

                    <View
                      style={{
                        borderWidth: 1,
                        borderBottomWidth: 0,
                        color: "#000",
                      }}
                    >
                      {renderedContent}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default DownloadCarePlan;
