import React, { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import ToBeConfirmed from "./clientinvoice/ToBeConfirmed";
import ToBeInvoiced from "./clientinvoice/ToBeInvoiced";
import ClientInVoices from "./clientinvoice/ClientInVoices";
import { TabButton1 } from "../clients/clientReusableComponents/components";

function ClientBillingDashBoard() {
  const [currentTab, setCurrentTab] = useState(1);
  const tabs = [
    { id: 1, text: "To-be-confirmed Visits" },
    { id: 2, text: "To-be-invoiced" },
    { id: 3, text: "Client Invoices" },
  ];
  const renderContent = () => {
    switch (currentTab) {
      case 1:
        return <ToBeConfirmed />;
      case 2:
        return <ToBeInvoiced />;
      case 3:
        return <ClientInVoices />;
      default:
        return null;
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {tabs.map((tab) => (
          <Box key={tab.id}>
            <TabButton1
              data={{
                id: tab.id,
                text: tab.text,
              }}
              isActive={currentTab === tab.id}
              onClick={() => setCurrentTab(tab.id)}
            ></TabButton1>
          </Box>
        ))}
      </Box>
      <Paper>{renderContent()}</Paper>
    </Box>
  );
}

export default ClientBillingDashBoard;
