import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TabButton, UserListBox } from "../users/userPersonal/Components";
import { Box, IconButton } from "@mui/material";
import LogVisitDetails from "./LogTabsContent/LogVisitDetails";
import LogToDos from "./LogTabsContent/LogToDo";
import LogMedication from "./LogTabsContent/LogMedication";
import LogCarerNotes from "./LogTabsContent/LogCarerNotes";
import LogTimeline from "./LogTabsContent/LogTimeline";
import Admin from "../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../reusableComponents/ConfirmationDialog";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const tabsList = [
  { id: 1, text: "Visit Details" },
  { id: 2, text: "To-Do's" },
  { id: 3, text: "Medication" },
  { id: 4, text: "Carer Notes" },
  { id: 5, text: "Timeline" },
];

export default function LogRightDashboard({
  selectedVisit,
  setHasUnsavedChanges,
  hasUnsavedChanges,
}) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [pendingTabId, setPendingTabId] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const scrollRef = useRef(null);
  const [showArrows, setShowArrows] = useState(false); // Single state to manage both arrows

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => {
    setOpenDialog(false);
    setPendingTabId(null);
  };
  const onClickYesDialogBtn = () => {
    if (pendingTabId !== null) {
      setActiveTab(pendingTabId);
      setPendingTabId(null);
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleClose();
  };

  // Handle tab switching with unsaved changes confirmation
  const handleTabSwitch = (tabId) => {
    if (hasUnsavedChanges) {
      setPendingTabId(tabId);
      handleOpen();
    } else {
      setActiveTab(tabId);
    }
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <LogVisitDetails
            selectedVisit={selectedVisit}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        );

      case 2:
        return <LogToDos selectedVisit={selectedVisit} />;

      case 3:
        return <LogMedication selectedVisit={selectedVisit} />;

      case 4:
        return <LogCarerNotes />;
      case 5:
        return <LogTimeline />;
      default:
        return null; // or a default component
    }
  };

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      // Scroll by -50px to the left
      scrollRef.current.scrollLeft -= 50; // Direct manipulation of scroll position
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      // Scroll by 50px to the right
      scrollRef.current.scrollLeft += 50; // Direct manipulation of scroll position
    }
  };

  // Function to check if scroll is possible
  const checkScrollButtons = () => {
    const scrollableWidth = scrollRef.current.scrollWidth;
    const containerWidth = scrollRef.current.offsetWidth;
    const scrollPosition = scrollRef.current.scrollLeft;

    // If the content is scrollable (left or right), show the arrows
    setShowArrows(
      scrollPosition > 0 || scrollPosition < scrollableWidth - containerWidth
    );
  };

  // Add event listener for scroll event
  useEffect(() => {
    const scrollElement = scrollRef.current;

    // Check if the arrows should be shown initially and after every scroll event
    const handleScroll = () => {
      checkScrollButtons();
    };

    scrollElement.addEventListener("scroll", handleScroll);

    // Check for resizing the window (for zoom effect)
    const handleResize = () => {
      checkScrollButtons();
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listeners on component unmount
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Check if the scroll position is at the start or end initially
  useEffect(() => {
    checkScrollButtons();
  }, [tabsList]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        component="div"
        sx={{
          background: "white",
          width: "95%",
          position: "relative",
          borderRadius: "15px",
          marginTop: "45px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 10,
            left: "50%",
            transform: "translate(-50%, -100%)",
            zIndex: 1,
            maxWidth: "100%",
          }}
        >
          {/* Left Arrow Button */}
          {showArrows && (
            <Box>
              <IconButton
                sx={{
                  zIndex: 2,
                  //backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={handleScrollLeft}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
            </Box>
          )}
          <Box
            ref={scrollRef}
            sx={{
              display: "flex",
              alignItems: "end",
              overflowX: "auto",
              borderRadius: "5px",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
            }}
          >
            {tabsList.map((each) => (
              <TabButton
                key={each.id}
                isActive={activeTab === each.id}
                onClick={() => handleTabSwitch(each.id)}
                data={each}
              />
            ))}
          </Box>

          {/* Right Arrow Button */}
          {showArrows && (
            <Box>
              <IconButton
                sx={{
                  zIndex: 2,
                  //backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={handleScrollRight}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            marginTop: "15px",
            borderRadius: "10px",
            height: "calc(100vh - 330px)",
            //boxShadow: "0px 0px 10px 0px #27998933",
            overflow: "hidden",
          }}
        >
          {" "}
          {renderActiveTabContent()}{" "}
          {/* Render the content based on the active tab */}
        </Box>
      </Box>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
    </Box>
  );
}
