// src/styles/useStyles.js
import { makeStyles } from "@mui/styles";
import ManageMedication from "../medication/ManageMedication";
import { borderBottom, height, margin, padding } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  Manage_main_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "15px",
  },
  add_to_visit_btn: {
    width: "180px",
    height: "40px",
    color: "#279989",
    fontSize: "14px",
    border: "1px solid #279989",
    boxShadow: "0px 0px 10px 0px #27998933",
    borderRadius: "8px",
    textTransform: "none",
  },
  header: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    width: "100%",
  },
  // content: {
  //   marginTop: '15px', // Adjust based on header height
  //   overflowY: 'auto',
  //   height: 'calc(100vh - 60px)', // Fill remaining height

  // },
  medicationHeader: {
    padding: "15px",
    borderRadius: "10px",
    height: "calc(100vh - 220px)",
    boxShadow: "0px 0px 10px 0px #27998933",
    width: "100%",

    display: "flex",
  },
  medicationsidebar: {
    width: "20%",
    background: "#F0FCFA",
    borderRadius: "8px",
    padding: "10px",
    height: "100%",
    overflowY: "scroll", // Allow scrolling
    //scrollbarWidth: "none", // For Firefox
    // "&::-webkit-scrollbar": {
    //   // For Chrome, Safari, and Edge
    //   display: "none",
    // },
  },
  medicationRightSideContent: {
    width: "80%",
    paddingLeft: "20px",
    maxHeight: "calc(100vh - 220px)", // Sets max height for scrolling
    overflowY: "auto", // Enable vertical scrolling for content
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      // For Chrome, Safari, and Edge
      display: "none",
    },
  },
  sessionLabel: {
   
    display: "none !important", // Ensure the correct syntax
  },
  medicationTable: {
    fontSize: "14px !important", 
    width: "100% !important", 
    boxShadow: "0px 0px 10px 0px #2799894D",
  },
  tableCell: {
    fontSize: "13px !important",
     paddingLeft:'2px !important',
     paddingRight:'2px !important',
     paddingTop: "6px !important",
     paddingBottom: "6px !important",
    color: "white !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
    border: "none !important",
  },
  tablebodycell: {
    fontSize: "13px !important",
    paddingLeft:'2px !important',
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    paddingRight:'2px !important',
    fontWeight: "400 !important",
    textAlign: "center !important",
    border: "none !important",
  },
  tablebodycellB: {
    fontSize: "13px !important",
    paddingLeft:'2px !important',
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
    paddingRight:'2px !important',
    fontWeight: "400 !important",
    textAlign: "center !important",
    border: "none !important",
  },
  //Billing table body cells

  tableCellH:{
    fontSize: "14px !important",
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
    color: "white !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
  },
  tablebodycellMar: {
    fontSize: "13px !important",
    padding: "9px !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
  },
  scrollableContainer: {
    display: 'flex',
    overflowX: 'auto',
  },
  // marchart table css start
  MainContainer:{
    width: "100%",
    position: "relative"
  },
  tableContainer :{
    overflowY: "auto", 
    borderRadius: "8px", 
    overflowX: "",
    "&::-webkit-scrollbar": {
      // For Chrome, Safari, and Edge
      
       },
       
      position: "relative",
      height: "calc(100vh - 388px)",
     
  },
 
 
  MarChartTableHead:{
    backgroundColor: '#279989',position: "sticky", top:0,zIndex:1000
  },
  tableCellHeader:{
    fontSize: "14px !important",
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
    color: "white !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
    position: "sticky",
    zIndex: 2,
    backgroundColor: "#279989",
    border: "none", 
  },
  medicationTextHeader:{
   position: "sticky", display: "flex", alignItems: "center", justifyContent: "center",width:'150px'
  },
  tableCellHeaderDays:{
    fontSize: "14px !important",
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
    color: "white !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
    backgroundColor: "#279889",
    border: "none",
    

  },
  tablecellBody:{
    fontSize: "13px !important",
    padding: "8px !important",
    fontWeight: "400 !important",
    textAlign: "center !important",
    backgroundColor: "#F0FCFA",
    position: "sticky",
    textAlign: "center !important",
    border: "none !important",
    zIndex: 1,
  },
  medicationText:{
  backgroundColor: "#D0F4EE", padding: "8px", borderRadius: "8px"
  },
  tablecellBodyDays:{
    textAlign: "center !important",
    backgroundColor:'#F0FCFA',
    border: "none",
    borderBottom:'none !important',
    position: "relative",
    paddingTop:'6px',
    paddingBottom:'6px',
  
    px:1
  },
  hoverButtonMainBox: {
     padding: "4px 2px",
    fontSize: "10px",
    fontWeight: "500",
    position: "relative",
    display: "inline-block",
   
  },
  hoverButton: {
    display: "none", // Initially hidden
    position: "absolute",
    top: "18px", // Below the image
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#279989",
    color: "white",
    padding: "0px 20px",
    fontSize: "0.8em",
    borderRadius: "8px 8px 0px 0px",
    width: "90px",
  
    
  },
  statusimage:{
    width: "16px", height: "16px", cursor: "pointer" 
  },
  popupBox: {
    position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        width: "34%",
        borderRadius: "20px",
        boxShadow: 24,
        display: "flex",
        flexDirection: "column",
  },
  popupHeader:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"12px",
    
  },
  popupContent:{
    display:'flex',justifyContent:'space-between',
    paddingLeft:'10px',
    paddingRight:'10px',
  },
  customTextAreaMarChart:{
    minHeight: "100px",
  },
  // marchart table css end
  datagridmain: {
    // border: 0,
    // '& .MuiDataGrid-cell': {
    //   textAlign: 'center',
    // },
    // '& .MuiDataGrid-columnHeaderTitle': {
    //   textAlign: 'center',
    // },
    // '& .MuiDataGrid-virtualScroller': {
    //   overflowY: 'auto', // Vertical scrolling for rows
    //   // height: 'calc(100vh - 226px)', // Adjust height dynamically
    // },
    // '& .MuiDataGrid-main': {
    //   overflow: 'hidden', // Prevent scrolling for the grid
    // },
    // '& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel': {
    //   margin: 0, // Remove default margins
    // },
    // '& .MuiTablePagination-root': {
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'flex-end',
    // },
  },
  tableMainContainer:{
     width: '100%', overflow: 'hidden',  borderTopLeftRadius: "10px",
     borderTopRightRadius: "10px",
     height: 'calc(100vh - 176px)', 
  },
  gridColumns:{
    display: "flex",
    flexDirection: "column", // Allow multiple lines of usernames
    justifyContent: "center", // Vertically center content
    alignItems: "center", // Horizontally center content
    textAlign: "center", // Center-align text
    height: "100%", // Occupy full cell height
    overflow: "hidden", // Prevent content overflow
    whiteSpace: "normal", // Allow text to wrap
    wordBreak: "break-word", // Break long words if necessary
    lineHeight:1.2
  },
  //Billing final table
 
  
}));

export default useStyles;
