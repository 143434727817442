import { useState, useRef, useEffect } from "react";
import React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
} from "@mui/material";
import ImageCrop from "../../common/ImageCrop";

import ProfilePhotoEdit from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEdit.svg";
import ProfilePhotoAddPhoto from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhoto.svg";
import ProfilePhotoSave from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSave.svg";
import ProfilePhotoDelete from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDelete.svg";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";

import ProfilePhotoEditHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEditHover.svg";
import ProfilePhotoAddPhotoHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhotoHover.svg";
import ProfilePhotoSaveHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSaveHover.svg";
import ProfilePhotoDeleteHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDeleteHover.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";

function IconChangePopup({
  logo,
  onClose = () => {},
  openChangeIcon = false,
  setAgencySelectedLogo,
  setFinalSelectedProfilePhoto,
  changeOrEdit,
}) {
  const [selectedLogo, setSelectedLogo] = useState(logo);
  const [selLogo, setSelLogo] = useState(logo);
  const [errorLogoText, setErrorLogoText] = useState("");
  const [onHoverAddPhoto, setOnHoverAddPhoto] = useState(false);

  const [zoomEditOpen, setZoomEditOpen] = useState(false);

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const [selectedProfileImage, setSelectedProfileImage] = useState(
    user?.profile_photo
  );

  const handleClose = () => {
    onClose();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (should be less than 5MB)
      //   const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      // const maxSizeInBytes = 100 * 1024; // 100kb
      //   if (file.size > maxSizeInBytes) {
      //     setErrorLogoText("File size must be less than 5MB.");
      //     setImageValidate(true);
      //     return;
      //   }

      // Validate file type (should be png or jpeg)
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        setErrorLogoText("Invalid file type. Upload PNG or JPEG");
        // setImageValidate(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        changeOrEdit
          ? setSelectedLogo(reader.result)
          : setSelectedProfileImage(reader.result);
        // setImage(reader.result);
        // setImageName(file.name);

        // const extension = file.name.split(".").pop();
        // const imageFileName = generateImageName(file.name, extension);
        // setImageName(imageFileName);
        setErrorLogoText(""); // Clear error message on successful upload
      };
      reader.readAsDataURL(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = null;
  };

  const getTwoUpperCaseLetters = (fullName) => {
    // if (!fullName) return "U"; // Default fallback when no name is provided (e.g., "U" for User).

    const nameParts = fullName.trim().split(" ");
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }

    // Return the first letter of the first and second name.
    return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
  };

  return (
    <Dialog
      open={openChangeIcon}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: "#279989",
            fontFamily: "Arvo",
            marginRight: "auto",
          }}
        >
          {changeOrEdit ? "Agency Logo" : "Edit Profile"}
        </Typography>
        <ProfilePhotoButton
          buttonText=""
          onClickButton={() => {
            handleClose();
            changeOrEdit
              ? setSelectedLogo(logo)
              : setSelectedProfileImage(user?.profile_photo);
          }}
          imgUrlBeforeHover={ProfilePhotoClose} // apply ProfilePhotoClose svg icon url
          imgUrlAfterHover={ProfilePhotoCloseHover} // apply ProfilePhotoCloseHover svg icon url
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: changeOrEdit ? "50%" : 150,
              height: 150,
              borderRadius: changeOrEdit ? "0%" : "50%",
              background: changeOrEdit ? null : "#269989",
              padding: changeOrEdit ? 5 : 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden", // To ensure the image is clipped to the circle
            }}
          >
            {changeOrEdit ? (
              <img
                src={selectedLogo}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : selectedProfileImage ? (
              <img
                src={selectedProfileImage}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Typography
                style={{
                  fontSize: "3rem",
                  color: "#fff", // Text color
                  fontWeight: "bold",
                }}
              >
                {getTwoUpperCaseLetters(
                  user?.first_name + " " + user?.last_name
                )}
              </Typography>
            )}
          </Box>
        </Grid>
      </DialogContent>

      {errorLogoText && (
        <Typography
          component="p"
          sx={{
            color: "red",
            textAlign: "center",
            fontFamily: "Lora",
            fontSize: "12px",
            marginTop: "-9px",
            marginBottom: "10px",
          }}
        >
          {errorLogoText}
        </Typography>
      )}
      <Divider
        sx={{
          bgcolor: "#2799894D",
          height: "2px",
        }}
      />
      <DialogActions
        sx={{
          marginBottom: "4px",
          justifyContent: "space-between",
          padding: "0",
        }}
      >
        <Grid container justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {zoomEditOpen && (
              <ImageCrop
                open={zoomEditOpen}
                setOpen={setZoomEditOpen}
                selectedImage={
                  changeOrEdit ? selectedLogo : selectedProfileImage
                }
                setImage={setSelLogo}
                setSelectedImage={
                  changeOrEdit ? setSelectedLogo : setSelectedProfileImage
                }
              />
            )}

            <ProfilePhotoButton
              isDisable={selectedLogo || selectedProfileImage ? false : true}
              buttonText="Edit"
              onClickButton={() => setZoomEditOpen(true)}
              imgUrlBeforeHover={ProfilePhotoEdit} // apply ProfilePhotoEdit svg icon url
              imgUrlAfterHover={ProfilePhotoEditHover} // apply ProfilePhotoEditHover svg icon url
            />
            <Button
              onMouseEnter={() => setOnHoverAddPhoto(true)}
              onMouseLeave={() => setOnHoverAddPhoto(false)}
              component="label" // This allows us to use the button to trigger the file input
              //   startIcon={<AddPhotoAlternateIcon />}
              sx={{
                textTransform: "none",
                color: "#969999",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Arvo",
                "&:hover": {
                  color: "#279989", // change the hover color of button
                },
              }}
            >
              <img
                alt="add icon"
                src={
                  onHoverAddPhoto
                    ? ProfilePhotoAddPhotoHover
                    : ProfilePhotoAddPhoto
                }
                style={{
                  textTransform: "none",
                  color: "#969999",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Arvo",
                  "&:hover": {
                    color: "#279989", // change the hover color of button
                  },
                }}
                // style={{
                //   width: "20px",
                //   height: "20px",
                //   gap: 1,
                //   color:"#279989"
                // }}
              />
              {changeOrEdit ? "Add Logo" : "Add Photo"}
              <input
                type="file"
                // accept="image/*"
                // accept=".jpeg,.jpg,.png" // Restrict to JPEG and PNG formats
                accept="image/jpeg,image/jpg,image/png" // Restrict to JPEG and PNG formats
                onChange={handleImageChange}
                hidden
              />
            </Button>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "10px" }}
          >
            <ProfilePhotoButton
              buttonText="Save"
              sxIcon={{ height: "16px", width: "16px", marginBottom: "2px" }}
              onClickButton={() => {
                changeOrEdit
                  ? setAgencySelectedLogo(selectedLogo)
                  : setFinalSelectedProfilePhoto(selectedProfileImage);
                // setImage(selectedLogo);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoSave} // apply ProfilePhotoSave svg icon url
              imgUrlAfterHover={ProfilePhotoSaveHover} // apply ProfilePhotoSaveHover svg icon url
            />

            <ProfilePhotoButton
              isDisable={selectedLogo ? false : true}
              buttonText="Delete"
              onClickButton={() => {
                // deleteImage();
                console.log("Logo deleted");
                setSelectedLogo(null);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoDelete} // apply ProfilePhotoDelete svg icon url
              imgUrlAfterHover={ProfilePhotoDeleteHover} // apply ProfilePhotoDeleteHover svg icon url
            />
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default IconChangePopup;

export const ProfilePhotoButton = ({
  buttonText = "",
  onClickButton = () => {},
  imgUrlBeforeHover = "", // url before hove the img
  imgUrlAfterHover = "", // url after hover imge
  styles = {},
  isDisable = false,
  sxIcon = {},
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      disabled={isDisable}
      onClick={onClickButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        textTransform: "none",
        color: "#969999",
        display: "flex",
        flexDirection: "column", // Stack the icon and text vertically
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arvo",
        "&:hover": {
          color: "#279989", // apply the hover color of button
        },
        ...styles,
      }}
    >
      {/* change th margin, heights, width, as per requirements in styles of img */}

      <img
        alt="profile icon"
        src={isHover ? imgUrlAfterHover : imgUrlBeforeHover}
        style={{
          width: "20px",
          height: "20px",
          gap: 1,
          opacity: isDisable ? 0.5 : 1,
          ...sxIcon,
        }}
      />

      {buttonText}
    </Button>
  );
};
