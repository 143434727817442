import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { RadioInput, Dropdown, Calendar } from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";
import CancelVisit from "../../../assets/images/clients/Visits/cancelvisit.svg";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function HangingTodoMedicationDialog({
  openDialog,
  todos,
  medications,
  visits,
  setRefreshVisits,
  handleCloseDialog = () => {},
}) {
  const extractedTodos = todos.map(({ todo_details_id }) => ({
    todoDId: todo_details_id,
    visitDId: "",
    group: "",
  }));

  const [todo, setTodo] = useState(extractedTodos);

  const extractedMedications = medications.map(
    ({ medication_id, medication_type, blister_pack_id, scheduled_id }) => ({
      medicationDId: medication_id,
      visitDId: "",
      group: "",
      medication_type: medication_type,
      blister_pack_id: blister_pack_id ? blister_pack_id : 0,
      scheduled_id: scheduled_id ? scheduled_id : 0,
    })
  );

  const [medication, setMedication] = useState(extractedMedications);

  // Example: Setting `todoId` when selecting the first todo item

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };
  const handleTodoCheckboxChange = (event, todoId, visitId, group) => {
    console.log(event.target.checked, todoId, visitId, group);

    setTodo((prev) =>
      prev.map((item) => {
        if (item.todoDId === todoId) {
          // Toggle the `visitDId` and `group` values based on the checkbox state
          const updatedVisitDId = event.target.checked ? visitId : "";
          const updatedGroup = event.target.checked ? group : "";
          console.log("item", item, updatedVisitDId, updatedGroup);
          return { ...item, visitDId: updatedVisitDId, group: updatedGroup };
        }
        return item; // Return unchanged items
      })
    );
  };

  const TisChecked = (todoId, visitId, group) => {
    const todoItem = todo.find((item) => item.todoDId === todoId);

    return (
      todoItem && todoItem.group === group && todoItem.visitDId === visitId
    );
  };

  const handleMedicationCheckboxChange = (
    event,
    medicationId,
    visitId,
    group
  ) => {
    console.log(event.target.checked, medicationId, visitId, group);

    setMedication((prev) =>
      prev.map((item) => {
        if (item.medicationDId === medicationId) {
          // Toggle the `visitDId` and `group` values based on the checkbox state
          const updatedVisitDId = event.target.checked ? visitId : "";
          const updatedGroup = event.target.checked ? group : "";
          console.log("item", item, updatedVisitDId, updatedGroup);
          return { ...item, visitDId: updatedVisitDId, group: updatedGroup };
        }
        //console.log("item", item);
        return item; // Return unchanged items
      })
    );
  };

  const MisChecked = (medicationId, visitId, group) => {
    const medicationItem = medication.find(
      (item) => item.medicationDId === medicationId
    );

    return (
      medicationItem &&
      medicationItem.group === group &&
      medicationItem.visitDId === visitId
    );
  };

  const postHangingTodosLinked = async () => {
    try {
      const filteredTodos = todo.filter((item) => item.group !== "");

      // Map to create the desired structure
      const list = filteredTodos.map((item) => ({
        todo_details_id: item.todoDId, // Corrected property name
        visit_details_id: item.visitDId,
        group: item.group,
      }));
      const body = { list };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/todo-add-group-list`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
        //handlestep();
      } else {
        console.log("Failed to set todo's for selected visits");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const postHangingMedicationsLinked = async () => {
    try {
      const filteredMedications = medication.filter(
        (item) => item.group !== ""
      );

      // Map to create the desired structure
      const list = filteredMedications.map((item) => ({
        blister_pack_details_id: item.blister_pack_id,
        scheduled_details_id: item.scheduled_id,
        visit_details_id: item.visitDId,
        group: item.group,
      }));
      const body = { list };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/medication-add-group-list`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
        //handlestep();
      } else {
        console.log("Failed to set medication's for selected visits");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onConfirmFun = () => {
    {
      console.log("updated Todos's ", todo);
    }

    {
      console.log("updated Medication's ", medication);
    }
    postHangingTodosLinked();
    postHangingMedicationsLinked();
    const min = 1;
    const max = 100;
    const generatedNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setRefreshVisits(generatedNumber);
    handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "60%", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
            >
              Hanging Todo's and Medication's
            </Typography>
          </Box>
          <Grid container direction="column">
            {/* {console.log("Hanging ", todos)} */}

            {todos.map((todo) => {
              return (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      borderRadius: "8px",
                      backgroundColor: "#E3F2F0",
                      width: "45%",
                      height: "60px",
                      margin: "10px 0",
                      padding: "5px 10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ marginBottom: "10px", textAlign: "Left" }}>
                      <Typography variant="body4">
                        {todo.todo_description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "5%",
                      borderTop: "1px dotted  #269AB9",
                      height: "1px",
                      marginLeft: "1px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "70%",
                      margin: "20px 0",
                      //paddingLeft: "80px",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      "&::before": {
                        content: '""', // This pseudo-element creates the border
                        position: "absolute",
                        left: 0,
                        top: "22.5px", // Leave 20px space from the top
                        bottom: "22.5px", // Leave 20px space from the bottom
                        width: "1px", // No width, only a vertical border
                        borderLeft: "1px dotted #269AB9", // Dotted line style
                      },
                      paddingLeft: "1.5px",
                      //margin: "5px 0",
                      //alignItems: "end",
                    }}
                  >
                    {visits.map((visit) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "5px 0",
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              borderTop: "1px dotted  #269AB9",
                              height: "1px",
                            }}
                          ></Box>

                          <Box
                            sx={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "410px",
                              //border: "1px solid #2799894d",
                              borderRadius: "8px",
                              padding: "0 10px",
                            }}
                          >
                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              {visit.planned_start_time} -{" "}
                              {visit.planned_end_time}
                            </Typography>
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                handleTodoCheckboxChange(
                                  event,
                                  todo.todo_details_id,
                                  visit.visit_details_id,
                                  0
                                )
                              }
                              checked={TisChecked(
                                todo.todo_details_id,
                                visit.visit_details_id,
                                0
                              )}
                              disabled={TisChecked(
                                todo.todo_details_id,
                                visit.visit_details_id,
                                1
                              )} // Disable if the other checkbox is selected
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />

                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              This visit only
                            </Typography>
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                handleTodoCheckboxChange(
                                  event,
                                  todo.todo_details_id,
                                  visit.visit_details_id,
                                  1
                                )
                              }
                              checked={TisChecked(
                                todo.todo_details_id,
                                visit.visit_details_id,
                                1
                              )}
                              disabled={TisChecked(
                                todo.todo_details_id,
                                visit.visit_details_id,
                                0
                              )} // Disable if the other checkbox is selected
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              All {visit.session_type} visits
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
            {medications.map((medication) => {
              return (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      borderRadius: "8px",
                      backgroundColor: "#E3F2F0",
                      width: "45%",
                      height: "60px",
                      margin: "10px 0",
                      padding: "5px 10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ marginBottom: "10px", textAlign: "Left" }}>
                      <Typography variant="body4">
                        {medication.nhs_medicine_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "5%",
                      borderTop: "1px dotted  #269AB9",
                      height: "1px",
                      marginLeft: "1px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "70%",
                      margin: "20px 0",
                      //paddingLeft: "80px",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      "&::before": {
                        content: '""', // This pseudo-element creates the border
                        position: "absolute",
                        left: 0,
                        top: "22.5px", // Leave 20px space from the top
                        bottom: "22.5px", // Leave 20px space from the bottom
                        width: "1px", // No width, only a vertical border
                        borderLeft: "1px dotted #269AB9", // Dotted line style
                      },
                      paddingLeft: "1.5px",
                      //margin: "5px 0",
                      //alignItems: "end",
                    }}
                  >
                    {visits.map((visit) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "5px 0",
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              borderTop: "1px dotted  #269AB9",
                              height: "1px",
                            }}
                          ></Box>

                          <Box
                            sx={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "410px",
                              //border: "1px solid #2799894d",
                              borderRadius: "8px",
                              padding: "0 10px",
                            }}
                          >
                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              {visit.planned_start_time} -{" "}
                              {visit.planned_end_time}
                            </Typography>
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                handleMedicationCheckboxChange(
                                  event,
                                  medication.medication_id,
                                  visit.visit_details_id,
                                  0
                                )
                              }
                              checked={MisChecked(
                                medication.medication_id,
                                visit.visit_details_id,
                                0
                              )}
                              disabled={MisChecked(
                                medication.medication_id,
                                visit.visit_details_id,
                                1
                              )} // Disable if the other checkbox is selected
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />

                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              This visit only
                            </Typography>
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                handleMedicationCheckboxChange(
                                  event,
                                  medication.medication_id,
                                  visit.visit_details_id,
                                  1
                                )
                              }
                              checked={MisChecked(
                                medication.medication_id,
                                visit.visit_details_id,
                                1
                              )}
                              disabled={MisChecked(
                                medication.medication_id,
                                visit.visit_details_id,
                                0
                              )} // Disable if the other checkbox is selected
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                            <Typography
                              sx={{
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                maxWidth: "100%", // Adjust based on container width
                              }}
                              variant="body4"
                            >
                              All {visit.session_type} visits
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
