import { useState, useRef, useEffect } from "react";
import { Typography } from "@mui/material";
import EnhancedTable from "./userTable/UserTable";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CustomButtom from "../../components/reusableComponents/CustomButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import TopContentContainer from "../../components/reusableComponents/TopContentContainer";
import ContentContainer from "../../components/reusableComponents/ContentContainer";
import { getCookie } from "../../utils/utils";
import CareboxLogo from "../../assets/images/users/logo.jpg";
import agencyLogo from "../../assets/images/users/agency2.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";
const doc = new jsPDF();
// import Input from "@mui/material/Input";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import UserFormView from "./UserFormView";

export default function UserDashBoard() {
  const [usersList, setUsersList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedIDS, setSelectedIDS] = useState([]);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const styles = {
    tableCell: {
      backgroundColor: "#279989",
      zIndex: 1,
    },
    paragraph: {
      color: "#FFF",
      padding: "0px",
      position: "relative",
    },
  };
  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/user`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok === true) {
          setUsersList(data.data);
          setFilteredList(data.data);
        } else {
          console.log("get all failed");
          setUsersList([]);
          setFilteredList([]);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchUsersAPI();
  }, []);

  const toggleIDsInSelectedIDS = (id) => {
    if (selectedIDS.includes(id)) {
      setSelectedIDS(selectedIDS.filter((each) => each !== id));
    } else {
      setSelectedIDS([...selectedIDS, id]);
    }
  };

  const toggleAllIDs = (checked) => {
    setSelectedIDS(checked === true ? usersList.map((each) => each.id) : []);
  };

  const exportUsersPdf = () => {
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, width, height, "F");
    doc.addImage(CareboxLogo, "JPEG", 15, 10, 34, 15);
    doc.addImage(agencyLogo, "JPEG", 170, 10, 21, 21);
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`Users List`, 75, 25);
    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toJSON().slice(0, 10);
    doc.text(`Date: ${today}`, 15, 30);
    const finalUsers = usersList.filter((u) => selectedIDS.includes(u.id));
    var body = [
      ...finalUsers.map((d) => [
        `${d.prefix} ${d.first_name} ${d.middle_name} ${d.last_name}`,
        d.email,
        `${d.country_code} ${d.contact_number}`,
        d.admin === 1 ? "Yes" : "No",
        d.status === 1 ? "Active" : "Inactive",
      ]),
    ];
    doc.autoTable({
      head: [["Name", "Email", "Contact", "Admin", "Status"]],
      body: body,
      startY: 35,
      borderRadius: "10px",
      headStyles: {
        fillColor: "#279989",
        fontStyle: "Courier",
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      didParseCell: (data) => {
        const ind = data.column.index;
        switch (ind) {
          case 0:
            data.cell.styles.cellWidth = 50;
            data.cell.styles.valign = "top";
            break;
          case 1:
            data.cell.styles.cellWidth = 50;
            data.cell.styles.valign = "top";
            break;
          case 2:
            data.cell.styles.halign = "center";
            break;
          case 3:
            data.cell.styles.halign = "center";
            break;
          case 4:
            data.cell.styles.halign = "center";
            if (data.cell.raw === "Active") {
              data.cell.styles.textColor = [36, 138, 61];
            }
            if (data.cell.raw === "Inactive") {
              data.cell.styles.textColor = [255, 105, 97];
            }
            break;
          default:
            break;
        }
      },
    });
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`users_${today}.pdf`);
  };

  return (
    <Box>
      <TopContentContainer>
        <Typography
          //component="h1"
          variant="h5"
          sx={{
            color: "#279989",
            padding: "0px",
          }}
        >
          Users
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginLeft: "20px",
            flex: 1,
          }}
        >
          <TextField
            onChange={(e) => {
              // lowering the text value
              let value = e.target.value.toLowerCase();
              // checking the text value includes in fname, mname, lname, phone number, email
              const filtData = usersList.filter((each) => {
                return (
                  each.first_name.toLowerCase().includes(value) ||
                  each.middle_name.toLowerCase().includes(value) ||
                  each.last_name.toLowerCase().includes(value) ||
                  each.contact_number.toLowerCase().includes(value) ||
                  each.email.toLowerCase().includes(value)
                );
              });

              setFilteredList(filtData);
            }}
            size="small"
            placeholder="Search"
            sx={{
              width: "60%",
              maxWidth: "250px",
              margin: "0px",
              marginRight: "20px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .MuiInputBase-input": {
                MarginLeft: "-5px",
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color: "#279989",
                      width: "20px",
                      marginLeft: "10px",
                      padding: 0,
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <Typography variant="h6" sx={styles.paragraph}>
            <div
              style={{ margin: "0px 20px" }}
              onClick={() => setIsAdminOpen(!isAdminOpen)}
              // isDec={false}
              // imgUrl={incrementIcon}
            >
              <CustomButtom text="Export" disabled={selectedIDS.length <= 0} />
            </div>
            {isAdminOpen === true && selectedIDS.length > 0 ? (
              <TablePopup
                exportUsersPdf={exportUsersPdf}
                // exportUsersExcel={exportUsersExcel}
                onClose={() => setIsAdminOpen(false)}
                usersList={usersList}
                selectedIDS={selectedIDS}
              />
            ) : null}
          </Typography> */}
          <Link to="/users/add-user">
            <CustomButtom text="+ Add User" />
          </Link>
        </div>
      </TopContentContainer>

      <ContentContainer isTransparentBg={false}>
        <EnhancedTable
          usersList={filteredList}
          toggleIDsInSelectedIDS={toggleIDsInSelectedIDS}
          toggleAllIDs={toggleAllIDs}
          selectedIDS={selectedIDS}
        />
      </ContentContainer>
    </Box>
  );
}

export const TablePopup = ({
  exportUsersPdf,
  // exportUsersExcel,
  usersList,
  selectedIDS,
  onClose = () => {},
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!popupRef.current.contains(event.target)) onClose();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  const styles = {
    tabWrapper: {
      border: "1px solid #27998966",
      background: "white",
      borderRadius: "8px",
      position: "absolute",
      minWidth: "75px",
      zIndex: 99,
      bottom: "0",
      transform: "translate(-50%, 105%)",
      left: "50%",
      padding: "2px ",
    },
    tab1: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      fontSize: "14px",
      padding: "4px 0px 4px 15px",
      margin: "0px",
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "8px", // Add left space to the border
        right: "8px", // Add right space to the border
        height: "0.5px",
        backgroundColor: "rgba(39, 153, 137, 0.2)",
      },
      "&:hover": { color: "#279989" },
    },
    tab2: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      fontSize: "14px",
      padding: "4px 0px 4px 15px",
      margin: "0px",
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "8px", // Add left space to the border
        right: "8px", // Add right space to the border
        // height: "0.5px",
        backgroundColor: "rgba(39, 153, 137, 0.2)",
      },
      "&:hover": { color: "#279989" },
    },
  };
  const [dynamicUrl, setDynamicUrl] = useState("");
  const token = getCookie();
  const finalUsers = usersList
    .filter((u) => selectedIDS.includes(u.id))
    .map((u) => u.id);
  const URL = `${process.env.REACT_APP_BASE_URL}/user/export/excel?id=${finalUsers}&token=${token}`;

  useEffect(() => {
    const newUrl = URL;
    setDynamicUrl(newUrl);
  }, [URL]);
  const exportUsersExcel = () => {
    if (dynamicUrl) {
      window.open(dynamicUrl, "_blank");
    }
  };
  return (
    <Box ref={popupRef} sx={styles.tabWrapper}>
      <Typography onClick={exportUsersPdf} variant="body0" sx={styles.tab1}>
        PDF
      </Typography>
      <Typography onClick={exportUsersExcel} variant="body0" sx={styles.tab2}>
        Excel
      </Typography>
    </Box>
  );
};
