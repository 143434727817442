import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { getCookie } from '../../../utils/utils';
import useStyles from '../../clients/styles/useStyles';
import CustomButtom from '../../reusableComponents/CustomButton';
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fontSize } from '@mui/system';
import Loader from '../Loader';
const doc = new jsPDF();
// const doc = new jsPDF({
//   orientation: "landscape", // Wide orientation
//   unit: "mm",
//   format: [420, 297], // Custom width and height
// });


const columns = [
  // { field: 'id', headerName: 'ID',  headerAlign: 'center', align: 'center' },
  { field: 'userName', headerName: 'User Name',  headerAlign: 'center', align: 'center' },
  { field: 'email', headerName: 'Email',  headerAlign: 'center', align: 'center' },
  { field: 'contactNumber', headerName: 'Phone Number',  headerAlign: 'center', align: 'center' },
  { field: 'dob', headerName: 'Date of Birth', headerAlign: 'center', align: 'center' },
  { field: 'gender', headerName: 'Gender',  headerAlign: 'center', align: 'center' },
  { field: 'status', headerName: 'Status',  headerAlign: 'center', align: 'center' },
  {field: 'admin', headerName: 'Admin',  headerAlign: 'center', align: 'center' },
  {field: 'address', headerName: 'Address',  headerAlign: 'center', align: 'center' },
  {field: 'dbsNo', headerName: 'Dbs No',  headerAlign: 'center', align: 'center' },
  {field: 'dbsExpiryDate', headerName: 'Dbs Expiry Date',  headerAlign: 'center', align: 'center' },
  {field: 'drive', headerName: 'Drive',  headerAlign: 'center', align: 'center' },
  {field: 'employmentStartDate', headerName: 'Employment Start Date',  headerAlign: 'center', align: 'center' },
  {field: 'employmentEndDate', headerName: 'Employment End Date',  headerAlign: 'center', align: 'center' },
  {field: 'specialist', headerName: 'Specialist',  headerAlign: 'center', align: 'center' },
  {field: 'hourlyRate', headerName: 'Hourly Rate', headerAlign: 'center', align: 'center' },
  {field: 'payPerMile', headerName: 'Pay Per Mile',  headerAlign: 'center', align: 'center' },
  {field: 'niNumber', headerName: 'Ni Number',  headerAlign: 'center', align: 'center' },
];

const paginationModel = { page: 0, pageSize: 20 };

function UserReports() {
  const [userReports , setUserReports] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
   const classes = useStyles()
  useEffect(()=>{
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = 'https://3.8.158.142:8443/api/getreportuserdetails';
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
      console.log(data.data,'==============================================');
  
        // setmedicationroute(transformedData);
      
        if (response.ok === true) {
          setUserReports(data.data);
        } else {
          console.log("get all failed");  
          setUserReports([]);
        }
      } catch (e) {
        console.log("error", e.message);
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of success or failure
      }
    };
    fetchUsersAPI();
  },[])
  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      console.log(rowSelectionModel);
    }
  },[rowSelectionModel]);
  if(loading){
    return(
      <Loader />
    )}
  const rows = userReports.map((user, index) => ({
    id: index + 1,
    userName: user.userName,
    email: user.email,
    contactNumber: user.contactNumber,
    dob: user.dob,
    gender: user.gender,
    status: user.status,
    admin: user.admin,
    address: user.address,
    dbsNo: user.dbsNo,
    dbsExpiryDate: user.dbsExpiryDate,
    drive: user.drive,
    employmentStartDate: user.employmentStartDate,
    employmentEndDate: user.employmentEndDate,
    specialist: user.specialist,
    hourlyRate: user.hourlyRate,
    payPerMile: user.payPerMile,
    niNumber: user.niNumber
  }));
  const adjustedColumns = columns.map((col) => {
    const maxColumnWidth = Math.max(
      col.headerName.length * 9 + 1, // Header text width
      ...rows.map((row) => String(row[col.field]).length * 8 + 1) // Row text width
    );
    return {
      ...col,
      width: maxColumnWidth,
      minWidth: 70, // Set a minimum width to avoid extremely narrow columns
    };
  });
  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 3; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo
  
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(CareboxLogo, "JPEG", tableMargin, imageYPosition, logoWidthLeft, imageHeight); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`UserOverView Reports`, 75, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
  
    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 15, 30);
  
    const clientColumns = [
      "userName",
      "email",
      "Dob",
      "gender",
      "status",
      "admin", 
    ];
  
    const finalClients = rows.filter((u) => rowSelectionModel.includes(u.id));
    const body = 
        finalClients.map((d) => {
        return [
        d.userName,
        d.email,
        d.dob,
        d.gender,
        d.status,
        d.admin,
    ]})
    
  
    doc.autoTable({
      head: [clientColumns],
      body: body,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 14,
        halign: "center",
        cellPadding: { top: 2, right: 1.1, bottom: 5.8, left: 1.1 }
      },
      styles: {
        fontSize: 12,
        halign: "center", // Default horizontal alignment
        valign: "center", // Default vertical alignment
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
     cellPadding: { top: 5.4, right: 1.5, bottom: 5.4, left: 1.5 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw; // The cell's raw content
        if (typeof cellContent === "string" || typeof cellContent === "number") {
          const cellWidth = data.cell.width;
          const wrappedText = doc.splitTextToSize(cellContent.toString(), cellWidth); // Wrap text to fit cell width
          if (wrappedText.length > 1) {
            data.cell.text = wrappedText; // Replace the cell's text with wrapped text
            data.cell.styles.valign = "middle"; // Ensure vertical alignment remains centered
            data.cell.styles.halign = "center"; // Ensure horizontal alignment remains centered
          }
        }
      },
    });
  
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`clients_${today}.pdf`);
  };
  
  return (
    <Box sx={{ px: 3,pt:4}}>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",px:2,pb:3,alignItems: "center" }}>
      <Typography variant="h5"  sx={{p:0,m:0,color:"#279989"}}>
        Users Reports
      </Typography>
      <CustomButtom text="Download" onClick={exportClientsPdf} disabled={rowSelectionModel.length === 0} />
      </Box>
      <Box className={classes.tableMainContainer} >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40,]}
          className={classes.datagridmain}
          checkboxSelection  // Enable checkbox selection
         
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
       
      </Box>
  
      
    </Box>
  );
}

export default UserReports;

// sx={{
//   border: 0,
//   '& .MuiDataGrid-cell': {
//     textAlign: 'center',
//   },
//   '& .MuiDataGrid-columnHeaderTitle': {
//     textAlign: 'center',
//   },
//   '& .MuiDataGrid-virtualScroller': {
//     overflowY: 'auto', // Vertical scrolling for rows only
//     height: 'calc(100vh - 220px)', // Set a fixed height for the rows
//   },
//   '& .MuiDataGrid-main': {
//     overflow: 'hidden', // Prevent scrolling for the entire grid
//   },
//   '& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel': {
//   margin: 0, // Remove default margins on <p> tags
// },
// '& .MuiTablePagination-root': {
//   display: 'flex',
//   alignItems: 'center', // Ensure vertical alignment
//   justifyContent: 'flex-end', // Ensure horizontal alignment
// },                   
// }}