import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "../../clients/styles/useStyles";
import UserTableView from "../../../assets/images/userTableIcons/UserTableView.svg";
import UserTableViewHover from "../../../assets/images/userTableIcons/UserTableViewHover.svg";
import hoverDeleteBilling from "../../../assets/images/userTableIcons/hoverdelete.png";
import deleteBilling from "../../../assets/images/userTableIcons/viewdelete.png";
import hoverDownload from "../../../assets/images/userTableIcons/hoverdownload.png";
import viewDownload from "../../../assets/images/userTableIcons/viewdownload.png";
import { Status3 } from "../../users/userTable/Components";
import ClientInvoiceView from "./ClientInvoiceView";
import CustomCalendar from "../../logs/CustomCalendar";
import dayjs from "dayjs";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import jsPDF from "jspdf";
import DeleteConfirmationIcon from "../../../assets/images/roster/deleteInvoice.svg";
const paginationModel = { page: 0, pageSize: 20 };
const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "26px",
        height: "26px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};
function ClientInVoices() {
  const [billingData, setBillingData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedToView, setSelectedToView] = useState([]);
  const [selectedToDownload, setSelectedToDownload] = useState(null);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [modalOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [filteredData, setFilteredData] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const classes = useStyles();
  const fetchUsersAPI = async () => {
    try {
      const token = getCookie();
      const URL = "https://3.8.158.142:8443/api/getClientInvoice";
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setBillingData(data.data);
      } else {
        console.error("API call failed");
        setBillingData([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  const filterBillingData = () => {
    const filtered = billingData?.filter((billing) => {
      const minVisitDate = new Date(billing?.minVisitDate);
      const maxVisitDate = new Date(billing?.maxVisitDate);
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Check if the date ranges overlap
      return minVisitDate <= end && maxVisitDate >= start;
    });

    setFilteredData(filtered);
  };
  useEffect(() => {
    fetchUsersAPI();
  }, []);
  useEffect(() => {
    filterBillingData();
  }, [billingData, startDate, endDate]);
  const rows = filteredData?.map((billing, index) => ({
    id: index,
    invoiceNo: billing?.invoiceNo,
    clientName: billing?.clientName,
    //dayjs(DueDate).format("DD-MM-YYYY")
    minVisitDate: `${dayjs(billing?.minVisitDate).format("DD-MM-YYYY")} - ${dayjs(billing?.maxVisitDate).format("DD-MM-YYYY")}`, 
    invoiceCreatedDate: billing?.invoiceCreatedDate,
    invoiceDueDate: billing?.invoiceDueDate,
    totalActualTime: billing?.totalPlannedTime,
    totalActualTimePay: billing?.totalPlannedTimePay,
    billingStatus: billing?.billingStatus,
    action: null,
  }));
  const handleViewClick = async (row) => {
    console.log(row.invoiceNo);
    try {
      const token = getCookie();
      const URL = `https://3.8.158.142:8443/api/getInvoiceDetails?invoice_no=${row.invoiceNo}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setSelectedToView(data.data);
      } else {
        console.error("API call failed");
        setSelectedToView([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
    setModalIsOpen(true);
    console.log("handleview", selectedToView);
  };
  const handleDownload = async (row) => {
    try {
      const token = getCookie();
      const URL = `https://3.8.158.142:8443/api/getInvoiceDetails?invoice_no=${row.invoiceNo}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setSelectedToView(data.data);
        // setSelectedToDownload(row);
        downloadPDF();
      } else {
        console.error("API call failed");
        setSelectedToView([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }

    console.log("download");
  };
  const handleDelete = (row) => {
    console.log("delete");
    setConfirmationDialogOpen(true);
    // confirmDeleteInvoice(row.invoiceNo);
    setSelectedToDelete(row?.invoiceNo);
  };
  const confirmDeleteInvoice = async () => {
    console.log(selectedToDelete);
    try {
      const URL = `https://3.8.158.142:8443/api/deleteInvoice?invoice_no=${selectedToDelete}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        fetchUsersAPI();
        handleDeleteConfirmationClose();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteConfirmationClose = () => setConfirmationDialogOpen(false);
  const adjustedColumns = [
    {
      field: "invoiceNo",
      headerName: "INV-ID",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 110,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      headerAlign: "center",
      align: "center",
      flex: 1.6,
      minWidth: 110,
    },
    {
      field: "minVisitDate",
      headerName: "Date Range",
      headerAlign: "center",
      align: "center",
      flex: 1.8,
      minWidth: 110,
    },
    {
      field: "invoiceCreatedDate",
      headerName: "Created On",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "invoiceDueDate",
      headerName: "Due Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "totalActualTime",
      headerName: "Total Hours",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "totalActualTimePay",
      headerName: "Total Pay",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },

    {
      field: "billingStatus",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        // Convert value to lowercase for case-insensitive comparison
        const statusValue = params.value === "Invoiced" ? 1 : 0;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start", // Align content to the top
              justifyContent: "center",
              height: "100%", // Ensure it takes the full height of the cell
              padding: "0 4px",
            }}
          >
            <Status3 status={statusValue}></Status3>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Box onClick={() => handleViewClick(params?.row)}>
            <HoverImg
              imgBeforeHover={UserTableView}
              imgAfterHover={UserTableViewHover}
            />
          </Box>
          <Box onClick={() => handleDownload(params?.row)}>
            <HoverImg
              imgBeforeHover={viewDownload}
              imgAfterHover={hoverDownload}
            />
          </Box>
          <Box onClick={() => handleDelete(params?.row)}>
            <HoverImg
              imgBeforeHover={deleteBilling}
              imgAfterHover={hoverDeleteBilling}
            />
          </Box>
        </Box>
      ),
    },
  ];
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const calculateTimeDifference = (startTime, endTime) => {
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const diff = Math.abs(endDate - startDate); // Difference in milliseconds
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Convert to hours
    const minutes = Math.floor((diff / (1000 * 60)) % 60); // Convert to minutes

    return `${hours.toString().padStart(2, "0")} hrs ${minutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const calculateTotalTime = (items) => {
    let totalMinutes = 0;

    items.forEach((item) => {
      const [startHour, startMinute] = item.plannedStartTime
        ?.split(":")
        .map(Number);
      const [endHour, endMinute] = item.plannedEndTime?.split(":").map(Number);

      const startDate = new Date();
      const endDate = new Date();

      startDate.setHours(startHour, startMinute);
      endDate.setHours(endHour, endMinute);

      totalMinutes += Math.abs((endDate - startDate) / (1000 * 60)); // Total minutes
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours?.toString().padStart(2, "0")} hrs ${remainingMinutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const minDate = selectedToView[0]?.minVisitDate; // Likely a string or Date object
  const maxDate = selectedToView[0]?.maxVisitDate;
  const inVoiceDate = selectedToView[0]?.invoiceCreatedDate;
  const DueDate = selectedToView[0]?.invoiceDueDate;
  const finalMinDate = minDate ? dayjs(minDate).format("DD-MM-YYYY") : null;
  const finalMaxDate = maxDate ? dayjs(maxDate).format("DD-MM-YYYY") : null;
  const finalinVoiceDate = maxDate ? dayjs(inVoiceDate).format("DD-MM-YYYY"): null;
  const finalDueDate = maxDate ? dayjs(DueDate).format("DD-MM-YYYY") : null;
  const downloadPDF = () => {
    const doc = new jsPDF();
    // Adding the Logo
    doc.addImage(CareboxLogo, "JPEG", 5, 10, 50, 20);
    doc.setFontSize(12);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 10, 40);

    // Adjust the x position to move everything to the right
    const invoiceStartX = 88; // Increased x value to move invoice boxes to the right
    const invoiceStartY = 10; // Keep the same gap as before

    // Aligning Invoice and Payment Information in Flex (single line)
    const invoiceBoxWidth = 80; // Adjusted width for the invoice box
    const paymentBoxWidth = 36; // Adjusted width for the payment box
    const transparency = 0.2;
    // Adding the invoice information with background color
    doc.setFillColor(39, 153, 137); // Color for invoice box
    doc.setGState(new doc.GState({ opacity: transparency }));
    doc.rect(invoiceStartX, invoiceStartY, invoiceBoxWidth, 30, "F"); // Invoice box with background color
    doc.setGState(new doc.GState({ opacity: 1 }));

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold"); // Use bold font
    doc.text(
      `Invoice: ${selectedToView[0]?.invoiceNo}`,
      invoiceStartX + 5,
      invoiceStartY + 8
    );
    doc.text(
      `Invoice Date: ${finalinVoiceDate}`,invoiceStartX + 5,invoiceStartY + 16);
      doc.text(`Invoice Due Date: ${finalDueDate}`,invoiceStartX + 5,invoiceStartY + 24);

    // Adding Payment Information in a separate box with no background color
    doc.setFillColor(39, 153, 137); // White color for the payment box (no background)
    doc.rect(invoiceStartX + invoiceBoxWidth,invoiceStartY,paymentBoxWidth,30,"F"); // Payment box to the right of the invoice box

    doc.setTextColor(255, 255, 255);
    const total = `£ ${selectedToView?.reduce((sum, item) => sum + item.totalPlannedTimePay,0)}`;
    doc.text("TOTAL INVOICE",invoiceStartX + invoiceBoxWidth + 2,invoiceStartY + 10 );
    doc.setFontSize(20);
    doc.text(total, invoiceStartX + invoiceBoxWidth + 2, invoiceStartY + 20);
    // Address Boxes with increased height and same width
    const addressBoxWidth = 97;
    const addressBoxHeight = 66;
    function drawRoundedRect(doc, x, y, width, height, radius) {
      doc.roundedRect(x, y, width, height, radius, radius, "F"); // "F" for fill
    }

    // Left address box
    doc.setFillColor(243, 250, 249); // Light grey background
    drawRoundedRect(doc,5,invoiceStartY + 40,addressBoxWidth,addressBoxHeight,3);
    // Right address box
    doc.setFillColor(243, 250, 249); // Light grey background
    drawRoundedRect(doc,5 + addressBoxWidth + 5,invoiceStartY + 40,addressBoxWidth,addressBoxHeight,3);

    // Address details for left box
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold"); // Use bold font
    doc.text("Payment Collected by:", 5 + 5, invoiceStartY + 50);
    doc.text("Lifeline Carers Pvt. Ltd", 5 + 5, invoiceStartY + 60);
    doc.setFont("helvetica", "normal"); // Use bold font
    doc.text("The Watermark, 9-15, Ribbleton Lane", 5 + 5, invoiceStartY + 70);
    doc.text("Preston, Lancashire, PR1 5EZ", 5 + 5, invoiceStartY + 80);
    doc.setFont("helvetica", "bold");
    doc.text("accounts@lifelinecarers.co.uk", 5 + 5, invoiceStartY + 90);
    doc.text("+44 (0) 7755443321", 5 + 5, invoiceStartY + 100);

    // Address details for right box
    doc.text("Invoice to:", 5 + addressBoxWidth + 15, invoiceStartY + 50);
    doc.text(
      "Lancashire Council",
      5 + addressBoxWidth + 15,
      invoiceStartY + 60
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "The Watermark, 9-15, Ribbleton Lane",
      5 + addressBoxWidth + 15,
      invoiceStartY + 70
    );
    doc.text(
      "Preston, Lancashire, PR1 5EZ",
      5 + addressBoxWidth + 15,
      invoiceStartY + 80
    );
    doc.setFont("helvetica", "bold");
    doc.text("Maryk Aguirre", 5 + addressBoxWidth + 15, invoiceStartY + 90);
    // Adding Gap Above Address Boxes
    doc.setFontSize(16);
    doc.text(
      `Invoice from ${finalMinDate} to ${finalMaxDate}`,
      46,
      invoiceStartY + 119
    );
    // Adding Table
    doc.setFontSize(10);
    const headers = [
      "VisitDate",
      "Session",
      "Planned Time",
      "Users",
      "Hours Rate",
      "Total Pay",
    ];
    const rows = selectedToView?.map((item) => {
      const timeDifference = calculateTimeDifference(
        item?.plannedStartTime,
        item?.plannedEndTime
      );

      return [
        item?.visitDate,
        item?.sessionType,
        `${item?.plannedStartTime} - ${item?.plannedEndTime} \n ${timeDifference}`,
        item?.userCount,
        item?.hourlyPay,
        item?.totalPlannedTimePay,
      ];
    });

    doc.autoTable({
      head: [headers],
      body: rows,
      startY: invoiceStartY + 128, // Adjusted to start after the invoice date and address info
      margin: { left: 5, right: 5 }, // Small gaps from the start and end
      tableWidth: "auto", // Automatically adjust table width to fit within the page width
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: "#279989",
        textColor: "#FFFFFF",
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 },
      }, // Centering the header cells
      bodyStyles: {
        halign: "center",
        valign: "middle",
        cellPadding: { top: 4, right: 5, bottom: 4, left: 5 },
      }, // Centering the body cells
      alternateRowStyles: { fillColor: "#EEFEFA" },
    });

    // Adding the total row at the end
    const addressBoxWidthTotal = 100;
    const addressBoxHeightTotal = 8;
    doc.setFillColor(39, 153, 137);
    doc.rect(105,doc.lastAutoTable.finalY + 10,addressBoxWidthTotal,addressBoxHeightTotal,"F");
    doc.setTextColor(255, 255, 255);
    doc.text(calculateTotalTime(selectedToView),113,doc.lastAutoTable.finalY + 15); // Position (x: 60, y: doc.lastAutoTable.finalY + 15)
    doc.text(total, 170, doc.lastAutoTable.finalY + 15);

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text( `Page ${i} of ${pageCount}`, doc.internal.pageSize.width / 2, 287,{ align: "center" });
    }

    // Saving the PDF
    doc.save("Invoice.pdf");
  };

  return (
    <Box sx={{ px: 3, py: 2, width: "100%" }}>
      <Box sx={{ width: "35%" }}>
        <CustomCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
      <Box
        className={classes.tableMainContainer}
        sx={{ height: "calc(100vh - 260px) !important" }}
      >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
          // checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
        />
      </Box>
      <Modal open={modalOpen}>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            width: "55%",
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "8px",
            boxShadow: 24,
            top: 35,
          }}
        >
          <ClientInvoiceView
            selectedToView={selectedToView}
            closeModal={handleCloseModal}
          />
        </Box>
      </Modal>
      {confirmationDialogOpen && (
        <ConfirmationDialog
          openDialog={confirmationDialogOpen}
          TitleText={`Are you sure  you want to Delete the Invoice ${selectedToDelete}.?`}
          paraText="The deleted invoice cannot be retrieved."
          IconUrl={DeleteConfirmationIcon}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleDeleteConfirmationClose()}
          onConfirmFun={confirmDeleteInvoice}
          handleCloseDialog={handleDeleteConfirmationClose}
        />
      )}
    </Box>
  );
}

export default ClientInVoices;
