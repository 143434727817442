import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function BodyMapView({ bodyPartNames, bodyImages, setMedicationInfo, medicationInfo }) {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleClickOpen = (index) => {
    setCurrentImage(index); // Set the index of the clicked image
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeEditMedicationForm = () => {
    const filterdata = bodyImages.filter((_, i) => i !== currentImage);
    const filterdata1 = bodyPartNames.filter((_, i) => i !== currentImage);
    setMedicationInfo({
      ...medicationInfo,
      body_images: filterdata,
      body_part_names: filterdata1,
    });
    setCurrentImage(null); // Reset the current image
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: '20px',
        pb: 3,
      }}
    >
      {bodyImages?.map((base64Data, index) => (
        <Box key={index} sx={{ pt: 1, position: 'relative' }}>
          <DeleteIcon
            onClick={() => handleClickOpen(index)}
            sx={{
              position: "absolute",
              right: 0,
              ml: 2,
              cursor: 'pointer',
              color: "#279989",
              fontSize: "20px",
            }}
          />
          <img
            src={base64Data}
            alt={`Image ${index}`}
            style={{ width: '280px', height: 'auto' }}
          />
          <Typography
            variant="h6"
            sx={{ pt: 1, fontFamily: "Arvo", fontWeight: 700 }}
          >
            {bodyPartNames[index]}
          </Typography>
        </Box>
      ))}

      <Dialog
        open={open}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
        }}
      >
        <Box sx={{ textAlign: 'center', justifyContent: 'center', backgroundColor: '#F0FCFA' }}>
          <DialogContent>
            {currentImage !== null && (
              <img
                style={{ width: "25%", height: "auto" }}
                src={bodyImages[currentImage]} // Display the currently clicked image
                alt={`Image ${currentImage}`}
              />
            )}
            <DialogContentText>
              <Typography variant="h4" sx={{ fontFamily: "Arvo", mb: 1 }}>
                Are you sure you want to delete?
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: "Arvo", fontWeight: 700, mb: 1 }}>
                {bodyPartNames[currentImage]}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="customcancel" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="customsave" color="primary" onClick={closeEditMedicationForm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default BodyMapView;
