import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid, Button, Box } from "@mui/material";
//import CarePlanView from "./CarePlanView";
import CarePlanEdit from "./CarePlanEdit";
import { CarePlanSideBar } from "./CarePlanSideBar";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentFormView from "./AssessmentFormView";

import UploadDocumentDialog from "./UploadDocumentDialog";
import DownloadCarePlan from "./DownloadCarePlan";
import { PDFViewer } from "@react-pdf/renderer";
import PDFViewerDialog from "./PDFViewerDialog";
import ConsentFormView from "./ConsentFormView";
import ConsentPdfViewer from "./ConsentPdfViewer";

const CarePlan = ({
  clientId,
  clientMainData,
  toggleEdit,
  assessmentName,
  setAssessmentName,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  consent,
  setConsent,
  //isEditMode,
}) => {
  const [activeTabID, setActiveTabID] = useState(0);
  const [name, setName] = useState("");

  //const [assessmentName, setAssessmentName] = useState("");
  const [assessmentId, setAssessmentId] = useState("");
  const [selectedCard, setSelectedCard] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSidebarChanged, setIsSidebarChanged] = useState(false);
  const [pendingAssessment, setPendingAssessment] = useState(null);

  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);

  const [trialOpen, setTrialOpen] = useState(false);

  const [isDraft, setIsDraft] = useState(false);

  const [uploadFiles, setUploadFiles] = useState([
    { name: "name1", status: "status1", size: 3366, file: "name1.jpeg" },
    { name: "name2", status: "status2", size: 4466, file: "name2.jpeg" },
  ]);

  const [signatureName, setSignatureName] = useState([]);
  const [selectedSignatureNameIndex, setSelectedSignatureNameIndex] =
    useState();
  const [openConsentPdf, setOpenConsentPdf] = useState(false);

  const [searchParams] = useSearchParams();
  const isEditQueryParams = searchParams.get("edit");
  const [isEditMode, setIsEditMode] = useState(
    isEditQueryParams ? true : false
  );
  const toggleEditMode = (latestTabId) => {
    // setActiveTabID(latestTabId)
    setIsEditMode(!isEditMode);
  };
  useEffect(() => {
    if (clientMainData) {
      console.log("Client Data:", clientMainData);
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  useEffect(() => {
    console.log("consent", consent);
  }, [consent]);

  useEffect(() => {
    console.log("Final signature data", signatureName);
  }, [signatureName]);

  const handleDocumentDialogClose = () => {
    setDocumentDialogOpen(false);
  };

  const handleClosePDFViewerDialog = () => {
    setTrialOpen(false);
  };

  const handleCloseConsentPDF = () => {
    setOpenConsentPdf(false);
  };

  return (
    <>
      <Grid container direction="row" alignItems="top">
        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", height: "100%" }}>
              <CarePlanSideBar
                clientId={clientId}
                clientMainData={clientMainData}
                //setTabId={setTabId}
                activeTabID={activeTabID}
                setAssessmentName={setAssessmentName}
                setAssessmentId={setAssessmentId}
                setSelectedCard={setSelectedCard}
                hasUnsavedChanges={hasUnsavedChanges}
                setDialogOpen={setDialogOpen}
                setIsSidebarChanged={setIsSidebarChanged}
                setPendingAssessment={setPendingAssessment}
                setDocumentDialogOpen={setDocumentDialogOpen}
                setTrialOpen={setTrialOpen}
                setConsent={setConsent}
              />
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingLeft: "25px",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              {consent ? (
                <ConsentFormView
                  consent={consent}
                  signatureName={signatureName}
                  setSignatureName={setSignatureName}
                  setConsent={setConsent}
                />
              ) : !assessmentName ? (
                <CarePlanEdit
                  clientId={clientId}
                  toggleEditMode={toggleEditMode}
                  clientMainData={clientMainData}
                  sideTabId={activeTabID}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setAssessmentName={setAssessmentName}
                  setAssessmentId={setAssessmentId}
                  setSelectedCard={setSelectedCard}
                  uploadFiles={uploadFiles}
                  setUploadFiles={setUploadFiles}
                  isDraft={isDraft}
                  signatureName={signatureName}
                  setSignatureName={setSignatureName}
                  setOpenConsentPdf={setOpenConsentPdf}
                  setSelectedSignatureNameIndex={setSelectedSignatureNameIndex}
                  selectedSignatureNameIndex={selectedSignatureNameIndex}
                />
              ) : (
                <AssessmentFormView
                  clientId={clientId}
                  name={name}
                  selectedCard={selectedCard}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setDialogOpen={setDialogOpen}
                  dialogOpen={dialogOpen}
                  isSidebarChanged={isSidebarChanged}
                  setIsSidebarChanged={setIsSidebarChanged}
                  pendingAssessment={pendingAssessment}
                  setPendingAssessment={setPendingAssessment}
                  setSelectedCard={setSelectedCard}
                  documentDialogOpen={documentDialogOpen}
                  setAssessmentName={setAssessmentName}
                  setDocumentDialogOpen={setDocumentDialogOpen}
                  setIsDraft={setIsDraft}
                />
              )}
            </div>
          </div>
        </Box>
      </Grid>
      {documentDialogOpen && (
        <UploadDocumentDialog
          open={documentDialogOpen}
          onClose={handleDocumentDialogClose}
          clientId={clientId}
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
        />
      )}
      {trialOpen && (
        <PDFViewerDialog
          open={trialOpen}
          onClose={handleClosePDFViewerDialog}
          clientMainData={clientMainData}
          name={name}
          clientId={clientId}
          selectedCard={selectedCard}
        />
      )}
      {openConsentPdf && (
        <ConsentPdfViewer
          open={openConsentPdf}
          onClose={handleCloseConsentPDF}
          signatureName={signatureName}
          selectedSignatureNameIndex={selectedSignatureNameIndex}
        />
      )}
    </>
  );
};

export default CarePlan;
