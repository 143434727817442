import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";
import { ViewTheme } from "../Theme";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../Theme";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import EYE from "../../../assets/images/users/view.svg";
import { getCookie } from "../../../utils/utils";
import { Calendar } from "../../reusableComponents";
import { sections } from "./questionnaire";
import { nonequestions } from "./questionnaire";
import { emptyCompetency } from "./questionnaire";
import InformationDialog from "../../reusableComponents/informationDialog";
export default function UserCompetencyEdit({
  userId,
  toggleEdit,
  setHasUnsavedChanges,
}) {
  const [competency, setCompetency] = useState(emptyCompetency);
  const [originalCompetency, setOriginalCompetency] = useState(emptyCompetency);
  const [competencycheck, setCompetencyCheck] = useState(emptyCompetency);
  const [activeAssessment, setActiveAssessment] = useState(0);
  const [assessments, setAssessments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [opensDialog, setOpensDialog] = useState(false);
  const [fullAssessment, setFullAssessment] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setActiveAssessment(0);
  };
  const formatDates = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const selectedAssesment = async (ind) => {
    setActiveAssessment(ind);
    const token = getCookie();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const URL = `${baseURL}/competency?user_id=${userId}&id=${ind}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(URL, options);
      const data = await response.json();
      const finalData = data.data;
      if (response.ok && finalData.length > 0) {
        const newData = finalData[0];
        const fullAssessment = {
          ...newData,
          "c18-checkbox1": newData.c18_c1 === "true",
          "c18-checkbox2": newData.c18_c2 === "true",
          "c18-checkbox3": newData.c18_c3 === "true",
          "c18-checkbox4": newData.c18_c4 === "true",
          "c18-checkbox5": newData.c18_c5 === "true",
          "c18-checkbox6": newData.c18_c6 === "true",
          "c18-checkbox7": newData.c18_c7 === "true",
          "c18-checkbox8": newData.c18_c8 === "true",
          "c18-checkbox9": newData.c18_c9 === "true",
          "c18-checkbox10": newData.c18_c10 === "true",
          "c18-checkbox11": newData.c18_c11 === "true",
          "c46-checkbox": newData.consent === true,
        };
        setFullAssessment(fullAssessment);
        handleOpen();
      } else {
        setFullAssessment([]);
      }
    } catch (error) {
      console.error("Failed to fetch users API:", error);
    }
  };

  const fetchUsersAPI = useCallback(async () => {
    try {
      if (!userId) return;
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/competency?user_id=${userId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const newData = data.data[0] || {};
        const signName = `${user.first_name + " " + user.last_name}`;
        setAssessments(data.data.filter((record) => record.sign_2 !== ""));
        // Convert string "true"/"false" to boolean for checkbox fields
        const sanitizedData = Object.fromEntries(
          Object.entries(newData).map(([key, value]) => {
            if (value === "true") return [key, true];
            if (value === "false") return [key, false];
            return [key, value];
          })
        );
        setOriginalCompetency(sanitizedData);
        if (sanitizedData.sign_2 === "") {
          setActiveAssessment(sanitizedData.id);
          setCompetency((prevState) => ({
            ...prevState,
            ...sanitizedData,
            sign_1: sanitizedData.sign_1 === "" ? signName : sanitizedData.sign_1,
            sign_2:
              sanitizedData.sign_1 === ""
                ? ""
                : sanitizedData.sign_1 === signName
                ? ""
                : signName,
            date_1: sanitizedData.sign_1 === "" ? formattedDate : sanitizedData.date_1,
            date_2:
              sanitizedData.sign_1 === ""
                ? ""
                : sanitizedData.sign_1 === signName
                ? ""
                : formattedDate,
          }));

        }else{
          
          setActiveAssessment(0);
                    setCompetency((prevState) => ({
                      ...prevState,
                      ...emptyCompetency,
                      sign_1: signName,
                      sign_2: "",
                      date_1: formattedDate,
                      date_2: "",
                    }));
        }
        console.log("=============sanitizedData======================", sanitizedData);
      
        
       
      } else {
        console.error("Failed to fetch data", data);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [userId ,   formattedDate,
       user.first_name,
        user.last_name,
         setHasUnsavedChanges,]);

  useEffect(() => {
    fetchUsersAPI();
  }, [fetchUsersAPI]);

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handlesOpen = () => setOpensDialog(true);
  const handlesClose = () => setOpensDialog(false);
  const onClickYessDialogBtn = () => {
    toggleEdit();
    setHasUnsavedChanges(false);
  };

  const handleCancel = () => {
    if (!areObjectsEqual(competency, originalCompetency)) {
      handlesOpen();
      console.log("competency", competency);
      console.log("originalCompetency", competencycheck);
    } else {
      toggleEdit();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCompetency((prevValues) => {
      const updatedForm = {
        ...prevValues,
        [name]: capitalizeFirstLetter(value),
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, competencycheck)); // Detect changes
      return updatedForm;
    });
  };

  const handleDateChange = (date, name) => {
    setCompetency((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: date,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, competencycheck)); // Detect changes
      return updatedForm;
    });
  };
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setCompetency((prevValues) => {
      const updatedForm = {
        ...prevValues,
        [name]: value,
      };
      console.log("=====updatedForm=================", updatedForm);
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, competencycheck)); // Detect changes
      return updatedForm;
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCompetency((prevValues) => {
      const updatedForm = {
        ...prevValues,
        [name]: checked,
      };
      console.log("=====updatedForm=================", updatedForm);
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, competencycheck)); // Detect changes
      return updatedForm;
    });
    setErrorMessage("");
  };

  const handleSubmit = () => {
    let consent = competency["c46-checkbox"];
    if (!consent) {
      setErrorMessage(`Consent is required.`);
      return false;
    }
    setErrorMessage("");
    if (
      (originalCompetency.sign_1 === "" &&
        originalCompetency.sign_2 === "" &&
        assessments.length > 1) ||
      (originalCompetency.sign_1 !== "" && originalCompetency.sign_2 !== "") ||
      originalCompetency.sign_1 !== `${user.first_name} ${user.last_name}`
    ) {
      PostCompetency();
    } else {
      PutCompetency();
    }
    setHasUnsavedChanges(false); // Reset unsaved state
    return true;
  };

  const PostCompetency = async () => {
    try {
      let fullBody = {
        user_id: userId,
        ...competency,
        consent: competency["c46-checkbox"] ? 1 : 0,
        editable: 1,
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/competency`;
      const method = "POST";
      const options = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(fullBody),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        toggleEdit();
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  console.log(
    "123243535345===========================43434343434343competency============",
    competency
  );

  const PutCompetency = async () => {
    try {
      let fullBody = {
        ...competency,
        consent: competency["c46-checkbox"] ? 1 : 0,
        editable: 0,
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/competency/${userId}/${competency.id}`;
      const method = "PUT";
      const options = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(fullBody),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        toggleEdit();
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        maxWidth={false}
      >
        <Box>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={8.5} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  marginBottom: 0,
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    paddingRight: 2,
                    width: "100%",
                  }}
                >
                  <Grid container direction="row" alignItems="top">
                    <Grid item xs={12}>
                      <Box
                        textAlign="left"
                        sx={{ padding: EditTheme.spacing(3, 3) }}
                      >
                        {sections.map((section, index) => (
                          <Box key={index} sx={{ mb: 4 }}>
                            <Typography variant="h4">
                              {section.title}
                            </Typography>
                            {section.subtitle && (
                              <Typography variant="h5">
                                {section.subtitle}
                              </Typography>
                            )}
                            {section.subtitle === "Medicine form" ? (
                              <Box>
                                {section.questions.map((question) => (
                                  <FormControlLabel
                                    key={question.id}
                                    label={question.text}
                                    control={
                                      <Checkbox
                                        checked={!!competency[question.id]} // Ensure boolean value
                                        onChange={handleCheckboxChange}
                                        name={question.id}
                                      />
                                    }
                                  />
                                ))}
                              </Box>
                            ) : (
                              section.questions.map((question) => (
                                <Box key={question.id} sx={{ mb: 3 }}>
                                  {question.id.includes("checkbox") ? null : (
                                    <>
                                      <FormLabel>
                                        <Typography variant="h6">
                                          {question.text}
                                        </Typography>
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        sx={{ padding: "0", marginTop: "5px" }}
                                        name={question.id}
                                        value={competency[question.id]}
                                        onChange={handleRadioChange}
                                      >
                                        <FormControlLabel
                                          value="yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="no"
                                          control={<Radio />}
                                          label="No"
                                        />
                                        {nonequestions.includes(
                                          question.id
                                        ) && (
                                          <FormControlLabel
                                            value="none"
                                            control={<Radio />}
                                            label="None seen this time"
                                          />
                                        )}
                                      </RadioGroup>
                                    </>
                                  )}
                                </Box>
                              ))
                            )}
                          </Box>
                        ))}
                        <FormLabel>
                          <Typography variant="h4">
                            Any other information
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="info"
                          className="multiline-textfield"
                          variant="outlined"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.info}
                          onChange={handleChange}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Actions/exceptions identified{" "}
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="action"
                          className="multiline-textfield"
                          variant="outlined"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.action}
                          onChange={handleChange}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Signatures of All Involved Admins in the Assessment
                            Required
                          </Typography>
                        </FormLabel>

                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 8, mt: 2 }}
                        >
                          <Grid item xs={10} lg={5}>
                            <FormLabel>
                              <Typography variant="h6">Signature</Typography>
                            </FormLabel>
                            <TextField
                              disabled={true}
                              name="sign_1"
                              variant="outlined"
                              fullWidth
                              value={competency.sign_1}
                              onChange={handleChange}
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={10} lg={5}>
                            <Calendar
                              label="Date"
                              onDateChange={handleDateChange}
                              required
                              preFilledvalue={competency.date_1}
                              name="date_1"
                              disabled={competency.date_2 !== ""}
                            />
                          </Grid>
                        </Grid>
                        {originalCompetency.sign_1 === "" ? null : user.id ===
                          competency.user_id ? null : competency.sign_1 ===
                          `${user.first_name} ${user.last_name}` ? null : (
                          <Grid
                            container
                            direction="row"
                            alignItems="top"
                            sx={{ gap: 8 }}
                          >
                            <Grid item xs={10} lg={5}>
                              <FormLabel>
                                <Typography variant="h6">Signature</Typography>
                              </FormLabel>
                              <TextField
                                name="sign_2"
                                variant="outlined"
                                fullWidth
                                value={competency.sign_2}
                                disabled={
                                  competency.sign_2 !== "" ||
                                  competency.editable === 0
                                }
                                onChange={handleChange}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                            <Grid item xs={10} lg={5}>
                              <Calendar
                                label="Date"
                                onDateChange={handleDateChange}
                                required
                                preFilledvalue={competency.date_2}
                                name="date_2"
                                // disabled={
                                //   competency.editable === 0 ||
                                //   competency.editable !== ""
                                // }
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 3 + 9 }}
                        >
                          <Grid item>
                            <FormControlLabel
                              label={
                                "I hereby confirm the accuracy of the information provided above"
                              }
                              control={
                                <Checkbox
                                  checked={competency["c46-checkbox"]}
                                  onChange={handleCheckboxChange}
                                  name={"c46-checkbox"}
                                />
                              }
                            />
                            <Typography variant="errmsg">
                              {errorMessage}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 4, mt: 3 }}
                        >
                          <Grid item>
                            <Button
                              variant="customcancel"
                              color="primary"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="customsave"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={3.5} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  marginBottom: 0,
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                  <br />
                  {assessments?.map((a, index) => (
                    <div
                      style={{ paddingBottom: "4px" }}
                      key={index}
                      onClick={() => selectedAssesment(a.id)}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          border: "2px solid #F0FCFA",
                          minheight: "60px",
                          padding: "5px 15px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          background: "#C0EBE4",
                          cursor: "pointer",
                          border:
                            a.id === activeAssessment
                              ? "2px solid #279989"
                              : "2px solid #F0FCFA",
                          "&:hover": {
                            border: "2px solid #279989", // Correct way to use :hover in MUI sx
                          },
                        }}
                      >
                        {/* <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        > */}
                        <Grid item xs={2} sx={{ margin: "0 2px" }}>
                          <Box>
                            <img
                              src={ASSESMENT_FILE}
                              alt="user logo"
                              height="35px"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ margin: "0 1px" }}>
                          <Box sx={{ paddingLeft: "10px" }}>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                                paddingBottom: 0,
                              }}
                              variant="h5"
                            >
                              {a.sign_2}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                              }}
                              variant="h6"
                            >
                              {formatDates(a.date_2)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sx={{ margin: "0 1px" }}>
                          {/* </Box> */}

                          <Box>
                            <img
                              src={EYE}
                              alt={index}
                              style={{ width: "22px", height: "22px" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {openDialog && (
          <InformationDialog
            competency={fullAssessment}
            userId={userId}
            openDialog={openDialog}
            competencyId={0}
            handleCloseDialog={handleClose}
          />
        )}
        {opensDialog && (
          <ConfirmationDialog
            openDialog={opensDialog}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => handlesClose()}
            onConfirmFun={onClickYessDialogBtn}
            handleCloseDialog={handlesClose}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}

