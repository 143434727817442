import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import userLogo from "../../../assets/images/common/userLogo.png";
import LogoutModal from "./LogoutModal";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
// import LogoutIcon from "@mui/icons-material/Logout";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ReactComponent as ChangeIcon } from "../../../assets/icons/HeaderChangeIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/HeaderEditProfileIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/HeaderLogoutIcon.svg";

export default function UserLogin({
  hasUnsavedChanges,
  setHasUnsavedChanges,
  setOpenChangeIcon,
  setChangeOrEdit,
  finalSelectedProfilePhoto,
}) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handlepopup = () => {
    if (hasUnsavedChanges) {
      setPendingNavigation(true);
      handleOpenDialog();
    } else {
      setAnchorEl(null);
      setOpen(true);
    }
  };
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingNavigation(false);
  };
  const onClickYesDialogBtn = () => {
    if (pendingNavigation) {
      setOpen(true);
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleCloseDialog();
  };
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleChangeIcon = () => {
    if (hasUnsavedChanges) {
      handleOpenDialog();
    } else {
      setAnchorEl(null);
      setOpenChangeIcon(true);
      setChangeOrEdit(true);
    }
  };

  const handleEditProfile = () => {
    if (hasUnsavedChanges) {
      handleOpenDialog();
    } else {
      setAnchorEl(null);
      setOpenChangeIcon(true);
      setChangeOrEdit(false);
    }
  };
  const getTwoUpperCaseLetters = (fullName) => {
    // if (!fullName) return "U"; // Default fallback when no name is provided (e.g., "U" for User).

    const nameParts = fullName.trim().split(" ");
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }

    // Return the first letter of the first and second name.
    return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
      }}
    >
      {open && <LogoutModal closeModal={() => setOpen(false)} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          fontSize: "12px",
          position: "relative",
          flexGrow: 0,
          height: "40px",
          width: "40px",
          background:
            finalSelectedProfilePhoto || user?.profile_photo
              ? "none"
              : "#269989", // Add a background for initials
        }}
      >
        {finalSelectedProfilePhoto || user?.profile_photo ? (
          <img
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              flexShrink: 0,
              borderRadius: "inherit",
              border: 0,
            }}
            src={finalSelectedProfilePhoto || user?.profile_photo}
            alt="user logo"
          />
        ) : (
          <Typography
            style={{
              fontSize: "1.1rem",
              color: "#fff", // Text color
              fontWeight: "bold",
            }}
          >
            {getTwoUpperCaseLetters(user?.first_name + " " + user?.last_name)}
          </Typography>
        )}
      </Box>

      <Typography
        variant="body0"
        sx={{
          fontSize: "12px",
          margin: "0px",
          padding: "0px 10px",
          textAlign: "left",
          fontFamily: "Arvo",
        }}
      >
        <span style={{ fontSize: "14px" }}>Hello!</span> <br />
        {user?.first_name + " " + user?.last_name || "Harprit Sigh"}
      </Typography>
      <Box
        sx={{
          marginTop: "auto",
          marginBottom: "2px",
        }}
      >
        <ArrowDropDownIcon
          sx={{
            cursor: "pointer",
            width: "20px",
            height: "20px",
            color: menuOpen ? "#FFFFFF" : "black",
            borderRadius: "50%",
            backgroundColor: menuOpen ? "#279989" : "transparent",
            transition: "background-color 0.3s, color 0.3s", // smooth transition
            "&:hover": {
              backgroundColor: menuOpen ? "#279989" : "#2799894D",
            },
          }}
          onClick={handleMenuOpen}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        style={{
          marginTop: 9,
        }}
        disableAutoFocusItem
      >
        <MenuItem
          onClick={handleChangeIcon}
          sx={{
            width: "100%",
            marginLeft: 0,
            fontSize: 14,
            "&:hover": {
              color: "#279989",
              "& svg path": {
                fill: "none",
                stroke: "#279989",
              },
            },
            "&:before": {
              height: 0,
            },
          }}
        >
          <ListItemIcon>
            <ChangeIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          Change Logo
        </MenuItem>
        <MenuItem
          onClick={handleEditProfile}
          sx={{
            width: "100%",
            marginLeft: 0,
            fontSize: 14,
            "&:hover": {
              color: "#279989",
              "& svg path": {
                fill: "none",
                stroke: "#279989",
              },
            },
            "&:before": {
              height: 0,
            },
          }}
        >
          <ListItemIcon>
            <EditIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={handlepopup}
          sx={{
            width: "100%",
            marginLeft: 0,
            fontSize: 14,
            "&:hover": {
              color: "#279989",
              "& svg path": {
                fill: "#279989",
                // stroke: "#279989",
              },
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
